import { Injector, OnDestroy, OnInit, Directive } from '@angular/core';
import { GenericDialogsService } from '../../src/app/popup/generic-dialogs.service';

@Directive()
export abstract class BaseClass implements OnDestroy, OnInit {

    
    genericDialog: GenericDialogsService;


    constructor(injector: Injector) {
       
        this.genericDialog = injector.get(GenericDialogsService);

        
      //  this.genericDialogs = injector.get(GenericDialogsService);

    }
    ngOnInit(): void {
      
    }


    ngOnDestroy(): void {
    }


}



