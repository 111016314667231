
import { AbstractControl, ValidationErrors, ValidatorFn, FormGroup, FormArray } from '@angular/forms';
import { Constants } from '../constants/constants';
// import { isString, isNumber } from 'util';
let constants: Constants = new Constants();


export function onlyNumeric(minLength: number, maxLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

        let v: number = +control.value;
        let numberInString = v.toString();
        if (isNaN(v)) {
            return { 'onlyNumericError': constants.onlyNumeric }
        }
        // else if (v < 0) {
        //     return { 'negativeNumberError': constants.negativeNumberError }
        // }
        else if (v > 0) {

            if (numberInString.length < minLength) {
                return { 'minLengthError': "length can not be less than " + minLength }
            }
            else if (numberInString.length > maxLength) {
                return { 'maxLengthError': "length can not be more than " + maxLength }
            }

        }
        return null;

    }

}

export function minMaxLengthValidator(minLength: number, maxLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

        let v: number = +control.value;
        let numberInString = v.toString();
        if ((numberInString.length < minLength) && (+numberInString != 0) && (numberInString)) {
            return { 'minLengthError': "length can not be less than " + minLength }
        }
        else if ((numberInString.length > maxLength) && (+numberInString != 0)) {
            return { 'maxLengthError': "length can not be more than " + minLength }
        }

        return null;

    }

}
export function minLengthValidator(minLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        let v: number = +control.value;
        let numberInString = v.toString();
        if ((numberInString.length < minLength) && (+numberInString != 0) && (numberInString)) {
            return { 'minLengthError': "length can not be less than " + minLength }
        }
        if ((control.value != undefined) && (control.value != null) && (control.value != '') && (+numberInString == 0)) {
            return { 'minLengthError': "length can not be less than " + minLength }
        }
        // else if ((numberInString.length > maxLength) && (+numberInString !=0)) {
        //     return { 'maxLengthError': "length can not be more than " + minLength }
        // }

        return null;

    }

}

export function requiredField(): ValidatorFn {

    return (control: AbstractControl): ValidationErrors | null => {
        let v: any = control.value;

        if ((v)) {
            if (v < 0) {
                return { 'requiredField': constants.errorEmpty }
            }
        } else if (!v || v.length <= 0) {
            return { 'requiredField': constants.errorEmpty }
        }
        return null;
    }
}


export function requiredFieldWithZero(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        let v: any = control.value;

        if (!v || v.length <= 0) {
            return { 'zeroError': constants.zeroError }
        }
        return null;
    }
}

export function pattern(pattern, errorMsg: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        let v: any = control.value;
        if (v != null && v != "") {
            v = (v) ? v : v.toString();
            if (!v || !v.match(pattern)) {
                return { 'pattern': errorMsg }
            }
        }
        return null;
    }
}


export function getErrorMsgArray(formGroup: FormGroup) {
    let errorsArray = [];
    console.log("form group custom")
    console.log(formGroup);
    Object.keys(formGroup.controls).forEach(parentKey => {
        let valueForParentKey = formGroup.get(parentKey);
        if (valueForParentKey instanceof FormArray) {
            for (let index = 0; index < valueForParentKey.length; index++) {
                const element: any = valueForParentKey.controls[index];
                if (!element.valid) {
                    let array = getErrorMsgArray(element);
                    if (array && array.length > 0)
                        errorsArray = errorsArray.concat(array);
                }
            }
        } else {
            let errors = formGroup.get(parentKey).errors;
            if (errors) {
                for (let key in errors) {
                    let error = errors[key];
                    if (error != true) {
                        errorsArray.push(getErrorString(parentKey) + ' ' + getFieldName(parentKey)?.toLowerCase());
                        // errorsArray.push(formatFieldName(parentKey) + ' ' + errors[key]);
                    }
                }
            }
        }

    });

    return errorsArray;
}

export function getErrorObjList(formGroup: FormGroup) {
    let errorsArray = [];
    let errorsKeyList = [];
    console.log(formGroup);
    Object.keys(formGroup.controls).forEach(parentKey => {
        let valueForParentKey = formGroup.get(parentKey);
        if (valueForParentKey instanceof FormArray) {
            for (let index = 0; index < valueForParentKey.length; index++) {
                const element: any = valueForParentKey.controls[index];
                if (!element.valid) {
                    let array = getErrorMsgArray(element);
                    if (array && array.length > 0)
                        errorsArray = errorsArray.concat(array);
                }
            }
        } else {
            let errors = formGroup.get(parentKey).errors;
            if (errors) {
                for (let key in errors) {
                    let error = errors[key];
                    if (error != true) {
                        errorsKeyList.push(parentKey)
                    }
                }
            }
        }

    });

    return errorsKeyList;
}

function formatFieldName(str: string) {

    var split = str.split(/(?=[A-Z])/);

    var actualString = "";
    for (let i = 0; i < split.length; i++) {
        let subString = split[i];
        if (subString.toLowerCase() != 'fk' && subString.toLowerCase() != 'id') {
            if (i == 0)
                actualString += subString[0].toUpperCase() + subString.slice(1);
            else
                actualString += " " + subString[0].toUpperCase() + subString.slice(1);;
        }
    }
    return actualString;
}

function getErrorString(key: string) {
    switch (key.toLowerCase()) {
        case 'firstname': case 'surname': case 'postcode': case 'county':
            return constants.errorInputEmpty;
        case 'preferredtype': case 'drivinglicence':
            return constants.errorSelectEmpty;
        case 'yearsataddress':
            return constants.errorSelectWithoutA;
        case 'employmentstatus':
            return constants.errorSelectAnEmpty;
        case 'dateofbirth': case 'contactnumber': case 'email':
        case 'residentialstatus':
            return constants.errorValidEmpty;
        case 'addressline':
            return constants.errorEnterSelectValid;
        case 'iscurrentlybankrupt':
            return constants.errorBankruptcy;
        case 'term':
            return constants.errorTerms;
    }
}

function getFieldName(key: string) {
    switch (key.toLowerCase()) {
        case 'preferredtype':
            return 'preferred vehicle type';
        case 'email':
            return 'email address';
        case 'dateofbirth':
            return 'date of birth';
        case 'drivinglicence':
            return 'licence type';
        case 'addressline':
            return 'address';
        case 'yearsataddress':
            return 'time at address';
        case 'employmentstatus':
            return 'occupation status';
        case 'contactnumber':
            return 'phone number';
        case 'iscurrentlybankrupt': case 'term':
            return '';
        default:
            return key;
    }
}