<div class="modal fad-e modalCustom" id="privacyPolicyModal" tabindex="-1" role="dialog"
  aria-labelledby="privacyPolicyModalTitle" aria-hidden="true" data-backdrop="false">
  <div class="modal-dialog large-modal" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="privacyPolicyModalTitle">Privacy <span>Policy</span></h2> <button type="button"
          class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">
            <!-- &times; --><img src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/cancel-icon.svg" alt="Cancel Icon" rel="noopener">
          </span> </button>
      </div>
      <!-- modal body beginning here -->
      <div class="modal-body">
        <app-privacy-policy-content></app-privacy-policy-content>
      </div>
      <!-- modal body end here -->
    </div>
  </div>
</div>
