import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})

export class CanonicalService {

  constructor(@Inject(DOCUMENT) private dom) { }

  setCanonicalURL(url?: string) {
    const canURL = url == undefined ? this.dom.URL : url;
    // const link: HTMLLinkElement = this.dom.createElement('link');
    const head = this.dom.getElementsByTagName('head')[0];
    var link: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
    if (link==null) {
      link= this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(link);
    }
    link.setAttribute('rel', 'canonical');
    link.setAttribute('hreflang', 'en-gb');
    this.dom.head.appendChild(link);
    const canURL1 = canURL.replace('http://', 'https://')
    link.setAttribute('href', canURL1);
  }

}
