import { AfterViewInit, Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SearchForAVehicle } from 'src/app/modals/search-for-a-vehicle.model';
import { RequestCallbackService } from '../../shared/request-callback/request-callback.service';
declare var $: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})



export class ContactComponent implements OnInit, AfterViewInit {
  title = 'Contact Us | The Car Loan Centre';
  isRequestcaliked: boolean = true;
  searchforvehicleformgroup: FormGroup
  showhidediv: boolean = false;
  showlessclicked: boolean = false;
  showmorelicked: boolean = true;
  showtext = "More search options"
  searchforvehicle: SearchForAVehicle = new SearchForAVehicle();
  makeArray = [];
  transmisisonArray = [];
  bodytypeArray = [];
  maxpriceArray = [];
  searchURL: string;
  Elat = 51.586115;
  Elong = 0.518842;
  Mlat = 53.452751;
  Mlong = -2.126217;
  zoom: number = 6;

  lat: number = 51.586115;
  lng: number = 0.518842;


    clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`)
  }

markers: marker[] = [
  {
    lat: 51.586115,
    lng: 0.518842,
    label: 'CLC',
    draggable: true
  },
  {
    lat: 53.452751,
    lng: -2.126217,
    label: 'CLC',
    draggable: false
  }
]

  constructor(public menuservice: RequestCallbackService, public router: Router, private titleService: Title,
    private metaTagService: Meta) {
  }

  seoTags() {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Get in contact with us! Want to have a chat with one of our friendly sales consultants? We are always on hand to answer your questions.' }

    );
    this.metaTagService.updateTag(
      { name: 'keywords', content: '' },
    );
  }
  ngOnInit(): void {
    this.seoTags();

  }

  openChat() {
    location.hash = "#Main-Chat-1-4"
  }

  // showCallbackFrom() {
  //   document.getElementById("requestCallbackMainId").style.width = "100%";
  //   if (this.isRequestcaliked) {
  //     this.isRequestcaliked = false;
  //     document.getElementById("requestCallbackMainId").style.width = "100%";
  //   }
  // }

  //function for Request a callback
  showCallbackFrom() {
    document.getElementById("requestCallbackMainId").style.width = "100%";
    this.isRequestcaliked = false;
  }
  closeCallbackForm() {
    document.getElementById("requestCallbackMainId").style.width = "0";
    this.isRequestcaliked = true;
  }


  ngAfterViewInit(): void {

   // var modalCallBack = document.getElementById("requestCallbackMainId");
    // window.onclick = function(event) {
    // 	if (event.target == modalCallBack) {
    // 		modalCallBack.style.width = "0";
    // 	}
    // }

  }

    wobbleForm() {
    if ($(window).width() <= 991) {
      //callbackAnim.reverse()[1]();
    }
    else {
      $(".main-container-shadow").toggleClass("wobble")

      $(".main-container-shadow").delay(2000).queue(function () {
        $(".main-container-shadow").removeClass("wobble").dequeue();
      });
    }
  }

}

interface marker {
	lat: number;
	lng: number;
	label?: string;
	draggable: boolean;
}


