import { ApplicationRef, Injector } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Constants } from '../app/constants/constants';
import { CommonFunctionsService } from '../common/common-functions.service';
import { DataTransferService } from '../common/data-transfer.service';


export abstract class BaseClassApiService {

    public commonFunctions: CommonFunctionsService;
    public constants: Constants;
    ar: ApplicationRef;
    public apiService: ApiService;
    protected dataService: DataTransferService;

    constructor(injector: Injector) {
        this.commonFunctions = injector.get(CommonFunctionsService);
        this.constants = injector.get(Constants)
        this.ar = injector.get(ApplicationRef);
        this.dataService = injector.get(DataTransferService);
        this.apiService = injector.get(ApiService);
    }

}
