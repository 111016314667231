import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class CookiesconsentService {

  args = { utm_source: '', utm_medium: '' };
  expireDays: number = 7;
  withParams = null;
  desParams = null;
  defaultSource = '';
  defaultMedium = '';
  routingLink = '';
  baseUrl = '';
  constructor(private router: Router,
    private _cookieService: CookieService,
    private route: ActivatedRoute) { }

  setCookie() {
    debugger;
    this.getSourceMedium();
    this.setArgsList();
    this.setCookiesnRouterLink();
  }

  getSourceMedium() {

    this.route.queryParams.subscribe(params => {
      console.log(params)
      this.withParams = params['param'];
      this.defaultSource = params['utm_source'];
      this.defaultMedium = params['utm_medium'];
      this.desParams = params['des'];
      console.log(this.withParams)
    });
  }

  setArgsList() {
    let incomingPath = this.router.url;
    console.log(incomingPath)

    if (this.withParams == undefined && this.desParams == undefined) {
      switch (incomingPath.toLowerCase()) {
        case '/welcome/twitter':
          this.args = { utm_source: 'Social', utm_medium: 'Twitter' }; break;
        case '/welcome/chat':
          this.args = { utm_source: 'natural', utm_medium: 'Chat' }; break;
        case '/welcome/money':
          this.args = { utm_source: 'Advertising', utm_medium: 'Money.co.uk' }; break;
        case '/welcome/cashlady':
          this.args = { utm_source: 'Affiliate', utm_medium: 'Cashlady' }; break;
        case '/welcome/trustpilot':
          this.args = { utm_source: 'Advertising', utm_medium: 'Trustpilot' }; break;
        case '/welcome/fridayads':
          this.args = { utm_source: 'Advertising', utm_medium: 'Fridayads' }; break;
        case '/welcome/youtube':
          this.args = { utm_source: 'Social', utm_medium: 'Youtube' }; break;
        case '/welcome/assetexchange':
          this.args = { utm_source: 'Advertising', utm_medium: 'Assetexchange' }; break;
        case '/welcome/smspm':
          this.args = { utm_source: 'Advertising', utm_medium: 'SMSPM' }; break;
        case '/welcome/freeads':
          this.args = { utm_source: 'Advertising', utm_medium: 'SMSPM' }; break;
        case '/welcome/newsquest':
          this.args = { utm_source: 'Advertising', utm_medium: 'Newsquest' }; break;
        case '/welcome/emazed':
          this.args = { utm_source: 'Advertising', utm_medium: 'emazed' }; break;
        case '/welcome/facebook':
          this.args = { utm_source: 'Default', utm_medium: 'Facebook' }; break;
        default:
          this.args = { utm_source: this.defaultSource, utm_medium: this.defaultMedium };
      }
    }
    else {
      if (incomingPath.toLowerCase().includes('/welcome/facebook')) {
        if (this.withParams) {
          switch (this.withParams) {
            case 'fb':
              this.args = { utm_source: 'Social', utm_medium: 'Facebook' }; break;
            case 'fbg':
              this.args = { utm_source: 'Social', utm_medium: 'FBG' }; break;
            case 'fbl':
              this.args = { utm_source: 'Social', utm_medium: 'FBL' }; break;
            case 'fbzd':
              this.args = { utm_source: 'Social', utm_medium: 'FBZD' }; break;
          }
        }
      }
      else if (incomingPath.toLowerCase().includes('/welcome/zerodeposit')) {
        switch (this.withParams) {
          case 'cczd':
            this.args = { utm_source: 'Advertising', utm_medium: 'CCZD' }; break;
          case 'g+zd':
            this.args = { utm_source: 'Social', utm_medium: 'G+ZD' }; break;
          case 'dlzd':
            this.args = { utm_source: 'Advertising', utm_medium: 'DLZD' }; break;
          case 'smspmzd':
            this.args = { utm_source: 'Advertising', utm_medium: 'SMSPMZD' }; break;
          case 'ppczd':
            this.args = { utm_source: 'Google', utm_medium: 'PPCZD' }; break;
          case 'cczd1':
            this.args = { utm_source: 'Advertising', utm_medium: 'CCZD1' }; break;
          case 'sk1':
            this.args = { utm_source: 'Advertising', utm_medium: 'SK1' }; break;
          case 'sk2':
            this.args = { utm_source: 'Advertising', utm_medium: 'SK2' }; break;
          case 'sk3':
            this.args = { utm_source: 'Advertising', utm_medium: 'SK3' }; break;
          case 'sk4':
            this.args = { utm_source: 'Advertising', utm_medium: 'SK4' }; break;
          default:
            this.args = { utm_source: this.defaultSource, utm_medium: this.defaultMedium };

        }
      }

      else if (this.desParams && incomingPath.toLowerCase().includes('/welcome?')) {

        this.route.queryParams.subscribe(params => {
          console.log(params);

          this.args['utm_medium'] = params['utm_medium'];
          this.args['utm_source'] = params['utm_source'];
          this.desParams = params['des'];
          this.desParams =  this.desParams.toLowerCase();
          this.desParams= this.desParams.replace('CarFinance','car-finance');
          this.desParams= this.desParams.replace('carfinance','car-finance');

          if(this.desParams =='faq')
          {
            this.desParams= this.desParams.replace('faq','faqs');
          }
          this.desParams= this.desParams.replace('jargon','faqs');
          this.desParams= this.desParams.replace('logbookloans/compare','logbook-loans/compare');
          this.desParams= this.desParams.replace('logbookloans','logbook-loans');
          this.desParams= this.desParams.replace('manchester','essex');
          // this.desParams= this.desParams.replace('Apply','apply');
          this.desParams= this.desParams.toLowerCase();
        });

        // this.args = { source: 'source', medium: '' };
        this.baseUrl = location.origin + '/' + this.desParams + '?utm_source=' + this.args['utm_source'] + '&utm_medium=' + this.args['utm_medium'];
        //  + '&w=true';
        console.log(this.baseUrl)

      }
    }
  }

  setCookiesnRouterLink() {
    let param;
    this._cookieService.set('arrived', new Date().toString(), { expires: this.expireDays, path: '/' });
    this._cookieService.set('utm_source', this.args['utm_source'], { expires: this.expireDays, path: '/' });
    this._cookieService.set('utm_medium', this.args['utm_medium'], { expires: this.expireDays, path: '/' });
    this._cookieService.set('reference', "Main", { expires: this.expireDays, path: '/' });
    this._cookieService.set('oldLink', 'false', { expires: this.expireDays, path: '/' });
    this._cookieService.set('newlink', "?utm_medium=" + this.args['utm_medium'] + "&amp;utm_source=" + this.args['utm_medium'] + "&w=true", { expires: this.expireDays, path: '/' });
    console.log('cookies set')
    console.log(this._cookieService.getAll())

    if (this.desParams) {
      window.open(this.baseUrl, "_self");
      // this.router.navigate([this.desParams])
      //   .then(data => {
      //     debugger;
      //     window.open(this.baseUrl, "_self");
      //   })
      //   .catch(e => {
      //     this.router.navigate(['/' + Paths.pathHome]);
      //   });



      // window.open(this.baseUrl, "_self");
    }
    else {
      // if (this.withParams) {
      param = { utm_medium: this.args['utm_medium'], utm_source: this.args['utm_source'], w: true };
      this.router.navigate([this.routingLink], { queryParams: param })
    }
    // else if (!this.withParams) {
    //   param = { utm_medium: this.args['medium'], utm_source: this.args['source'], w: true };
    //   this.router.navigate([''], { queryParams: param });
    // }

  }
}
