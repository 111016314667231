
import { Injectable, Injector } from '@angular/core';
import { Subject } from 'rxjs';
import { APIURLS } from 'src/api/api-url';
import { GetVehicle } from '../modals/getVehicle.model';
import { BaseClassApiService } from '../../global/base-class-api-service';
import { CookieService } from 'ngx-cookie-service';
import { Overlay } from '@angular/cdk/overlay';
import { MatDialog } from '@angular/material/dialog';
import { ValidationsErrorPopupComponent } from './validations-error-popup/validations-error-popup.component';

//declare var swal: any;
@Injectable({
  providedIn: 'root'
})
export class GenericDialogsService extends BaseClassApiService {
  constructor(injector: Injector,
    private _cookieService: CookieService,private overlay: Overlay,
    private dialog: MatDialog
  ) {
    super(injector);
  }
  savedCount: number = 0;
  savedCarList: Array<GetVehicle> = [];

  public showSavedCarPopup() {
    this.closeSavedCarPopup();
    let element = document.getElementById("savedCarsPopupId");
    if (element) {
      element.style.display = "block";
    }
  }

  public closeSavedCarPopup() {
    let element = document.getElementById("savedCarsPopupId");
    if (element) {
      element.style.display = "none";
    }
  }

  getSavedCars(savedCarsIds): Subject<any> {
    let subject: Subject<any> = new Subject();
    if (savedCarsIds != undefined && savedCarsIds != null && savedCarsIds != '') {
      this.apiService.hitGetApi(APIURLS.getAxleBaseImageAndDetailURl(APIURLS.AXLEIMAGESWITHDETAILSGETBYID + 'vehicleIds=' + savedCarsIds.split(',')), {
        onSuccess(response) {
          if (response != undefined || response != null) {
            let carDetails = response;
            delete carDetails.vehicleImages;
            subject.next(carDetails)
          }
        }, onError(errorCode, errorMsg) {
        }
      })
      return subject;
    }
  }

getvehicleDetailsByIDs(){
  let savedCarsIds="";
  if(this.savedCarList.length==0)
  {
    let vehicles = this._cookieService.get('SavedVehicles');
    if(vehicles!=undefined && vehicles != null && vehicles !=''){
    let noOfVehicles = vehicles.split(',');
    noOfVehicles.forEach(element => {
     let saveCar= element.split('-');
     savedCarsIds=savedCarsIds==""?saveCar[saveCar.length-1]:savedCarsIds+","+saveCar[saveCar.length-1];
    });
     this.getSavedCars(savedCarsIds).subscribe(response => {
     this.savedCarList= response;

     });
    }
  }

}
public showErrorPopup(data: any) {
  let element = document.getElementById("errorPopup");
  if (element) {
        element.style.display = "block";
      }
  const scrollStrategy = this.overlay.scrollStrategies.reposition();
  return this.dialog.open(ValidationsErrorPopupComponent, {
    data: data,
    panelClass: 'custom-dialog-container',
    autoFocus: false,
    scrollStrategy
  });
  // let element = document.getElementById("errorPopup");
  //   if (element) {
  //     element.style.display = "block";
  //   }
}


}

