import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CookiesconsentService } from '../../global/cookiesconsent.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  title = '404';
isNotFound: boolean;
isRedirectedFromCarInfor:boolean=false;
  constructor(private route:ActivatedRoute, private _cookiesConsent: CookiesconsentService,
    private router:Router,private titleService: Title,
    private metaTagService: Meta) { }

  seoTags()
   {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: '' }

    );
    this.metaTagService.updateTag(
      { name: 'keywords', content: '' },

    );
   }

  ngOnInit(): void {
    if (this.route.snapshot.url[0].path.toLowerCase() =='logbookloans')
    {
      this.router.navigate(["logbook-loans"]);

      this.isNotFound = false;
    }
     else if (this.route.snapshot.url[0].path.toLowerCase() =='logbookloans/compare')
    {
      this.router.navigate(["logbook-loans/compare"]);
      this.isNotFound = false;
    }
    else if (this.route.snapshot.url[0].path.toLowerCase() =='carfinance')
    {
      this.router.navigate(["car-finance"]);
      this.isNotFound = false;
    }
    else if (this.route.snapshot.url[0].path.toLowerCase() =='jargon')
    {
      this.router.navigate(["faqs"]);
      this.isNotFound = false;
    }
    else if (this.route.snapshot.url[0].path.toLowerCase() =='faq')
    {
      this.router.navigate(["faqs"]);
      this.isNotFound = false;
    }
    else if (this.route.snapshot.url[0].path.toLowerCase() =='bad-credit-car-loans')
    {
      this.router.navigate(["cars"]);
      this.isNotFound = false;
    }
    else if (this.route.snapshot.url[0].path.toLowerCase() =='poor-credit-car-finance')
    {
      this.router.navigate(["cars"]);
      this.isNotFound = false;
    }
    else if (this.route.snapshot.url[0].path.toLowerCase() =='manchester')
    {
      this.router.navigate(["essex"]);
      this.isNotFound = false;
    }
    else if (this.route.snapshot.url[0].path.toLowerCase() =='welcome')
    {
      this._cookiesConsent.setCookie();
      this.isNotFound = false;
    }
    else if(this.route.snapshot.url[0].path.toLowerCase()!==this.route.snapshot.url[0].path)
    {

      this.router.navigate([this.route.snapshot.url[0].path.toLowerCase()]);
      this.isNotFound = false;
    }
   else{
    if (this.route.snapshot.url[0].path.toLowerCase() =='isredirectedfromcarinfor'){
      this.isNotFound = true;
      this.isRedirectedFromCarInfor = true;
    }
    else{
    this.isNotFound = true;}
   }

    this.seoTags();
  }

}
