<app-header></app-header>
<!-- <div class="body-bg-wp">
    <div class="bgOpacity"
        style="background-image: url('https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/pageBg/contactBackground.jpg')">
    </div>
</div> -->
<div class="body-bg-wp">

</div>
<!--flex-container content html beginning here-->
<div class="container-flex" id="mtopId">
    <!-- left menu html beginning here -->
    <div class="left-container forMobileBg" id="navForMobile">
        <!--	---fixed wrapper Menu and Search for a Vehicle...html beginning here-- - -->
        <div class="menuFixed-wp">
            <app-left-menu></app-left-menu>
        </div>
    </div>

    <div class="callback-wp-fixed">
        <!-- <a (click)="showCallbackFrom()"><img
                src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/request-callback-icon.svg" rel="noopener"
                style="height: 40px; width: 40px;" alt="Request Call Back Icon" /></a> -->
    </div>



    <!-- middle content html beginning here -->
    <div class="middle-container">
        <app-cookies-popup></app-cookies-popup>

        <div class="heading-wrapper">
            <h1>
                Contact <span>Us</span>
            </h1>
        </div>


        <div class="content-card whiteBgtran">

            <div class="termsPolicy-container">

                <h3 class="mt-0">Have any questions?</h3>

                <p>
                    You may find the answers you're looking for in our <a routerLink="/faqs" class="applyNow">FAQ
                        pages.</a>
                </p>
                <p>
                    If you would like to contact us, you can do so by using the information below. 
                    <!-- or click <a (click)="openChat()" style="cursor: pointer;" class="link-green">Here</a> to Chat with us now. -->
                </p>


                <div class="contact-panel-info">
                    <div class="info-details">
                        <div class="contact-phone">
                            <div class="icon-wp">
                                <a hreflang="en-gb" href="tel:03303800400"><i class="fa fa-phone"></i></a>
                            </div>
                            <div class="call-wp">
                                <h3>Call Us</h3>
                                <p>
                                    <a class="applyNow" href="tel:03303800400">03303 800 400</a>
                                </p>
                            </div>
                        </div>

                        <div class="contact-email">

                            <div class="icon-wp">
                                <a hreflang="en-gb" href="mailto:info@thecarloancentre.co.uk"><img
                                        src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/mail-icon.svg" rel="noopener"
                                        alt="Email us"></a>
                            </div>
                            <div class="email-text-wp">
                                <h3>Email Us</h3>
                                <p>
                                    <a class="applyNow"
                                        href="mailto:info@thecarloancentre.co.uk">info@thecarloancentre.co.uk</a>
                                </p>
                            </div>

                        </div>

                        <h3>Find Us in Essex</h3>
                        <ul class="listNone" style="padding: 0;">
                            <li>The Car Loan Centre Essex</li>
                            <li>77 Wollaston Way</li>
                            <li>Burnt Mills Industrial Estate</li>
                            <li>Basildon</li>
                            <li>Essex</li>
                            <li>SS13 1DJ</li>
                        </ul>

                    </div>

                    <div class="map-info">

                        <div class="map-wrapper">
                          <iframe  class="map-wrapper" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2479.0212012390443!2d0.5165034159272038!3d51.586174812761215!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8c47d6337a48b%3A0x94277186754edb7d!2sThe%20Car%20Loan%20Centre!5e0!3m2!1sen!2suk!4v1638370261259!5m2!1sen!2suk" style="border:0;" allowfullscreen="" loading="lazy" rel="noopener"></iframe>

                        </div>

                    </div>

                </div>
                <!-- <div class="contact-panel-info" style="padding-top: 3%;">
                  <div class="info-details">
                    <h3>Find Us in Manchester</h3>
                    <ul class="listNone" style="padding: 0;">
                      <li>The Car Loan Centre Manchester</li>
                      <li>Unit 4, Orbital Way</li>
                      <li>24 Windmill Lane</li>
                      <li>Denton</li>
                      <li>Manchester</li>
                      <li>M34 3QA</li>
                    </ul>
                  </div>

                  <div class="map-info">
                    <div class="map-wrapper">
                        <iframe class="map-wrapper" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2375.871035332987!2d-2.1284197840203567!3d53.45289157442619!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487bb5539779178f%3A0xf3a54b0048eedd0d!2sThe%20Car%20Loan%20Centre!5e0!3m2!1sen!2suk!4v1638370475010!5m2!1sen!2suk" style="border:0;" allowfullscreen="" loading="lazy" rel="noopener"></iframe>
                    </div>
                  </div>
                </div> -->


                <div class="contact-panel-deliver">
                    <div class="deliverImage">
                        <img src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/delivery-area.webp" rel="noopener"
                            alt="We can deliver every car nationwide" onerror="this.src='https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/delivery-area.png'">
                    </div>
                    <div class="deliverText">
                        <h3>We Deliver Nationwide!</h3>
                        <p>
                            Once approved for finance we can deliver your new car to you, Nationwide! (Fees may apply).
                            Alternatively, we have a Centre in Essex 
                            <!-- and Manchester -->
                             where we can support you in coming to
                            collect your vehicle where a cup of Tea/Coffee will be waiting.
                        </p>
                    </div>
                </div>



                <div class="blueBand">
                    <h2>We're Social Too!</h2>
                </div>


                <div class="contact-panel-social">
                    <a href="https://www.facebook.com/thecarloancentre" target="_blank" rel="noopener">
                        <div class="socialBtn">
                            <img src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/socialFacebook.png"
                                alt="Follow us on Facebook">
                            <h3 style="color:#48A2C8;" class="">Follow Us</h3>
                        </div>
                    </a>
                    <a href="https://www.instagram.com/thecarloancentre/" target="_blank" rel="noopener">
                        <div class="socialBtn">
                            <img src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/socialInstagram.png"
                                alt="Visit our Instagram page">
                            <h3 style="color:#3484A6;" class="">Like Us</h3>
                        </div>
                    </a>
                    <a href="https://twitter.com/thecarloancentr" target="_blank" rel="noopener">
                        <div class="socialBtn">
                            <img src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/socialTwitter.png"
                                alt="Visit our Twitter page">
                            <h3 style="color:#266F8E;" class="">Tweet Us</h3>
                        </div>
                    </a>
                    <a href="https://youtube.com/channel/UCMkGZZEEB65O7VYmLoy8wSg" target="_blank" rel="noopener">
                        <div class="socialBtn">
                            <img src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/socialYoutube.png"
                                alt="Visit our YouTube Channel">
                            <h3 style="color:#1E5871;" class="">Watch Us</h3>
                        </div>
                    </a>

                    <a href="https://www.google.com/maps/place/The+Car+Loan+Centre/@51.6109178,0.4950853,17z/data=!3m1!4b1!4m5!3m4!1s0x47d8c47d6337a48b:0x94277186754edb7d!8m2!3d51.6109178!4d0.497274"
                        target="_blank" rel="noopener">
                        <div class="socialBtn">
                            <img src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/googleMyBusiness.png"
                                alt="Visit our Google Business page" rel="noopener">
                            <h3 style="color:#104860" class="">Discover Us</h3>
                        </div>
                    </a>
                </div>

                <div class="makesDifferentBg">
                    <div class="homeFooterSearch">
                        <app-search-vehicle></app-search-vehicle>
                    </div>


                    <div class="makesDifferentInner">
                        <div class="makesDiff-content">
                            <h4>What makes us different...</h4>
                            <ul>
                                <li class="car-icon">100's OF CARS IN STOCK</li>
                                <li class="thumbsUp-icon">BAD CREDIT SPECIALISTS - CCJS, DEFAULTS, ARREARS, ALL
                                    CONSIDERED</li>
                                <li class="pound-icon">A LENDER THAT CAN SAY YES!</li>
                                <li class="balloon-icon">NO BALLOON PAYMENTS</li>
                                <li class="map-icon">NATIONWIDE DELIVERY AVAILABLE!</li>
                            </ul>
                            <h4>...and all our Cars come with</h4>
                            <ul>
                                <li class="steering-wheel-icon">MOT, WARRANTY & INSPECTION <a
                                        routerLink="/warranty">more info</a></li>
                            </ul>
                        </div>
                    </div>


                </div>




            </div>
        </div>

    </div>

    <!-- middle content html beginning here -->



    <!-- right content html beginning here -->
    <div class="right-container forMobileform" id="requestCallbackMainId">
        <!--	---fixed wrapper Request A Callback...html beginning here-- - -->
        <div class="requestCallBack-fixed-wp">
            <app-request-callback></app-request-callback>
        </div>
    </div>
</div>
<!-- flex-container content html end here -->
<app-footer></app-footer>
