import { Injectable } from '@angular/core';

import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CommonFunctionsService {
  constructor(private activeRoute: ActivatedRoute, private router: Router,) {
  }



  printLog(message, hide?: boolean) {
    if (!hide) {
      console.log(message);
    }
  }



  navigateWithReplaceUrl(path: string) {
    this.printLog(this.activeRoute);
    this.router.navigate([path], { replaceUrl: true });
  }

  navigateWithoutReplaceUrl(path: string) {
    this.printLog(this.activeRoute);
    this.router.navigate([path]);
  }

  openSnackBar(message: string) {
    // let config = new MatSnackBarConfig();
    // config.panelClass = 'successSnackbar';
    // config.duration = this.snackBarDuration;
    // this._snackBar.open(message, null, config);

  }
  openErrorSnackBar(message: string) {
  //   let config = new MatSnackBarConfig();
  //   config.panelClass = 'errorSnackbar';
  //   config.duration = this.snackBarDuration;
  //   this._snackBar.open(message, null, config);
  // }


}

}



