import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Location } from "@angular/common";

@Component({
  selector: 'app-privacy-policy-content',
  templateUrl: './privacy-policy-content.component.html',
  styleUrls: ['./privacy-policy-content.component.scss']
})
export class PrivacyPolicyContentComponent implements OnInit {

  showtext: string;
  title = 'Privacy Policy | The Car Loan Centre';
  public href: string = "";

  constructor(location: Location,private titleService: Title,
    private metaTagService: Meta) {this.href = location.path(); }
  count: any;
  header: any;
  sticky: any;

  isRequestcaliked: boolean = true;

  seoTags()
  {
   this.titleService.setTitle(this.title);
   this.metaTagService.updateTag(
     { name: 'description', content: 'A description of the privacy policy regarding information we collect from you and how we use it responsibly.' }

  );
  this.metaTagService.updateTag(
    { name: 'keywords', content: '' },
   );
  }

  ngOnInit(): void {
    if(this.href.includes('policy')){
      this.seoTags();
}
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }
  showCallbackFrom() {
    document.getElementById("requestCallbackMainId").style.width = "100%";
    if (this.isRequestcaliked) {
      this.isRequestcaliked = false;
      document.getElementById("requestCallbackMainId").style.width = "100%";
    }
  }
  showLessBtn(elem) {

    if (this.showtext == "More search options") {
      this.showtext = "Show less";
      document.getElementById("arrowUpid").classList.add("arrowUp-icon");
      document.getElementById("showform-1").classList.add("showForm");
      document.getElementById("showform-2").classList.add("showForm");
      document.getElementById("showform-3").classList.add("showForm");
      document.getElementById("showform-4").classList.add("showForm");
      document.getElementById("showform-5").classList.add("showForm");

    } else {
      this.showtext = "More search options";
      document.getElementById("arrowUpid").classList.remove("arrowUp-icon");
      document.getElementById("showform-1").classList.remove("showForm");
      document.getElementById("showform-2").classList.remove("showForm");
      document.getElementById("showform-3").classList.remove("showForm");
      document.getElementById("showform-4").classList.remove("showForm");
      document.getElementById("showform-5").classList.remove("showForm");

    }



  }

  wobbleForm() {
    if ($(window).width() <= 991) {
        //callbackAnim.reverse()[1]();
    }
    else {
        $(".main-container-shadow").toggleClass("wobble")

        $(".main-container-shadow").delay(2000).queue(function () {
            $(".main-container-shadow").removeClass("wobble").dequeue();
        });
    }
  }

}
