import { HttpErrorResponse, HttpHandler, HttpHeaderResponse, HttpInterceptor, HttpProgressEvent, HttpRequest, HttpResponse, HttpSentEvent, HttpUserEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { MyStorage } from '../app/utils/myStorage';
import { Constants } from '../app/constants/constants';
import { CommonFunctionsService } from '../common/common-functions.service';
import { DataTransferService } from '../common/data-transfer.service';
import { APIURLS } from './api-url';


@Injectable({
  providedIn: 'root'
})
export class ApiInterceptorService implements HttpInterceptor {

  METHOD_POST = "POST";
  METHOD_PUT = "PUT";

  constructor(public constants: Constants, private dataService: DataTransferService
    , private myStorage: MyStorage
    , public commonFunctions: CommonFunctionsService) {
    // this.authData = JSON.parse(myStorage.getItem(this.constants.currentUser));

  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    //addModifiedOrCreatedByData(this, this.addAccesstokenToRequest(req))
    return next.handle(req).pipe(tap(evt => {
      if (evt instanceof HttpResponse) {
        removeCallsFromTotalCalls(evt.url);
        this.dataService.onHideShowLoader(false);
      }
    }),
      catchError((err: HttpErrorResponse) => {
        removeCallsFromTotalCalls(err.url);
        this.dataService.onHideShowLoader(false);
        let error = {};
        if (err.error) {
          let message = { error: err.error }
          error = { status: err.status, message: message };
        }
        else
          error = { status: err.status, message: getErrorMsgFromErrorCode(err, this) }
        return throwError(error);
      }));
  }
  handle400Error(error) {
    return throwError(error);
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    return throwError(req);
  }


  private addAccesstokenToRequest(req: HttpRequest<any>) {

    let token = this.myStorage.getItem(this.constants.access_token);

    //let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiQWRtaW4iLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9jb3VudHJ5IjoiRW5nbGFuZCIsIkVtcGxveWVlSWQiOiJUQ0xDMDAxIiwiVGVuYW50SWQiOiIxIiwiZmtFbXBJZCI6IjEiLCJQcm9maWxlSWQiOiIxIiwiVXNlcklkIjoiMSIsIm5iZiI6MTYwMzM5NDMzOSwiZXhwIjoxNjAzNDgwNzM5LCJpc3MiOiJUQ0xDIn0.wjAZwBG6laQIKwmhdUUze_V20ASMh-3lJk7hRuwBAyc";
    if (!req.url.includes('VehicleCustomer/GetAllVehicleWithImage') && !req.url.includes('VehicleCustomer/GetImage')) {
    if (token) {
      req = req.clone({
        setHeaders: {
          'Authorization': "Bearer " + token,
        }
      });
    }
  }
    // if (req.url.includes(APIURLS.FORGOT_PASSWORD_BASE)) {
    //   if (req.url.includes(APIURLS.GET_CUSTOMER_FORGOT_DETAIL))
    //     return req;
    //     let token = JSON.parse(this.myStorage.getItem(this.constants.tempUserDetailForForgotPassword)).access_token;

    //   req = req.clone({
    //     setHeaders: {
    //       'Authorization': "Bearer " + token,
    //     }
    //   });

    // }
    // else if (req.url != APIURLS.BR_CUSTOMER_PORTAL_BASE_URL + APIURLS.BR_CUSTOMER_PORTAL_LOGIN && req.url != APIURLS.BR_CUSTOMER_PORTAL_BASE_URL + APIURLS.BR_FORGOTPASSWORD_API && req.url != APIURLS.BR_REGISTER) {
    //   let token = JSON.parse(this.myStorage.getItem(this.constants.currentUser)).access_token;
    //   if (token) {
    //     req = req.clone({
    //       setHeaders: {
    //         'Authorization': "Bearer " + token,
    //       }
    //     });
    //   }
    // }
    return req;
  }
}
function getErrorMsgFromErrorCode(error: HttpErrorResponse, context: ApiInterceptorService) {
  switch (error.status) {
    case context.constants.errorUnauthorized:
      return context.constants.errorMsgUnauthorized;
      break;
    case context.constants.errorNotFound:
      return context.constants.erroMsgNotFound + '-' + error.url;
    case context.constants.errorDuplicate:
      context.commonFunctions.openErrorSnackBar(error.error.Message);
      return error.error.Message;
    case context.constants.error409:
      return error.error;

    default:
      console.log(error)
      if (error.error && error.error.Message) {
        context.commonFunctions.openErrorSnackBar(error.error.Message);
        return error.error.Message;
      }
      else if (error.message)
        return error.message;
      else
        return context.constants.errorMsgForDefault;
  }
}

function addModifiedOrCreatedByData(context: ApiInterceptorService, req: HttpRequest<any>) {
  //AppComponent.totalCalls.push(req.url);
  if (req.url.includes("https://preliver2apims.azurewebsites.net/api/sales/TCLCWebApp/RequestCallBack"))
    if (req.url.includes(APIURLS.BR_BASE_URL)) {
      return req;




    }
  return req;
  //req.body = reqBody;
}

function removeCallsFromTotalCalls(url: string) {
  // let index = AppComponent.totalCalls.indexOf(url);
  // AppComponent.totalCalls.splice(index, 1);

}
