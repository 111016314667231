export class SearchForAVehicle {
    general: string="";
    make: string="";
    transmission: string="";
    bodytype: string="";
    maxprice: string="";
    fueltype: string="";
    regyear: string="";
    seats: string="";
    enzinesize: string="";
    price: string="";

}



