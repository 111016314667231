import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import moment from 'moment';
import { onlyNumeric, requiredField, minMaxLengthValidator, minLengthValidator, pattern, requiredFieldWithZero, } from '../common/CustomValidators';
import { Constants } from '../constants/constants';
// import { constants } from 'buffer';
// import { isString, isNumber } from 'util';
import { WhiteSpaceValidator } from './WhiteSpaceValidator';

@Injectable({
  providedIn: 'root'
})
export class ValidationsService {
  error: any = { isError: false, errorMessage: '' };
  isValidDate: any;
  constructor(public constants: Constants) { }
  public addRequiredValidation(): ValidatorFn {
    return Validators.compose([this.requiredField()]);
  }
  public checkButoncheckedOrnot(): ValidatorFn {
    return Validators.compose([this.validateIfChecked("Not Checked")]);

  }
  public checkAge(): ValidatorFn {
    return Validators.compose([this.validateIfAge18("Not Eligible")]);

  }
  public addNoSpeicalCharacterInStringValidation(): ValidatorFn {
    var re = /^[A-Za-z0-9 ]+$/;
    return Validators.compose([pattern(re, this.constants.errorInvalidText), minMaxLengthValidator(2, 100), WhiteSpaceValidator.cannotContainSpace]);
  }

  public addEmailValidation(): ValidatorFn {

    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return Validators.compose([this.pattern(re, this.constants.errorInvalidEmailAddress)]);
  }


  public addPhoneNumberValidation(minLength: number): ValidatorFn {
    {
      var re = (/^(?!0.)\d+$/);
      return Validators.compose([requiredField(), minLengthValidator(minLength), WhiteSpaceValidator.cannotContainSpace]);
    }
    return null;
  }
  requiredField(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let v: any = control?.value;
      if ((v)) {
        if (v < 0) {
          return { 'requiredField': this.constants.errorEmpty }
        }
      } else if (!v || v.length <= 0) {
        return { 'requiredField': this.constants.errorEmpty }
      }
      return null;
    }
  }

  pattern(pattern, errorMsg: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let v: any = control?.value;
      if (v != null && v != "") {
        v = (v) ? v : v.toString();
        if (!v || !v.match(pattern)) {
          return { 'pattern': errorMsg }
        }
      }
      return null;
    }
  }

  validateIfChecked(errormsg): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let v: any = control?.value;
      if (!v) {
        return {
          'err': "checkbox not checked"
        };
      }
      return null;
    }
  }

  validateIfAge18(errormsg): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let v: any = control?.value;
        let age = moment().diff(v, 'years', false)
        if (age < 18) {
          return {
            'agenoteligible': this.constants.agenoteligible
          };
        }
      return null;
    }
  }


  patternphone(pattern, errorMsg: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let v: any = control?.value;
      if (v != null && v != "") {
        v = (v) ? v : v.toString();
        if (!v || !v.match(pattern)) {
          return { 'pattern': errorMsg }
        }
      }
      return null;
    }
  }
}




