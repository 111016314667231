import { AfterViewInit, Component, HostListener, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { APIURLS } from 'src/api/api-url';
import { ApiService } from 'src/api/api.service';
import { ValidationsService } from 'src/app/common/validations.service';
import { Constants } from 'src/app/constants/constants';
import { ApiResponseCallback } from 'src/app/Interfaces/ApiResponseCallback';
import { RequestACallback } from 'src/app/modals/request-a-callback.model';
import { MyStorage } from '../../utils/myStorage';
import { RequestCallbackService } from './request-callback.service';
declare var $: any;

@Component({
  selector: 'app-request-callback',
  templateUrl: './request-callback.component.html',
  styleUrls: ['./request-callback.component.scss']
})
export class RequestCallbackComponent implements OnInit, AfterViewInit, ApiResponseCallback {
  requestcallbackformgroup: FormGroup;
  requestcall: RequestACallback = new RequestACallback();
  validationService: ValidationsService;
  submitClicked: boolean = false;
  utm_source = "";
  utm_medium = "";
  enterdcontact: "";
  public apiService: ApiService;
  accessTokenFromServer: boolean = false;
  maxlengthsize: boolean = false;
  maxcontactlength = "10";
  mincontactlength = "10";
  isRequestcaliked: boolean = false;
  token = '';
  @HostListener('document:touchend', ['$event'])
  onTouch(event): void {
    var modalCallBack = document.getElementById("requestCallbackMainId");
    if (event.target == modalCallBack) {
      modalCallBack.style.width = "0";
    }
  }
  // onGlobalClick(event): void {
  //   alert("ok")
  //   var modalCallBack = document.getElementById("requestCallbackMainId");
  // 		if (event.target == modalCallBack) {
  // 			modalCallBack.style.width = "0";
  // 	}
  // }


  constructor(private activatedRoute: ActivatedRoute,
    public requestcallbackservice: RequestCallbackService,
    public router: Router,
    public injector: Injector,
    public constants: Constants,
    public myStorage: MyStorage,
    public dialog: MatDialog,

  ) {
    this.validationService = injector.get(ValidationsService);

  }
  ngAfterViewInit(): void {

    $('.panel-collapse').on('show.bs.collapse', function () {
      $(this).siblings('.panel-heading').addClass('active');

    });
    $('.panel-collapse').on('hide.bs.collapse', function () {
      $(this).siblings('.panel-heading').removeClass('active');
    });
  }
  onSuccess(response: any) {
    this.isRequestcaliked = false;
    if (response) {
      if (this.accessTokenFromServer) {
        this.accessTokenFromServer = false;
        //this.myStorage.setItem(this.constants.access_token, response['access_token']);
        // this.token = response['access_token'];
      } else {
        // this.router.navigateByUrl(APIURLS.red_url);
        // window.location.href = APIURLS.red_url;
        this.router.navigate([APIURLS.red_url], { queryParams: { ct: "carfinance" } });
      }
    }
  }
  onError(errorCode: number, errorMsg: string) {
    // throw new Error('Method not implemented.');
    this.isRequestcaliked = false
  }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.utm_source = params['utm_source'];
      this.utm_medium = params['utm_medium'];
    });
    // window.onclick = function (event) {
    //   if (event.target == document.getElementById("requestCallbackMainId")) {
    //     document.getElementById("requestCallbackMainId").style.width = "0";
    //   }
    // }
    init(this)
  }


  // public getApiToken() {
  //   this.accessTokenFromServer = true;
  //   // this.requestcallbackservice.postRequestForAPITOken(this);
  // }

  numberOnly() {
    let self = this;
    debugger;
    $('#myinput').on('input', function () {
      var x = $(this).val();
      if (x.length == 1) {
        if (x == 0) {
          self.maxcontactlength = "11";
          self.mincontactlength = "10";
        } else if (x == 4) {
          self.maxcontactlength = "12";
          self.mincontactlength = "12";

        } else {
          x = '0' + x;
          self.maxcontactlength = "11";
          self.mincontactlength = "10";

        }
        this.enterdcontact = x;
        $(this).val(x);
      }
      else if (x.length == 2) {
        if ((x == 44) || (x == 0o0)) {
          self.maxcontactlength = "12";
          self.mincontactlength = "12";

        }

        else {
          var n = x.startsWith("04");
          if ((x != "04") && (x != "03") && (x != "02") && (x != "01") && (x != "05") && (x != "06") && (x != "07") && (x != "08") && (x != "09") && (x != "00")) {
            x = '0' + x;
            self.maxcontactlength = "11";
            self.mincontactlength = "10";

          }
        }
        this.enterdcontact = x;
        $(this).val(x);
      }
      else if (x.length == 3) {
        if (x == 0o00) {
          self.maxcontactlength = "11";
          self.mincontactlength = "10";
          x = '0';
        }
        this.enterdcontact = x;
        $(this).val(x);
      }

    });

    $(window).width() >= screen.width
    $('.panel-collapse').on('show.bs.collapse', function () {
      $(this).siblings('.panel-heading').addClass('active');
    });
    $('.panel-collapse').on('hide.bs.collapse', function () {
      $(this).siblings('.panel-heading').removeClass('active');
    });

  }


  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }
  //function for Request a callback
  showCallbackFrom() {
    document.getElementById("requestCallbackMainId").style.width = "100%";
    this.isRequestcaliked = false;
  }
  closeCallbackForm() {
    document.getElementById("requestCallbackMainId").style.width = "0";
    this.isRequestcaliked = true;
  }


  onRequestForCallbackClick() {
    
    this.activatedRoute.queryParams.subscribe(params => {
      this.utm_source = params['utm_source'];
      this.utm_medium = params['utm_medium'];
    });
    if (this.utm_medium != null && this.utm_source != null) {
      this.requestcall.visitorSource = this.utm_source;
      this.requestcall.visitorMedium = this.utm_medium;
      this.requestcall.visitorReference = "Main";
    }
    this.submitClicked = true;
    this.requestcall.firstname = this.requestcallbackformgroup.get('firstname').value;
    this.requestcall.surname = this.requestcallbackformgroup.get('surname').value;
    this.requestcall.email = this.requestcallbackformgroup.get('email').value;
    this.requestcall.contactnumber = this.requestcallbackformgroup.get('contactnumber').value;
    this.requestcall.istermclicked = this.requestcallbackformgroup.get('istermclicked').value;
    this.requestcall.isFuturemarketingclicked = this.requestcallbackformgroup.get('isFuturemarketingclicked').value;
    addFormValidation(this);
    if (this.requestcallbackformgroup.valid) {
      // this.getApiToken();
      setTimeout(() => {
        if ((this.requestcall.email != "")) {
          this.isRequestcaliked = true;
          this.requestcallbackservice.postRequestForSubmitCallbakRequest(this.requestcall,this);
          // var token = this.token;
          // if (token)
            
        }
      }, 300);

    }
  }
}

function addFormValidation(context: RequestCallbackComponent) {
  context.requestcallbackformgroup = new FormGroup({
    firstname: new FormControl((context.requestcall.firstname), context.validationService.addRequiredValidation()),
    surname: new FormControl((context.requestcall.surname), context.validationService.addRequiredValidation()),
    email: new FormControl((context.requestcall.email), context.validationService.addEmailValidation()),
    contactnumber: new FormControl((context.enterdcontact), context.validationService.addPhoneNumberValidation(+context.mincontactlength)),
    istermclicked: new FormControl((context.requestcall.istermclicked), context.validationService.checkButoncheckedOrnot()),
    isFuturemarketingclicked: new FormControl((context.requestcall.isFuturemarketingclicked)),
  });
}
function init(context: RequestCallbackComponent) {
  context.requestcallbackservice.setformGroup(context.requestcallbackformgroup);
  addFormValidation(context);
}
