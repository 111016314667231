<app-header></app-header>
<!-- <div class="body-bg-wp dark-bg">
     <div class="bgOpacity" style="background-image: url('https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/pageBg/cookiesBackground.jpg')"></div>

</div> -->
<div class="body-bg-wp dark-bg">

</div>
<!--flex-container content html beginning here-->
<div class="container-flex" id="mtopId">
    <!-- left menu html beginning here -->
    <div class="left-container forMobileBg" id="navForMobile">
        <!--	---fixed wrapper Menu and Search for a Vehicle...html beginning here-- - -->
        <div class="menuFixed-wp">
            <app-left-menu></app-left-menu>
        </div>
    </div>


    <!-- middle content html beginning here -->
    <div class="middle-container">
        <app-cookies-popup></app-cookies-popup>

        <div class="heading-wrapper">
            <h1 class="white">Cookies <span>Policy</span></h1>
        </div>

        <div class="callback-wp-fixed">
            <!-- <a (click)="showCallbackFrom()"><img src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/request-callback-icon.svg"  style="height: 40px; width: 40px;" alt="Request Call Back Icon"></a> -->
        </div>
        <div class="content-card whiteBgtran">

            <div class="termsPolicy-container">

                <h3 class="mt-0">IP Addresses and Cookies</h3>

                <p>
                    We may use cookies, a small text file that is downloaded to your computer’s hard drive so that we can recognise when you access our site. We use cookies that are functional and analytical with limited lifespan. We use cookies to tailor your experience
                    when you visit our site, to identify your operating system, browser type and for system administration. Where available this may include your IP address. We may collect statistical data about users’ browser actions that do not identify
                    an individual, this may be used to tailor the information we provide to users, provide aggregate information related to advertising and marketing that may be shared with advertising partners.
                </p>

                <p>
                    You may disable cookies by activating the appropriate settings on your browser. If you refuse to accept cookies you may be unable to access certain parts of our site. Unless you have adjusted your browser settings so that it will refuse cookies, our system
                    will issue cookies when you log on to our site.
                </p>

                <p>
                    Our website uses Facebook Tools. Facebook Tools enables Facebook to store and access cookies or other information on your device. You can opt out of this by going to <a class="applyNow" hreflang="en-gb" href="https://optout.aboutads.info/?c=2&lang=EN">https://optout.aboutads.info</a>                    or <a class="applyNow" hreflang="en-gb" href="https://youronlinechoices.eu/">https://youronlinechoices.eu/</a>
                </p>

                <p>
                    If you wish to disable cookies within your browser, you can do so by going to your browser settings and selecting the relevant option.
                </p>

            </div>


        </div>








    </div>
    <!-- middle content html beginning here -->




    <!-- right content html beginning here -->
    <div class="right-container forMobileform" id="requestCallbackMainId">
        <!--	---fixed wrapper Request A Callback...html beginning here-- - -->
        <div class="requestCallBack-fixed-wp">
            <app-request-callback></app-request-callback>
        </div>
    </div>
</div>
<!-- flex-container content html end here -->
<app-footersmall></app-footersmall>
