import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataTransferService {

  private hideShowLoaderSubject = new Subject<boolean>();
  hideShowLoaderObservable = this.hideShowLoaderSubject.asObservable();

  

  private currentPageUrlSubject = new BehaviorSubject<any>("");
  currentPageUrlObservable = this.currentPageUrlSubject.asObservable();

  private gridDataChangedSubject = new Subject<any>();
  gridDataChangedObservable = this.gridDataChangedSubject.asObservable();

  private tabChangedSubject = new Subject<string>();
  tabChangedObservable = this.tabChangedSubject.asObservable();

  private gridDataToParentSubject = new Subject<any>();
  gridDataToParentObservable = this.gridDataToParentSubject.asObservable();

  private shareDataSubject = new Subject<any>();
  shareDataObservable = this.shareDataSubject.asObservable();

  private dataFromApplicantSubject = new Subject<any>();
  dataFromApplicantObservable = this.dataFromApplicantSubject.asObservable();

  private shareVehicleDataToParentSubject = new Subject<any>();
  dataFromVehicleDetailObservable = this.shareVehicleDataToParentSubject.asObservable();

  private reloadTotalSubject = new Subject<any>();
  reloadTotaSubjectObservable = this.reloadTotalSubject.asObservable();

  private shareDataToNotesSubject = new BehaviorSubject<any>("");
  shareDataToNotesSubjectObservable = this.shareDataToNotesSubject.asObservable();

  private updatedStatusSubject = new Subject<any>();
  updatedStatusObservable = this.updatedStatusSubject.asObservable();

  constructor() { }
  onHideShowLoader(showLoader: boolean) {
    setTimeout(() => {
      this.hideShowLoaderSubject.next(showLoader);
    }, 100);

  }


  sendCurrentPageUrl(url: string) {
    this.currentPageUrlSubject.next(url);
  }

  onGridDataChanged(gridFor: any) {
    this.gridDataChangedSubject.next(gridFor);
  }

  onTabChanged(tabName: string) {
    this.tabChangedSubject.next(tabName);
  }
  sendDataToParentFromGrid(data) {
    this.gridDataToParentSubject.next(data);
  }
  
  sendDataFromApplicantToParent(data) {
    this.dataFromApplicantSubject.next(data);
  }
  
  shareData(data: any) {
    this.shareDataSubject.next(data);
  }
  
  shareVehicleDataToParent(data: any) {
    this.shareVehicleDataToParentSubject.next(data);
  }
  
  reloadTotalonParent(data: any) {
    this.reloadTotalSubject.next(data);
  }
  
  shareDataToNotes(data: any) {
    this.shareDataToNotesSubject.next(data);
  }
  
  shareUpdatedStatus(data: any) {
    this.updatedStatusSubject.next(data);
  }
  

}

