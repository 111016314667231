import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchForAVehicle } from '../../modals/search-for-a-vehicle.model';
import { SearchVehicleService } from './search-vehicle.service';
import { MasterList } from './master-list';

@Component({
  selector: 'app-search-vehicle',
  templateUrl: './search-vehicle.component.html',
  styleUrls: ['./search-vehicle.component.scss']
})
export class SearchVehicleComponent implements OnInit {
  searchforvehicleformgroup: FormGroup
  searchforvehicle: SearchForAVehicle = new SearchForAVehicle();
  searchURL: string;
  showtext = "More search options"
  showhidediv: boolean = false;
  showlessclicked: boolean = false;
  showmorelicked: boolean = true;
  searchClicked: boolean = false;
  make = [];
  transmission = [];
  bodytype = [];
  fueltype = [];
  regyear = [];
  seats = [];
  enzinesize = [];
  price = [];
  showMore: boolean = false;
  sortQuery;
  url;
  sorting = MasterList.sorting;
  searchItems: any;
  sortOnFlag: boolean = false;

  onTouch(event): void {
    var modalCallBack = document.getElementById("general");
    if (event.target == modalCallBack) {
      this.onSearchForVehicleClick();
    }
  }
  constructor(public searchVehicleService: SearchVehicleService, public router: Router, public activatedRoute: ActivatedRoute) {
    this.make = MasterList.make;
    this.fueltype = MasterList.fueltype;
    this.transmission = MasterList.transmission;
    this.bodytype = MasterList.bodytype;
    this.regyear = MasterList.regyear;
    this.seats = MasterList.seats;
    this.enzinesize = MasterList.enzinesize;
    this.price = MasterList.price;
  }

  ngOnInit(): void {
    this.url = this.router.url.includes('/cars');
    // console.log(this.url);

    this.activatedRoute.queryParams.subscribe(params => {
      this.sortQuery = params['search']
      // console.log(this.sortQuery?.substring(0, this.sortQuery?.indexOf('SearchQuery')));

    });
    init(this);
  }

  generateCustomLink(): string {
    if (this.searchClicked) {
      return this.searchURL;
    }
    else
      return null;
  }

  onSearchForVehicleClick() {
    this.searchVehicleService.searchItems1 = '';
    this.showMore = false;
    this.showtext = "More search options";
    this.searchClicked = true;
    this.searchVehicleService.searchVehicle(this.searchItems);
    let query = this.searchVehicleService.globalqueryparameter;
    if (this.searchItems?.length > 0 && this.sortOnFlag)
      query = this.searchItems + '&' + query
    this.router.navigate(['/cars'], { queryParams: { search: query } });
  }

  showLessBtn() {
    if (!this.showMore) {
      this.showMore = true;
      this.showtext = "Show less";
    } else {
      this.showMore = false;
      this.showtext = "More search options";
    }
  }

  onChangeSorting(event) {
    this.searchVehicleService.searchItems1 = '';
    this.sortOnFlag = true;
    this.searchItems = event
    let query = this.searchVehicleService.globalqueryparameter;
    this.router.navigate(['/cars'], { queryParams: { search: event + query } })

    this.searchVehicleService.searchVehicle(this.searchItems);

  }
}

function addFormValidation(context: SearchVehicleComponent) {
  context.searchforvehicleformgroup = new FormGroup({
    general: new FormControl((context.searchforvehicle.general)),
    make: new FormControl((context.searchforvehicle.make)),
    transmission: new FormControl((context.searchforvehicle.transmission)),
    bodytype: new FormControl((context.searchforvehicle.bodytype)),
    maxprice: new FormControl((context.searchforvehicle.maxprice)),
    price: new FormControl((context.searchforvehicle.maxprice)),
    fueltype: new FormControl((context.searchforvehicle.fueltype)),
    regyear: new FormControl((context.searchforvehicle.regyear)),
    seats: new FormControl((context.searchforvehicle.seats)),
    enzinesize: new FormControl((context.searchforvehicle.enzinesize)),


  });
}
function init(context: SearchVehicleComponent) {
  context.showMore = false;
  context.searchClicked = false;
  context.searchVehicleService.searchItems = '';
  addFormValidation(context);
  context.searchVehicleService.setformGroup(context.searchforvehicleformgroup);
}
