<div >
<nav class="nav-wrapper">
    <a href="javascript:void(0)" class="close-btn" (click)="closeNav()"> <img loading="lazy" src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/close-icon.svg" alt="Close Icon"></a>

    <ul >
        <li class="nav-home" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a routerLink="/" title="">Home</a></li>
        <li class="nav-car-finance" routerLinkActive="active"><a routerLink="/car-finance" title="">Car Finance</a></li>
        <li class="nav-view-our" routerLinkActive="active"><a routerLink="/cars" title="" *ngIf="!changeUrl" (click)="ongotopage()">View
        Our Cars</a></li>
        <!-- <li class="nav-saved-cars" routerLinkActive="active"><a (click)="openSavedCarpopup()" title="" *ngIf="!changeUrl">Saved Cars
        <i class="fa fa-gratipay" routerLinkActive="active"></i> <span> <sup>&nbsp;&nbsp;{{value}}</sup></span></a>
        </li>
        <li class="nav-logbook" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"><a routerLink="/logbook-loans" title="">Logbook Loans</a></li> -->
        <li class="nav-comparelogbook" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"><a routerLink="/logbook-loans/compare"  title="" *ngIf="changeUrl">Compare Logbook
        Loans</a></li>
        <li class="nav-customer" routerLinkActive="active"><a routerLink="/faqs" title="">FAQs</a></li>
        <!-- <li class="nav-faqs" routerLinkActive="active"><a routerLink="/jargon" title="">Jargon Buster</a></li> -->
        <li class="nav-faqs" routerLinkActive="active"><a routerLink="/review" title="">Customer Reviews</a></li>
        <li class="nav-about" routerLinkActive="active"><a routerLink="/about" title="">About</a></li>
        <li class="nav-contact" routerLinkActive="active"><a routerLink="/contact" title="">Contact</a></li>
        <!-- <li class="nav-apply" routerLinkActive="active"><a routerLink="/apply" title="">Apply</a></li> -->
    </ul>

</nav>
</div>
<!-- <noscript>

    <nav class="nav-wrapper">
<a href="javascript:void(0)" class="close-btn" (click)="closeNav()"> <img loading="lazy" src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/close-icon.svg" alt="Close Black Icon"></a>
    <ul>
        <li class="nav-home" routerLinkActive="active"><a href="/home" title="">Home</a></li>
        <li class="nav-car-finance" routerLinkActive="active"><a href="/car-finance" title="">Car Finance</a></li>
        <li class="nav-view-our" routerLinkActive="active"><a href="/cars" title="" *ngIf="!changeUrl">View
        Our Cars</a></li>
        <li class="nav-saved-cars" routerLinkActive="active"><a (click)="openSavedCarpopup()" title="" *ngIf="!changeUrl">Saved Cars
        <i class="fa fa-gratipay" routerLinkActive="active"></i> <span> <sup>&nbsp;&nbsp;{{value}}</sup></span></a>
        </li>
        <li class="nav-logbook" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"><a href="/logbook-loans" title="">Logbook Loans</a></li>
        <li class="nav-comparelogbook" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"><a href="/logbook-loans/compare"  title="" *ngIf="changeUrl">Compare Logbook
        Loans</a></li>
        <li class="nav-customer" routerLinkActive="active"><a href="/faqs" title="">FAQs</a></li>
        <li class="nav-faqs" routerLinkActive="active"><a href="/review" title="">Customer Reviews</a></li>
        <li class="nav-about" routerLinkActive="active"><a href="/about" title="">About</a></li>
        <li class="nav-contact" routerLinkActive="active"><a href="/contact" title="">Contact</a></li>
        <li class="nav-apply" routerLinkActive="active"><a href="/apply" title="">Apply</a></li>
    </ul>
</nav>
</noscript> -->
<!-- <app-search-for-a-vehicle *ngIf="isSearchpanel"></app-search-for-a-vehicle> -->
<!--	---fixed wrapper Menu and Search for a Vehicle...html end here-- - -->
