import { Injectable, Injector } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { APIURLS } from '../../../api/api-url';
import { BaseClassApiService } from '../../../global/base-class-api-service';

@Injectable({
  providedIn: 'root'
})
export class SearchVehicleService extends BaseClassApiService {
  searchItems: string = '';
  globalqueryparameter: string = '';
  searchItems1: string = '';
  constructor(injector: Injector, public _cookieService: CookieService) {
    super(injector);
  }
  public _formGroup: FormGroup;

  public getformGroup(): FormGroup {
    return this._formGroup;
  }
  public setformGroup(value: FormGroup) {
    this._formGroup = value;
  }

  searchVehicle(searchItems): Subject<any> {
    let self = this;
    self.searchItems = searchItems
    let subject: Subject<any> = new Subject();
    let requestJson = createRequest(searchItems, createRequestJson(searchItems, this), this);
    this.apiService.hitGetApi(APIURLS.getAxleBaseImageAndDetailURl(APIURLS.GETVEHICLELIST + '?' + requestJson), {
      onSuccess(response) {
        subject.next(response)
        setTimeout(() => {
          self.dataService.shareData({ response: response, searchItems: self.searchItems1, searchStr: requestJson });
        }, 500);
      }, onError(errorCode, errorMsg) {
      }
    })
    return subject;
  }
}

function createRequestJson(searchItems, context: SearchVehicleService) {
  context.searchItems = '';
  let requestJson = {};
  context.searchItems = '';

  Object.keys(context._formGroup.controls).forEach(key => {
    let value = context._formGroup.get(key).value;
    requestJson[key] = value;
    if (value) {
      if (key == 'regyear') { value = 'Reg Year ' + value + '+'; }
      if (key == 'price') { value = value.replace("less_", "Less Than £"); value = value.replace("more_", "More Than £"); }

      if (key == 'make') { value = requestJson['make']; }
      if (key == 'transmission') { value = requestJson['transmission']; }
      if (key == 'bodytype') { value = requestJson['bodytype']; }
      if (key == 'fueltype') { value = requestJson['fueltype']; }

      if (key == 'seats') { value = requestJson['seats']; }
      if (key == 'enzinesize') { value = requestJson['enzinesize']; }
      if (key == 'general') {

        var splitStr = value.toLowerCase().split(" ");
        for (var i = 0; i < splitStr.length; i++) {

          splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);

        }
        value = splitStr.join(', ');

      }

      if (context.searchItems1 && context.searchItems1?.length > 0) {

        context.searchItems1 = context.searchItems1 + ', ' + value;
      }

      else
        context.searchItems1 = value;
    }
  });

  return requestJson;
}

function createRequest(searchItems, requestJson, context: SearchVehicleService) {
  let searchURL = '';

  let searchQuery = "SearchQuery=" + requestJson['general']; if (!requestJson['general']) searchQuery = '';
  let make = "Make=" + requestJson['make']; if (!requestJson['make']) make = '';
  let transmission = "Transmission=" + requestJson['transmission']; if (!requestJson['transmission']) transmission = '';
  let bodytype = "BodyShape=" + requestJson['bodytype']; if (!requestJson['bodytype']) bodytype = '';
  let fueltype = "FuelType=" + requestJson['fueltype']; if (!requestJson['fueltype']) fueltype = '';
  let regyear = "registrationYear=" + requestJson['regyear']; if (!requestJson['regyear']) regyear = '';
  let seats = "Seats=" + requestJson['seats']; if (!requestJson['seats']) seats = '';
  let enzinesize = "EngineSize=" + requestJson['enzinesize']; if (!requestJson['enzinesize']) enzinesize = ''; else { enzinesize = "&EngineSize=" + requestJson['enzinesize'].replace("L", "") };

  searchURL = searchQuery
    + (make?.length > 0 ? '&' : '') + make
    + (transmission?.length > 0 ? '&' : '') + transmission
    + (bodytype?.length > 0 ? '&' : '') + bodytype
    + (fueltype?.length > 0 ? '&' : '') + fueltype
    + (regyear?.length > 0 ? '&' : '') + regyear
    + (seats?.length > 0 ? '&' : '') + seats
    + (enzinesize?.length > 0 ? '&' : '') + enzinesize;
  if (requestJson['price'] == 'more_10000') {
    if (requestJson['price']) searchURL = searchURL + "&minPrice=" + requestJson['price'].replace("more_", "");
  }
  else {
    if (requestJson['price']) searchURL = searchURL + "&maxPrice=" + requestJson['price'].replace("less_", "");
  }
  if (searchURL.indexOf('&') == 0)
    searchURL = searchURL.slice(1);

  context.globalqueryparameter = searchURL;
  searchURL = searchURL + "#VehiclesTop";
  if (searchItems?.length > 0){
    searchURL = searchItems + '&' + searchURL;
    console.log(searchURL);
  }
  return searchURL;
}
