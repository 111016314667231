import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss']
})
export class administrationComponent implements OnInit {
  title = 'administration | The Car Loan Centre';

  constructor(private titleService: Title,
    private metaTagService: Meta) { }
  isRequestcaliked: boolean = true;

  seoTags()
  {
   this.titleService.setTitle(this.title);
   this.metaTagService.updateTag(
     { name: 'description', content: 'The Asset Exchange Limited trading as The Car Loan Centre – In Administration.' }

  );1
  this.metaTagService.updateTag(
    { name: 'keywords', content: '' },
   );
  }
  ngOnInit(): void {
    this.seoTags();
  }

  showCallbackFrom() {
    document.getElementById("requestCallbackMainId").style.width = "100%";
    if (this.isRequestcaliked) {
      this.isRequestcaliked = false;
      document.getElementById("requestCallbackMainId").style.width = "100%";
    }
  }
}
