<app-header></app-header>
<div class="body-bg-wp dark-bg">

</div>
<!-- <div class="body-bg-wp dark-bg">
    <div class="bgOpacity" style="background-image: url('https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/pageBg/cookiesBackground.jpg')"></div>
    <div class="bgOpacity" style="background-color: #4d4d4d;"></div>
</div> -->
<!--flex-container content html beginning here-->
<div class="container-flex" id="mtopId">
    <!-- left menu html beginning here -->
    <div class="left-container forMobileBg" id="navForMobile">
        <!--	---fixed wrapper Menu and Search for a Vehicle...html beginning here-- - -->
        <div class="menuFixed-wp">
            <app-left-menu></app-left-menu>
        </div>
    </div>





    <!-- middle content html beginning here -->
    <div class="middle-container">
      <app-cookies-popup></app-cookies-popup>

      <div class="adminBanner">
        <!-- <img src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/administration.webp" alt="Finance driven by you" width="1558" height="536"
                  onerror="this.src='https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/administration.png'">            -->

                  <p>
                    The Asset Exchange Limited trading as The Car Loan Centre – In Administration <br />
                    I advise that The Asset Exchange Limited was placed into administration on 28 February 2024, with Ian Corfield, Geoff Rowley and Glyn Mummery of FRP Advisory Trading Limited appointed as joint administrators. <br />
               
                    The affairs, business and property of the Company are being managed by the Administrator(s) and who act as agents of the Company without personal liability. <br />
               
                    A list of our Insolvency Practitioners and their respective licensing bodies is available at <a href="https://www.frpadvisory.com/legal-and-regulatory-notices/#appointmenttakers">https://www.frpadvisory.com/legal-and-regulatory-notices/#appointmenttakers</a>. <br />
                    Insolvency Practitioners are bound by the Insolvency Code of Ethics when carrying out all professional work relating to an insolvency appointment. <br /> <br />
               
                    At present it is uncertain that goods and services ordered before we entered administration will be supplied.  If you have made a deposit for or paid for goods or services by credit or debit card (including charge and pre-paid cards) and you are advised that goods/order is not going to be fulfilled as expected, you may be able to get your money back by claiming a refund from your card issuer.  There may be time limits that apply. <br />
                    Please contact your card issuer as soon as you can if this may apply to you (there is no need to wait until the due date before contacting your card issuer).  Further information can be found here <a href="https://www.moneysavingexpert.com/reclaim/visa-mastercard-chargeback/#Little-Known%20Card%20Purchase%20Protection%20Which%20Can%20Get%20%C2%A31,000s%20Back">Debit & credit card chargeback: protection on faulty goods - MSE (moneysavingexpert.com)</a>. <br />
               
                </p>
    </div>

    </div>
    <!-- middle content html beginning here -->


    <!-- right content html beginning here -->
    <div class="right-container forMobileform" id="requestCallbackMainId">
        <!--	---fixed wrapper Request A Callback...html beginning here-- - -->
        <div class="requestCallBack-fixed-wp">
            <app-request-callback></app-request-callback>
        </div>
    </div>
</div>
<!-- flex-container content html end here -->
<app-footer></app-footer>
