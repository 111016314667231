<div class="termsPolicy-container">
  <div>
    <h3 class="mt-0">A. Introduction</h3>
    <p>These terms of use govern your use of our website; by using our website, you accept these terms of use in full.
      If
      you disagree with these terms of use or any part of these terms of use, you must not use our website.
    </p>

    <h3>B. Who can use this site?</h3>
    <p>This site and the products and services described on this site are for consumers only and only available to
      consumers who are resident in and who access the site from within Great Britain. We reserve the right to amend the
      content of this
      site including the value or price of any services offered.</p>

    <h3>C. Copyrights, Trademarks and copying materials on this site</h3>
    <p>Unless otherwise stated, we or our licensors own the intellectual property rights in the website and material on
      the website. Subject to the licence below, all these intellectual property rights are reserved. We give no
      permission for
      the use of our trademarks, and such use may constitute an infringement of our rights. You may view, download for
      caching purposes only, and print pages from the website for your own personal use, subject to the restrictions set
      out
      below and elsewhere in these terms of use.</p>
    <p>You must not:</p>
    <ul>
      <li>republish material from this website (including republication on another website);</li>
      <li>sell, rent or sub-licence material from the website;</li>
      <li>show any material from the website in public;</li>
      <li>reproduce, duplicate, copy or otherwise exploit material on our website for a commercial purpose;</li>
      <li>edit or otherwise modify any material on the website;</li>
      <li>redistribute material from this website except for content specifically and expressly made available for
        redistribution.
      </li>
    </ul>
  </div>
  <div>
    <h3>D. Acceptable Use</h3>
    <p>You must not use our website in any way that causes, or may cause, damage to the website or impairment of the
      availability or accessibility of the website; or in any way which is unlawful, illegal, fraudulent or harmful, or
      in
      connection
      with any unlawful, illegal, fraudulent or harmful purpose or activity.</p>
    <p>You must not use our website to copy, store, host, transmit, send, use, publish or distribute any material which
      consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit or other
      malicious
      computer software.</p>
    <p>You must not conduct any systematic or automated data collection activities (including without limitation
      scraping,
      data mining, data extraction and data harvesting) on or in relation to our website without our express written
      consent.</p>

    <h3>E. Warranties</h3>
    <p>We do not warrant the completeness or accuracy of the information published on this website; nor do we commit to
      ensuring that the website remains available or that the material on the website is kept up-to-date.</p>
    <p>To the maximum extent permitted by applicable law we exclude all representations, warranties and conditions
      relating to this website and the use of this website (including, without limitation, any warranties implied by law
      of satisfactory
      quality, fitness for purpose and/or the use of reasonable care and skill).</p>

    <h3>F. Third party websites</h3>
    <p>Our website may include hyperlinks to other websites owned and operated by third parties. These links are not
      recommendations. We have no control over the contents of third party websites, and we accept no responsibility for
      them or for
      any loss or damage that may arise from your use of them.</p>

    <h3>G. Exclusion of liability</h3>
    <p>Nothing in these terms of use will: (a) limit or exclude our or your liability for death or personal injury
      resulting from negligence; (b) limit or exclude our or your liability for fraud or fraudulent misrepresentation;
      (c)
      limit any
      of our or your liabilities in any way that is not permitted under applicable law; or (d) exclude any of our or
      your
      liabilities that may not be excluded under applicable law.</p>
    <p>The limitations and exclusions of liability set out in this section and elsewhere in these terms of use: (a) are
      subject to the preceding paragraph; and (b) govern all liabilities arising under the terms of use or in relation
      to
      the subject
      matter of the terms of use, including liabilities arising in contract, in tort (including negligence) and for
      breach
      of statutory duty.</p>
  </div>
  <div>
    <p>To the extent that the website and the information and services on the website are provided free-of-charge, we
      will
      not be liable for any loss or damage of any nature.</p>
    <p>We will not be liable to you in respect of any losses arising out of any event or events beyond our reasonable
      control.</p>
    <p>We will not be liable to you in respect of any losses, including (without limitation) loss of or damage to
      profits,
      income, revenue, use, production, anticipated savings, business, contracts, commercial opportunities or goodwill.
    </p>
    <p>We will not be liable to you in respect of any loss or corruption of any data, database or software.</p>
    <p>We will not be liable to you in respect of any special, indirect or consequential loss or damage.</p>

    <h3>H. Assignment</h3>
    <p>We may transfer, sub-contract or otherwise deal with our rights and/or obligations under these terms of use
      without
      notifying you or obtaining your consent.</p>
    <p>You may not transfer, sub-contract or otherwise deal with your rights and/or obligations under these terms of
      use.
    </p>

    <h3>I. Severability</h3>
    <p>If a provision of these terms of use is determined by any court or other competent authority to be unlawful
      and/or
      unenforceable, the other provisions will continue in effect. If any unlawful and/or unenforceable provision would
      be
      lawful
      or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of the provision
      will continue in effect.</p>

    <h3>J. Exclusion of third party rights</h3>
    <p>These terms of use are for the benefit of you and us, and are not intended to benefit any third party or be
      enforceable by any third party. The exercise of our and your rights in relation to these terms of use is not
      subject
      to the consent
      of any third party.</p>

    <h3>K. Law and jurisdiction</h3>
    <p>This site and these conditions are governed by and construed in accordance with English law and any disputes
      relating to these terms will be subject to the exclusive jurisdiction of the Courts of England &amp; Wales.
    </p>
  </div>
</div>