import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Paths } from 'src/app/constants/paths';
import { Location } from "@angular/common";
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit,AfterViewInit {
  public href: string = "";
  public showForLogbookPages : boolean = false;
  year;
  constructor(location: Location,public router:Router) {
    this.href = location.path();
  }
  ngAfterViewInit(): void {


  }

  ngOnInit(): void {
    this.year = new Date().getFullYear();
    if(this.href.includes('logbook-loans') || this.href.includes('logbook-loans/compare')){
      console.log('i was here');

      this.showForLogbookPages = true;

    }
    else{
      this.showForLogbookPages = false;
    }

  }

  moveToJargonBusterPage()
  {
    this.router.navigateByUrl(Paths.pathjargonBuster);
  }
}
