import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { Paths } from './constants/paths';
import { AboutComponent } from './views/about/about.component';
import { ApplyComponent } from './views/apply/apply.component';
// import { BadCreditCarLoansComponent } from './views/bad-credit-car-loans/bad-credit-car-loans.component';
import { BenefitsCarFinanceComponent } from './views/benefits-car-finance/benefits-car-finance.component';
import { CarFinanceInEssexComponent } from './views/car-finance-in-essex/car-finance-in-essex.component';
import { CarFinanceComponent } from './views/car-finance/car-finance.component';
import { CarInfoComponent } from './views/car-info/car-info.component';
import { CcjLoansComponent } from './views/ccj-loans/ccj-loans.component';
import { CentreAssessmentsComponent } from './views/centre-assessments/centre-assessments.component';
import { CompareLogbookLoansComponent } from './views/compare-logbook-loans/compare-logbook-loans.component';
import { ComplaintProcedureComponent } from './views/complaint-procedure/complaint-procedure.component';
import { ContactComponent } from './views/contact/contact.component';
import { CookiesComponent } from './views/cookies/cookies.component';
import { DeclinedCarFinanceComponent } from './views/declined-car-finance/declined-car-finance.component';
import { DefaultCarFinanceComponent } from './views/default-car-finance/default-car-finance.component';
import { FaqComponent } from './views/faq/faq.component';
import { HomepageComponent } from './views/homepage/homepage.component';
import { LogBookLoansComponent } from './views/logbook-loans/logbook-loans.component';
// import { ManchesterCarFinanceComponent } from './views/manchester-car-finance/manchester-car-finance.component';
import { NoCreditScoringComponent } from './views/no-credit-scoring/no-credit-scoring.component';
import { NoGuarantorCarFinanceComponent } from './views/no-guarantor-car-finance/no-guarantor-car-finance.component';
import { NotFoundComponent } from './views/not-found/not-found.component';
import { OffersComponent } from './views/offers/offers.component';
// import { PoorCreditCarFinanceComponent } from './views/poor-credit-car-finance/poor-credit-car-finance.component';
import { PrivacyAndPolicyComponent } from './views/privacy-and-policy/privacy-and-policy.component';
// import { PromoComponent } from './views/promo/promo.component';
// import { BlogDetailsComponent } from './views/blog-details/blog-details.component';
// import { BlogComponent } from './views/blog/blog.component';
import { PromoComponent } from './views/promo/promo.component';
import { ReviewsComponent } from './views/reviews/reviews.component';
import { SpecialistCarFinanceComponent } from './views/specialist-car-finance/specialist-car-finance.component';
import { TermsAndConditionComponent } from './views/terms-and-condition/terms-and-condition.component';
import { ThankyouApplyPageComponent } from './views/thankyou-apply-page/thankyou-apply-page.component';
import { ThankyouPageComponent } from './views/thankyou-page/thankyou-page.component';
import { UsedCarFinanceComponent } from './views/used-car-finance/used-car-finance.component';
import { ViewOurCarsComponent } from './views/view-our-cars/view-our-cars.component';
import { WarrantyComponent } from './views/warranty/warranty.component';
import { WelcomeComponent } from './views/welcome/welcome.component';
import { administrationComponent } from './views/administration/administration.component';



const routes: Routes = [
  // { path: '', redirectTo: Paths.pathHome, pathMatch: 'full' },
  { path: 'welcome/:source', component: administrationComponent },
  { path: 'welcome', component: administrationComponent },
  { path: 'Welcome/:source', component: administrationComponent },
  { path: 'Welcome', component: administrationComponent },
  { path: '', component: HomepageComponent },
  { path: Paths.pathHome, component: HomepageComponent },
  { path: Paths.pathFAQ, component: administrationComponent },
  { path: Paths.pathAbout, component: administrationComponent },
  { path: Paths.pathApply, component: administrationComponent },
  { path: Paths.pathBenefitsLoans, component: administrationComponent },
  { path: Paths.pathCarFinance, component: administrationComponent },
  { path: Paths.pathCarFinanceInEssex, component: administrationComponent },
  { path: Paths.pathCarDetails, component: administrationComponent },
  { path: Paths.pathCCJLoans, component: administrationComponent },
  { path: Paths.pathCentreAssessments, component: administrationComponent },
  { path: Paths.pathCompareLogBookLoan, component: administrationComponent },
  { path: Paths.pathComplaintProcedure, component: administrationComponent },
  { path: Paths.pathContact, component: ContactComponent },
  { path: Paths.pathCookies, component: CookiesComponent },
  { path: Paths.pathDeclinedCarFinance, component: administrationComponent },
  { path: Paths.pathdefaultcarfinance, component: administrationComponent },
  { path: Paths.pathLogBookLoan, component: administrationComponent },
  // { path: Paths.pathManchesterCarFinance, component: ManchesterCarFinanceComponent },
  { path: Paths.pathNoCardScoring, component: administrationComponent },
  { path: Paths.pathnoguarantorcarfinance, component: administrationComponent },
  { path: Paths.pathOffers, component: administrationComponent },
  { path: Paths.pathPrivacyPolicy, component: administrationComponent },
  { path: Paths.pathReview, component: administrationComponent },
  { path: Paths.pathspecialistcarfinance, component: administrationComponent },
  { path: Paths.pathTermsAndCondition, component: administrationComponent },
  { path: Paths.pathUsedCarFinance, component: administrationComponent },
  { path: Paths.pathViewOurCars, component: administrationComponent },
  { path: Paths.pathWarranty, component: administrationComponent },
  { path: Paths.pathPromo, component: administrationComponent },
  { path: Paths.pathadministration, component: administrationComponent },
  // { path: Paths.pathBlog, component: BlogComponent },
  // { path: Paths.pathBlogDetails1, component: BlogDetailsComponent },


  // { path: Paths.pathFAQ, loadChildren: () => import('./views/faq/faq.module').then(m => m.FaqModule) },
  // { path: Paths.pathAbout, loadChildren: () => import('./views/about/about.module').then(m => m.AboutModule) },
  // { path: Paths.pathApply, loadChildren: () => import('./views/apply/apply.module').then(m => m.ApplyModule) },
  // { path: Paths.pathBadCreditLoans, loadChildren: () => import('./views/bad-credit-car-loans/bad-credit-car-loans.module').then(m => m.BadCreditCarLoansModule) },
  // { path: Paths.pathBenefitsLoans, loadChildren: () => import('./views/benefits-car-finance/benefits-car-finance.module').then(m => m.BenefitsCarFinanceModule) },
  // { path: Paths.pathCarFinance, loadChildren: () => import('./views/car-finance/car-finance.module').then(m => m.CarFinanceModule) },
  // { path: Paths.pathCarFinanceInEssex, loadChildren: () => import('./views/car-finance-in-essex/car-finance-in-essex.module').then(m => m.CarFinanceInEssexModule) },
  // { path: Paths.pathCarDetails, loadChildren: () => import('./views/car-info/car-info.module').then(m => m.CarInfoModule) },
  // { path: Paths.pathCCJLoans, loadChildren: () => import('./views/ccj-loans/ccj-loans.module').then(m => m.CcjLoansModule) },
  // { path: Paths.pathCentreAssessments, loadChildren: () => import('./views/centre-assessments/centre-assessments.module').then(m => m.CentreAssessmentsModule) },
  // { path: Paths.pathCompareLogBookLoan, loadChildren: () => import('./views/compare-logbook-loans/compare-logbook-loans.module').then(m => m.CompareLogbookLoansComponentModule) },
  // { path: Paths.pathComplaintProcedure, loadChildren: () => import('./views/complaint-procedure/complaint-procedure.module').then(m => m.ComplaintProcedureModule) },
  // { path: Paths.pathContact, loadChildren: () => import('./views/contact/contact.module').then(m => m.ContactModule) },
  // { path: Paths.pathCookies, loadChildren: () => import('./views/cookies/cookies.module').then(m => m.CookiesModule) },
  // { path: Paths.pathDeclinedCarFinance, loadChildren: () => import('./views/declined-car-finance/declined-car-finance.module').then(m => m.DeclinedCarFinanceModule) },
  // { path: Paths.pathdefaultcarfinance, loadChildren: () => import('./views/default-car-finance/default-car-finance.module').then(m => m.DefaultCarFinanceModule) },
  // { path: Paths.pathLogBookLoan, loadChildren: () => import('./views/logbook-loans/logbook-loans.module').then(m => m.LogBookLoansModule) },
  // { path: Paths.pathManchesterCarFinance, loadChildren: () => import('./views/manchester-car-finance/manchester-car.module').then(m => m.ManchesterCarFinanceModule) },
  // { path: Paths.pathNoCardScoring, loadChildren: () => import('./views/no-credit-scoring/no-credit-scoring.module').then(m => m.NoCreditScoringModule) },
  // { path: Paths.pathnoguarantorcarfinance, loadChildren: () => import('./views/no-guarantor-car-finance/no-guarantor-car-finance.module').then(m => m.NoGuarantorCarFinanceModule) },
  // { path: Paths.pathOffers, loadChildren: () => import('./views/offers/offers.module').then(m => m.OffersModule) },
  // { path: Paths.pathpoorcreditcarfinance, loadChildren: () => import('./views/poor-credit-car-finance/poor-credit-car-finance.module').then(m => m.PoorCreditCarFinanceModule) },
  // { path: Paths.pathPrivacyPolicy, loadChildren: () => import('./views/privacy-and-policy/privacy-and-policy.module').then(m => m.PrivacyAndPolicyModule) },
  // { path: Paths.pathReview, loadChildren: () => import('./views/reviews/reviews.module').then(m => m.ReviewsModule) },
  // { path: Paths.pathspecialistcarfinance, loadChildren: () => import('./views/specialist-car-finance/specialist-car-finance.module').then(m => m.SpecialistCarFinanceModule) },
  // { path: Paths.pathTermsAndCondition, loadChildren: () => import('./views/terms-and-condition/terms-and-condition.module').then(m => m.TermsAndConditionModule) },
  // { path: Paths.pathUsedCarFinance, loadChildren: () => import('./views/used-car-finance/used-car-finance.module').then(m => m.UsedCarFinanceModule) },
  // { path: Paths.pathViewOurCars, loadChildren: () => import('./views/view-our-cars/view-our-cars.module').then(m => m.ViewOurCarsModule) },
  // { path: Paths.pathWarranty, loadChildren: () => import('./views/warranty/warranty.module').then(m => m.WarrantyModule) },
  { path: Paths.pathThankyouSubmitRequest, component: administrationComponent },
  { path: Paths.pathApplyThankyouSubmitRequest, component: administrationComponent },


  { path: '**', component: NotFoundComponent },


];
@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'top',   relativeLinkResolution: 'legacy', initialNavigation: 'enabled',onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
