import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GenericDialogsService } from '../../popup/generic-dialogs.service'
import $ from 'jquery';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isRequestcaliked: boolean = true;
  constructor(public router: Router, public _cookieService: CookieService,
    public _GenericDialogsService: GenericDialogsService) { }
  header: any;
  sticky: any;
  isChatBotLoaded: boolean = false;
  changeLogoText: boolean = false;
  isSticky: boolean = false;

  ngOnInit() {
    this._GenericDialogsService.closeSavedCarPopup();
    console.log('text ' + this.router.url);
    if (this.router.url == '/cars' || this.router.url.includes('/cars/info'))
      this.changeLogoText = false;
    else
      this.changeLogoText = true;
    // if (this.router.url == '/cars') {
    //   this._cookieService.set('_getOldPageIndexId', "1", { expires: 1, path: '/' });
    // }
    // if (this.router.url == '/info') {
    //   this._cookieService.set('_getOldPageIndexId', "1", { expires: 1, path: '/' });
    // }
  }
  public get value(): number {
    return this._GenericDialogsService.savedCount;
  }

  ngAfterViewInit() {
    let self = this;
    window.onscroll = function () { headerScrollFixed(self) }.bind(this);
  }

  showMenuLeft() {
    document.getElementById("navForMobile").style.width = "100%";
  }
  ongotopage()
  {
    if (this.router.url == '/cars'){
      this._cookieService.set('_getOldPageIndexId', "1", { expires: 1, path: '/' });
      window.open('/cars', "_self");
    }


  }
  showCallbackFrom() {
    document.getElementById("requestCallbackMainId").style.width = "100%";
    if (this.isRequestcaliked) {
      this.isRequestcaliked = false;
      document.getElementById("requestCallbackMainId").style.width = "100%";
    }
  }
  openSavedCarpopup() {
    this._GenericDialogsService.getvehicleDetailsByIDs();
    this._GenericDialogsService.showSavedCarPopup();
    window.scroll(0, 0);
  }

}

function headerScrollFixed(context: HeaderComponent) {
  context.header = document.getElementById("myHeader");
  context.sticky = context.header.offsetTop;
  var stepper = document.getElementById("stepperId");

  if (window.pageYOffset > context.sticky) {
    context.header.classList.add("sticky");
    if (document.getElementById('stepperId'))
      stepper.classList.add("stepperFixed");
    document.getElementById("logoWebId").classList.add("hideCotent");
    document.getElementById("contectId").classList.add("hideCotent");
    document.getElementById("emailId").classList.add("hideCotent");
    document.getElementById("compressedLogoId").classList.add("show-comp-logo");
    document.getElementById("mtopId").classList.add("mTop50");
    // document.getElementById("indicatorFixedId").classList.add("indicatorFixed");
  } else {
    context.header.classList.remove("sticky");
    if (document.getElementById('stepperId'))
      stepper.classList.remove("stepperFixed");
    document.getElementById("logoWebId").classList.remove("hideCotent");
    document.getElementById("contectId").classList.remove("hideCotent");
    document.getElementById("emailId").classList.remove("hideCotent");
    document.getElementById("compressedLogoId").classList.remove("show-comp-logo");
    document.getElementById("mtopId").classList.remove("mTop50");
    // document.getElementById("indicatorFixedId").classList.remove("indicatorFixed");
  }
}

