import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
// import {getLCP, getFID, getCLS} from 'web-vitals';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ApiInterceptorService } from 'src/api/api-interceptor.service';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CookiesPopupComponent } from './popup/cookies-popup/cookies-popup.component';
// import { SavedCarPopupComponent } from './popup/saved-car-popup/saved-car-popup.component';
import { AgmCoreModule } from '@agm/core';
import { RouterModule } from '@angular/router';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import { OWL_DATE_TIME_LOCALE, OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { ValidationsErrorPopupComponent } from './popup/validations-error-popup/validations-error-popup.component';
import { SharedModule } from './shared/shared.module';
import { AboutComponent } from './views/about/about.component';
import { ApplyComponent } from './views/apply/apply.component';
import { BenefitsCarFinanceComponent } from './views/benefits-car-finance/benefits-car-finance.component';
// import { BlogDetailsComponent } from './views/blog-details/blog-details.component';
// import { BlogComponent } from './views/blog/blog.component';
import { CarFinanceInEssexComponent } from './views/car-finance-in-essex/car-finance-in-essex.component';
import { CarFinanceComponent } from './views/car-finance/car-finance.component';
import { CarInfoComponent } from './views/car-info/car-info.component';
import { CcjLoansComponent } from './views/ccj-loans/ccj-loans.component';
import { CentreAssessmentsComponent } from './views/centre-assessments/centre-assessments.component';
import { CompareLogbookLoansComponent } from './views/compare-logbook-loans/compare-logbook-loans.component';
import { ComplaintProcedureComponent } from './views/complaint-procedure/complaint-procedure.component';
import { ContactComponent } from './views/contact/contact.component';
import { CookiesComponent } from './views/cookies/cookies.component';
import { DeclinedCarFinanceComponent } from './views/declined-car-finance/declined-car-finance.component';
import { DefaultCarFinanceComponent } from './views/default-car-finance/default-car-finance.component';
import { FaqComponent } from './views/faq/faq.component';
import { HomepageComponent } from './views/homepage/homepage.component';
import { LogBookLoansComponent } from './views/logbook-loans/logbook-loans.component';
import { NoCreditScoringComponent } from './views/no-credit-scoring/no-credit-scoring.component';
import { NoGuarantorCarFinanceComponent } from './views/no-guarantor-car-finance/no-guarantor-car-finance.component';
import { NotFoundComponent } from './views/not-found/not-found.component';
import { OffersComponent } from './views/offers/offers.component';
import { PrivacyAndPolicyComponent } from './views/privacy-and-policy/privacy-and-policy.component';
import { PromoComponent } from './views/promo/promo.component';
import { ReviewsComponent } from './views/reviews/reviews.component';
import { SpecialistCarFinanceComponent } from './views/specialist-car-finance/specialist-car-finance.component';
import { TermsAndConditionComponent } from './views/terms-and-condition/terms-and-condition.component';
import { ThankyouApplyPageComponent } from './views/thankyou-apply-page/thankyou-apply-page.component';
import { ThankyouPageComponent } from './views/thankyou-page/thankyou-page.component';
import { UsedCarFinanceComponent } from './views/used-car-finance/used-car-finance.component';
import { ViewOurCarsComponent } from './views/view-our-cars/view-our-cars.component';
import { WarrantyComponent } from './views/warranty/warranty.component';
import { WelcomeComponent } from './views/welcome/welcome.component';
import { ManchesterCarFinanceComponent } from './views/manchester-car-finance/manchester-car-finance.component';
import { administrationComponent } from './views/administration/administration.component';
@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    // JargonBusterComponent,
    HomepageComponent,
    // SecuredImageComponent,
    // SavedCarPopupComponent,
    NotFoundComponent,
    ThankyouPageComponent,
    FaqComponent,
    AboutComponent,
    ApplyComponent,
    BenefitsCarFinanceComponent,
    CarFinanceComponent,
    CarFinanceInEssexComponent,
    CarInfoComponent,
    CcjLoansComponent,
    CentreAssessmentsComponent,
    CompareLogbookLoansComponent,
    ComplaintProcedureComponent,
    ContactComponent,
    CookiesComponent,
    DeclinedCarFinanceComponent,
    DefaultCarFinanceComponent,
    LogBookLoansComponent,
    ManchesterCarFinanceComponent,
    NoCreditScoringComponent,
    NoGuarantorCarFinanceComponent,
    OffersComponent,
    PrivacyAndPolicyComponent,
    ReviewsComponent,
    SpecialistCarFinanceComponent,
    TermsAndConditionComponent,
    UsedCarFinanceComponent,
    ViewOurCarsComponent,
    WarrantyComponent,
    ThankyouApplyPageComponent,
    PromoComponent,
    ValidationsErrorPopupComponent,
    administrationComponent,
    // BlogComponent,
    // BlogDetailsComponent

  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    NgxJsonLdModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDialogModule,
    MatCardModule,
    MatDialogModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    SharedModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD_XCZc6qCybCrr8JsTjWsZU1gkhV77kI4',
      libraries: ['places']
    }),
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 10 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:12000'
    }),
    RouterModule
  ],
  exports: [

  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: ApiInterceptorService, multi: true, },
  { provide: MatDialogRef, useValue: {} },
  { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-GB' },

    CookieService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    // SavedCarPopupComponent,
    CookiesPopupComponent,
    ValidationsErrorPopupComponent
  ]
})

export class AppModule { }
