<!-- header fixed wrapper beginning here -->

<div class="headerFixed-wrapper error-page-header" *ngIf="isNotFound">
    <!-- header html beginning here -->
    <header class="header-wrapper">
        <div class="flex-container-header">
            <div class="logo-wp">
                <a class="mobile-logo"> <img loading="lazy" src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/tclc-logo-mobile-new.svg" rel="noopener"
                        alt="Bad Credit Car Finance Specialists" usemap="#Map" title="Logo" width="115" height="44" href="../">
                    <map name="Map">
                        <area shape="rect" coords="-5,4,101,48" href="../">
                        <area shape="rect" coords="105,3,159,47" routerLink="/review">
                    </map>
                </a>

                <a href="../" id="logoWebId" class="logo-web-wp">
                    <img loading="lazy" src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/tclcHeaderLogo-new.svg" alt="Thecarloancentre"
                        title="Logo" class="logo-web" rel="noopener" width="162" height="62">
                    <!-- <p *ngIf="!changeLogoText">Bad Credit Car Finance Specialists <span class="inBlock"></span></p>
                    <p *ngIf="changeLogoText">Great Britain's <span class="textBold">CHEAPEST</span> Logbook Lender
                        <span class="inBlock"></span>
                    </p> -->
                </a>
                <a href="#" class="compressed-logo" id="compressedLogoId"> 
                    <img loading="lazy" src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/car-swoosh-white.svg" rel="noopener" alt="The Car Loan Centre" title="Logo">
                </a>
            </div>

        </div>

    </header>
    <!-- header html end here -->
</div>
<div *ngIf="!isRedirectedFromCarInfor">
    <div class="body-bg-wp error-page-bg" *ngIf="isNotFound">
        <div class="bgOpacity" style='background-image: url("https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/pageBg/404-background.png");'></div>
    </div>
    <div class="error-page-container" *ngIf="isNotFound">
        <div class="error-page-content">
            <h1>404</h1>
            <p>
                Oops!
                <br> We couldn't find the page you were looking for.
            </p>

            <div class="error-btn-wp">
                <a routerLink="/home"><button type="button" class="btn btn-orange-md">Back to Home &nbsp;<i class="fa fa-play"></i></button></a>
                <a routerLink="/cars"><button type="button" class="btn btn-green-md">View Our Cars &nbsp;<i class="fa fa-play"></i></button></a>
            </div>
        </div>
    </div>

</div>

<div *ngIf="isRedirectedFromCarInfor">
    <div class="body-bg-wp error-page-bg" *ngIf="isNotFound">
        <div class="bgOpacity" style='background-image: url("https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/pageBg/CarNoLongerAvailable.jpg"); opacity: 0.65!important'></div>
    </div>


    <div class="error-page-container" *ngIf="isNotFound">
        <div class="error-page-content">
            <h1>Sorry!</h1>
            <p>
                This car is no longer available.
                <br><br><br> But don't worry! We have 100s more cars in stock!
            </p>

            <div class="error-btn-wp">
                <a routerLink="/home"><button type="button" class="btn btn-orange-md">Back to Home &nbsp;<i class="fa fa-play"></i></button></a>
                <a routerLink="/cars"><button type="button" class="btn btn-green-md">View Our Cars &nbsp;<i class="fa fa-play"></i></button></a>
            </div>
        </div>
    </div>
</div>
