export class APIURLS {
  
  public static BR_BASE_URL: string = "https://preliver2apims.azurewebsites.net/api/";
  public static BR_BASE_URL_POSTCODE: string ="https://preliveR2customerjourneyapi.azurewebsites.net/api/"
  public static BR_AXLE_BASE_URL: string = "https://axleimageswithprivateuserapi.azurewebsites.net/api/";
  //  public static BR_AXLE_BASE_URL: string = "https://axleserver.thecarloancentre.co.uk/api/axleimages/";

  // public static BR_AXLE_BASE_URL: string = "https://axleserver.thecarloancentre.co.uk/api/axleimages/";
  // public static BR_BASE_URL_POSTCODE: string ="https://preliveR2customerjourneyapi.azurewebsites.net/api/";
  public static BR_AXLE_BASE_URL_APPLY: string = "https://axleimageswithprivateuserapi.azurewebsites.net/api/";
  //  public static BR_AXLE_BASE_URL_APPLY: string = "https://axleserver.thecarloancentre.co.uk/api/axleimages/";
  public static red_url: string = "success";
  public static REQUEST_CALL_BACK: string = "TCLCWebApp/RequestCallBack"
  public static LOGBOOK_REQUEST_CALL_BACK: string = "TCLCWebApp/LogBookRequestCallBack"
  public static GETVEHICLELIST: string = "AxleImageDetailsForMainWebWithImage/SearchVehicle"
  public static VEHICLEDETAILS: string = "AxleImageDetailsForMainWebWithImage/GetVehicleDetilsWithImages/"
  public static AXLEIMAGESWITHDETAILSGETBYPAGE: string = "AxleImageDetailsForMainWebWithImage/GetPaged?"
  public static AXLEIMAGESWITHDETAILSGETSINGLE: string = "AxleImageDetailsForMainWebWithImage/GetByAny/"
  public static AXLEIMAGESWITHDETAILSGETSINGLEIMAGES: string = "VehicleImageView/GetByAny/"
  public static AXLEIMAGESWITHDETAILSGETBYID: string =  "AxleImageDetailsForMainWebWithImage/GetAllVehicleDetails?"
  public static AXLEIMAGESGETTOTALVEHICLECOUNT: string =  "AxleImageDetailsForMainWebWithImage/GetTotalVehicleCount"
  public static VEHICLEEMAILSEND: string ="SendEmail";
  public static APPLYFORMSEND:string="TCLCWebApp/ApplyForm";
  public static GETPOSTCODE:string="MatrixRoutedAPI/GetPostCodeAutopopulateItems";
  public static GETADDRESSBYPOSTCODE:string="MatrixRoutedAPI/GetDoorDetailsWithPostcode";
  public static GETALLMASTERLIST="TCLCWebApp/GetAllMasterList"

  // public static getSubmitCallBackRequestBaseUrl(apiUrl) {
  //   return this.BR_BASE_URL + apiUrl;
  // }
  public static getAxleBaseImageAndDetailURl(apiUrl) {
    return this.BR_AXLE_BASE_URL + apiUrl;
  }
  public static getPostcodeCallBackRequestBaseUrl(apiUrl) {
    return this.BR_AXLE_BASE_URL + apiUrl;
  }
// need to delete
  public static getSubmitApplyCallBackRequestBaseUrl(apiUrl) {
    return this.BR_AXLE_BASE_URL_APPLY + apiUrl;
  }




}
