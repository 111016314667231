import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Location } from "@angular/common";

@Component({
  selector: 'app-terms-conditions-content',
  templateUrl: './terms-conditions-content.component.html',
  styleUrls: ['./terms-conditions-content.component.scss']
})
export class TermsConditionsContentComponent implements OnInit {
  isRequestcaliked: boolean = true;
  title = 'Website Terms | The Car Loan Centre';
  public href: string = "";
  constructor(location: Location,private titleService: Title,
    private metaTagService: Meta) { this.href = location.path(); }

    seoTags()
    {
     this.titleService.setTitle(this.title);
     this.metaTagService.updateTag(
       { name: 'description', content: 'Read the terms and conditions of using The Car Loan Centre website here. ' }

    );
    this.metaTagService.updateTag(
      { name: 'keywords', content: '' },
     );
    }

  ngOnInit(): void {
    if(this.href.includes('terms')){
      this.seoTags();
}
  }

  showCallbackFrom() {
    document.getElementById("requestCallbackMainId").style.width = "100%";
    if (this.isRequestcaliked) {
      this.isRequestcaliked = false;
      document.getElementById("requestCallbackMainId").style.width = "100%";
    }
  }

  wobbleForm() {
    if ($(window).width() <= 991) {
        //callbackAnim.reverse()[1]();
    }
    else {
        $(".main-container-shadow").toggleClass("wobble")

        $(".main-container-shadow").delay(2000).queue(function () {
            $(".main-container-shadow").removeClass("wobble").dequeue();
        });
    }
  }

}
