import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import $ from 'jquery';
import { SearchForAVehicle } from 'src/app/modals/search-for-a-vehicle.model';
import { RequestCallbackService } from '../../shared/request-callback/request-callback.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
    count: any;
  header: any;
  sticky: any;
  title = 'The Car Loan Centre – Leading Car Loan & Car Finance Company UK';
  isRequestcaliked: boolean = true;
  searchforvehicleformgroup: FormGroup
  showhidediv: boolean = false;
  showlessclicked: boolean = false;
  showmorelicked: boolean = true;
  showtext = "More search options"
  searchforvehicle: SearchForAVehicle = new SearchForAVehicle();
  makeArray = [];
  transmisisonArray = [];
  bodytypeArray = [];
  maxpriceArray = [];
  searchURL: string;
  constructor(public menuservice: RequestCallbackService, public router: Router, private titleService: Title,
    private metaTagService: Meta) {
   }

   seoTags()
   {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'We’re car loan specialists, supplying quality used cars and finance including bad credit, even if you’ve been declined elsewhere. Apply for car loans UK today.' }

    );
    this.metaTagService.updateTag(
      { name: 'keywords', content: '' },

    );
    this.metaTagService.updateTag(
      { name: 'OMG-Verify-V1', content: '0aac5326-1a5f-4f04-b5e6-5fe48554eeb0' },

    );
   }
  ngOnInit(): void {
    this.seoTags();
  }
  showLessBtnclick() {
    if (this.showlessclicked == false) {
      this.showlessclicked = true;
      this.showmorelicked = false;
      this.showhidediv = true;
    }
    else {
      this.showlessclicked = false;
      this.showmorelicked = true;
      this.showhidediv = false;
    }
  }

  showLessBtn(elem) {

    if (this.showtext == "More search options") {
      this.showtext = "Show less";
      document.getElementById("arrowUpid").classList.add("arrowUp-icon");
      document.getElementById("showform-1").classList.add("showForm");
      document.getElementById("showform-2").classList.add("showForm");
      document.getElementById("showform-3").classList.add("showForm");
      document.getElementById("showform-4").classList.add("showForm");
      document.getElementById("showform-5").classList.add("showForm");

    } else {
      this.showtext = "More search options";
      document.getElementById("arrowUpid").classList.remove("arrowUp-icon");
      document.getElementById("showform-1").classList.remove("showForm");
      document.getElementById("showform-2").classList.remove("showForm");
      document.getElementById("showform-3").classList.remove("showForm");
      document.getElementById("showform-4").classList.remove("showForm");
      document.getElementById("showform-5").classList.remove("showForm");

    }
  }
  generateCustomLink(): string {
    return this.searchURL;
  }
  onSearchForVehicleClick() {
    this.searchforvehicle.make = this.searchforvehicleformgroup.get('make').value;
    this.searchforvehicle.general = this.searchforvehicleformgroup.get('general').value;
    this.searchforvehicle.transmission = this.searchforvehicleformgroup.get('transmission').value;
    this.searchforvehicle.bodytype = this.searchforvehicleformgroup.get('bodytype').value;
    this.searchforvehicle.maxprice = this.searchforvehicleformgroup.get('price').value;
    this.searchforvehicle.fueltype = this.searchforvehicleformgroup.get('fueltype').value;
    this.searchforvehicle.regyear = this.searchforvehicleformgroup.get('regyear').value;
    this.searchforvehicle.seats = this.searchforvehicleformgroup.get('seats').value;
    this.searchforvehicle.enzinesize = this.searchforvehicleformgroup.get('enzinesize').value;


    this.searchURL = "../Cars?General="
      + this.searchforvehicle.general + "&Make=" + this.searchforvehicle.make
      + "&Transmission=" + this.searchforvehicle.transmission
      + "&BodyShape=" + this.searchforvehicle.bodytype
      + "&FuelType=" + this.searchforvehicle.fueltype
      + "&RegistrationYear=" + this.searchforvehicle.regyear
      + "&Seats=" + this.searchforvehicle.seats
      + "&EngineSize=" + this.searchforvehicle.enzinesize
      + "&Price=" + this.searchforvehicle.maxprice



      + "#VehiclesTop"
    this.generateCustomLink()
  }

  showCallbackFrom() {
    document.getElementById("requestCallbackMainId").style.width = "100%";
    if (this.isRequestcaliked) {
      this.isRequestcaliked = false;
      document.getElementById("requestCallbackMainId").style.width = "100%";
    }
  }
  wobbleForm() {
    if ($(window).width() <= 991) {
        //callbackAnim.reverse()[1]();
    }
    else {
        $(".main-container-shadow").toggleClass("wobble")

        $(".main-container-shadow").delay(2000).queue(function () {
            $(".main-container-shadow").removeClass("wobble").dequeue();
        });
    }
  }

}





