<div class="termsPolicy-container forTarget">
  <div>
    <p>
      We respect your privacy and are committed to protecting your personal data.
    </p>
    <p>
      The information set out in this privacy policy aims to inform you as to how we collect and look after your
      personal data when you visit our website, [
      <a class="applyNow" href="/home">www.thecarloancentre.co.uk</a>
      ]. It will also tell you about your privacy rights and how the law
      protects you.
    </p>
    <p>
      References to "<b>you</b>" or "<b>your</b>" are to you as an
      individual using our website or otherwise contacting us (either on
      behalf of yourself, your business or another individual or
      organisation).
    </p>
    <h2>THE CONTENT</h2>
    <p>
      <a (click)="scroll(whoweare)"><b>WHO WE ARE</b></a><br />
      <a (click)="scroll(datawecollect)"><b>THE DATA WE COLLECT ABOUT YOU</b></a><br />
      <a (click)="scroll(failpersonaldata)"><b>IF YOU FAIL TO PROVIDE PERSONAL DATA</b></a><br />
      <a (click)="scroll(collectpersonaldata)"><b>HOW WE COLLECT YOUR PERSONAL DATA</b></a><br />
      <a (click)="scroll(usepersonaldata)"><b>HOW WE USE YOUR PERSONAL DATA</b></a><br />
      <a (click)="scroll(dispersonaldata)"><b>DISCLOSURES OF YOUR PERSONAL DATA</b></a><br />
      <a (click)="scroll(creagencies)"><b>CREDIT REFERENCE AGENCIES AND OTHER AGENCIES</b></a><br />
      <a (click)="scroll(openbank)"><b>OPEN BANKING</b></a><br />
      <a (click)="scroll(decmake)"><b>AUTOMATED DECISION-MAKING</b></a><br />
      <a (click)="scroll(datasec)"><b>DATA SECURITY</b></a><br />
      <a (click)="scroll(dataret)"><b>DATA RETENTION</b></a><br />
      <a (click)="scroll(legalright)"><b>YOUR LEGAL RIGHTS</b></a><br />
      <a (click)="scroll(contcomp)"><b>CONTACT DETAILS AND COMPLAINTS</b></a><br />
    </p>
    <h3>IMPORTANT INFORMATION</h3>
    <p>
      It is important that you read this privacy policy together with our
      <a href="/cookie" class="applyNow">Cookie Policy</a> our
      <a href="/terms" class="applyNow">Terms of Use</a> and any other privacy policy or fair processing notice we
      may provide on specific occasions when we are collecting or processing personal data about you so that you are
      fully aware
      of how and why we are using your data. This privacy policy supplements the other notices and is not intended
      to override them.
    </p>
    <p>
      Please note that we may update and amend this privacy policy from time to time and any changes will be posted
      on our website. This version was last updated on 11-APRIL-2022.
    </p>
    <p>
      It is important that the personal data we hold about you is accurate and current. Please keep us informed if
      your personal data changes during your relationship with us.
    </p>
    <p>
      Our website may include links to third-party website, plug-ins and applications. Clicking on those links or
      enabling those connections may allow third parties to collect or share data about you. We do not control these
      third-party websites and are not
      responsible for their privacy statements. When you leave our website, we encourage you to read the privacy
      policy of every website you visit.
    </p>
    <p>
      By accessing and continuing to use our website, you accept the terms of this privacy policy.
    </p>
    <h3 #whoweare>WHO WE ARE</h3>
    <p>
      The Asset Exchange Ltd (trading as ["The Car Loan Centre"] is a company incorporated in England and Wales with
      company number 08679485. Our registered office is 77 Wollaston Way, Burnt Mills Industrial Estate, Basildon,
      Essex, SS13 1DJ and is referred
      to as "
      <b>we</b>", "<b>us</b>" or "<b>our</b>" in this privacy policy.
    </p>
    <p>
      We source and make available used vehicles and provide finance to our customers. We lend money to customers
      who may have been declined elsewhere, or would usually be declined due to issues such as poor credit
      histories. We sell vehicles on hire purchase
      finance, as well as lending money secured on vehicles ('Logbook Loans') ("<b>Finance Package(s)</b>"). In
      deciding whether to offer Finance Packages to our customers,
      we consider each applicant individually according to their current
      circumstances and ability to repay rather than just automated credit
      scores. We are a controller in respect of the personal information we
      process about you.
    </p>
    <p>
      We also receive introductions to customers from other lenders and
      traders ("<b>Introducers</b>") via our Cash4Declines business model where the customer has been unable to
      secure finance from more mainstream lenders. Please note that our Introducers will be initially responsible
      for your personal data in connection
      with your original application/purchase as well as the decision to submit your details to us. The Introducer's
      privacy policy will apply to all such processing up to and including the introduction to us. This privacy
      policy will apply
      to any application for our Finance Packages and any vehicle purchase following such introduction.
    </p>
    <p>
      We are bound by applicable data protection laws in respect of the handling and collection of your personal
      data and are registered as a data controller in England and Wales, with the Information Commissioner's Office
      (ICO) under the ICO number ZA020916.
    </p>
    <p>
      If you have any questions about this privacy policy, including any requests to exercise your legal rights as
      set out in the '<b>YOUR LEGAL RIGHTS</b><b>'</b> section, please contact us using the contact details in the
      <b>'</b><b>CONTACT DETAILS AND COMPLAINTS</b><b>' </b>section.
    </p>
  </div>
  <div>
    <h3 #datawecollect>THE DATA WE COLLECT ABOUT YOU</h3>
    <p>
      Personal data, or personal information, means any information about an individual from which that person can
      be identified. It does not include data where the identity has been removed (anonymous data) or data relating
      to a corporate entity.
    </p>
    <p>
      If you visit our website, apply for an Agreement in Principle, complete our 'Request a Callback' form, or
      otherwise contact us to enquire about our vehicles and/or Finance Packages, of if you apply for or receive our
      Finance Packages, we may collect,
      use, store and transfer different kinds of personal data about you. Those calls are recorded for training and monitoring purposes. We have grouped together the types of
      personal data we may process as follows:
    </p>
    <ul>
      <li>
        <b>Identity Data</b> includes first name, last name, title, company position, date of birth, gender,
        nationality, marital status, occupation, social media profile information and voice recordings.
      </li>
      <li>
        <b>Contact Data</b> includes business or home address, billing and delivery address(es), email address(es),
        telephone number(s) and fax number(s).
      </li>
      <li>
        <b>Financial Data</b> includes card holder details (CHD), bank account details (account name, number and
        sort code).
      </li>
      <li>
        <b>Identification Data</b> includes a copy of your driving license and/or passport.
      </li>
      <li>
        <b>Vehicle/Driving Data</b> includes your vehicle registration and mileage, logbook loans, driving license
        information including any penalty points, details of accidents and claims (including history and those
        occurring whilst
        you are using our products/services) and, where vehicle tracking is used, your driving speed, distance
        travelled and current and past location tracking.
      </li>
      <li>
        <b>Credit Check Data</b> includes information necessary for us to undertake credit and other financial
        checks on you and assess what Finance Packages and terms would be suitable for you based upon what you can
        reasonably afford
        to pay. This may include the time you have spent at your address, occupational details, details from your
        income and expenditure from the bank statements you provide or that we obtain direct from your bank via Open
        Banking, number
        of dependents and adults you live with and details of your credit file received from Credit Reference
        Agencies such as your credit history. If you are a spouse or financial associate of someone applying for one
        of our Finance Packages,
        this may include your Credit Check Data.
      </li>
      <li>
        <b>Technical Data</b> includes internet protocol (IP) address, your login data, browser type and version,
        time zone setting and location, browser plug-in types and versions, operating system and platform, use of
        cookies or other
        devices to store and sometimes track information about you (more details on our use of cookies is set out in
        our
        <a href="/cookie" class="applyNow">Cookie Policy</a> and other technology on the devices you use to access
        our website.
      </li>
      <li>
        <b>Profile Data</b> includes your username and password, purchases and orders made by you and feedback and
        survey responses.
      </li>
      <li>
        <b>Usage Data</b> includes information about how you use our website.
      </li>
      <li>
        <b>Marketing and Communications Data</b> includes your preferences in receiving marketing from us, or from
        our Introducers or other third parties and your communication preferences.
      </li>
    </ul>
    <p>
      <b>Aggregated Data</b>. We also collect, use and share aggregated data such as statistical or demographic data
      for any purpose. Aggregated data may be derived from your personal data but is not considered personal data in
      law as this
      data does not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to
      calculate the percentage of users accessing a specific website feature. However, if we combine or connect
      Aggregated Data with
      your personal data so that it can directly or indirectly identify you, we treat the combined data as personal
      data which will be used in accordance with this privacy policy.
    </p>
    <p>
      <b>Special Categories of Personal Data</b>. This includes details about your racial or ethnic origin,
      political opinions, religious or philosophical beliefs, trade union membership, genetic or biometric data,
      health, sex life or sexual
      orientation.
    </p>
    <p>We may process certain special categories of personal data when:</p>
    <ul>
      <li>
        you include such information in your CV which you send to us when you make contact with us about employment
        opportunities with us;
      </li>
      <li>
        we request medical information if you are unable to meet your repayments and we are considering forbearance
        (an agreement to provide you with temporary repayment relief instead of taking possession of the financed
        car); or
      </li>
      <li>
        via the operation of CCTV at our sites - for example if you have an accident in or near one of our sites which is captured by our CCTV and/or then logged in our accident book or if you are involved in criminal activity which is captured on one of our CCTV systems.
      </li>
    </ul>
    <p>
      The legal basis on which we process such data will usually be your
      <u>explicit</u> consent, save for in respect of CCTV where our legal basis is legal claims and/or employment, social security and social protection law and/or vital interests.
    </p>
    <p>
      <b>Children</b>. Our website is not intended for children and in this respect we do not knowingly collect data
      relating to children.
    </p>
    <p>
      We do not actively collect any information about criminal convictions and offences.
    </p>
    <h3 #failpersonaldata>IF YOU FAIL TO PROVIDE PERSONAL DATA</h3>
    <p>
      Where we need to collect personal data by law, or under the terms of a contract we have with you (for example
      in connection with a vehicle purchase or a Finance Package) and you fail to provide that data when requested,
      we may not be able to perform the
      contract we have or are trying to enter into with you. In this case, we may have to suspend or cancel the
      purchase and/or Finance Package but we will notify you if this is the case at the time.
    </p>
    <h3 #collectpersonaldata>HOW WE COLLECT YOUR PERSONAL DATA</h3>
    <p>
      We use different methods to collect data from and about you including through:
    </p>
    <ul>
      <li>
        <b>Direct interactions.</b> You may give us your Identity, Contact, Financial, Identification,
        Vehicle/Driving, Credit Check, Profile, Marketing and Communications Data by filling in forms or by
        corresponding with us by post, phone,
        email, social media or otherwise. This includes personal data you provide when you:
      </li>
      <ul>
        <li>
          complete the form on our website when applying for an 'Agreement in Principle' for a Finance Package;
        </li>
        <li>fill in our 'Request A Callback' form on our website and subsequently speak to us on the telephone;</li>
        <li>
          provide us with information when contacting us on our automated chat function (through our website);
        </li>
        <li>
          provide us with contact details for the purpose of finding out information on our vehicles and/or Finance
          Packages or request to be contacted by a member of our team;
        </li>
        <li>
          get in touch to request information on our vehicles and/or Finance Packages or otherwise contact us;
        </li>
        <li>
          apply to purchase a vehicle from us and/or take out a Finance Package;
        </li>
        <li>
          set up a client account with us (as a sole trader or on behalf of a corporate entity as an Introducer);
        </li>
        <li>make contact with our support team;</li>
        <li>contact us about your interest in a job with us; or</li>
        <li>give us some feedback.</li>
      </ul>
      <li>
        <b>Automated technologies or interactions</b>. As you interact with our website we may automatically collect
        Technical or Usage Data about your equipment, browsing actions and patterns. We collect this personal data
        by using cookies
        and other similar technologies. Please see our <a href="/cookie" class="applyNow">Cookie Policy</a>. If you
        agree to use a tracking device in your vehicle, we may collect Profile Data associated with that device and
        your vehicle
        use.
      </li>
      <li>
        <b>CCTV</b>. We operate CCTV in and around our sites which capture images of you. We may be able to identify you from the CCTV data. We will only use this data to prevent, detect, deter and report on crime, to protect buildings and assets from crime, to protect the health and safety of customers, prospective customers, staff and other visitors and/or to assist with criminal investigations and support law enforcement bodies. <br>
        We use CCTV systems in this way for our legitimate interests (to prevent and detect crime), to comply with legal or regulatory obligations (to law enforcement bodies) and/or to protect the vital interests of you or another individual.
      </li>
      <li>
        <b>Third parties or publicly available sources</b>. When you apply for a Finance Package, we receive
        information about you from third parties and public sources as set out below:
      </li>
      <ul>
        <li>
          Identity and Financial/Credit Check Data from credit reference agencies and other credit information
          services to check that, where you have applied to obtain a vehicle or Finance Package from us, all the
          details submitted by you on your application are
          true and that the application has been genuinely made by you (please see the sections on '<b>CREDIT
            REFERENCE AGENCIES AND OTHER AGENCIES</b>' and '<b>OPEN BANKING'</b> for more information on this);
        </li>
        <li>
          Identity, Identification Data, Profile Data and Vehicle/Driving Data from the DVLA to check that all
          details submitted by you in your application are true, in particular in relation to your driving history;
        </li>
        <li>
          Identity and Vehicle/Driving Data from insurance companies, including details of accidents or claims,
          where we need this to deal with and manage any accidents or claims occurring whilst you are using our
          vehicles/Finance Packages and to receive insurance
          pay-outs where we are within our right to do so;
        </li>
        <li>
          Identity and Contact Data from Introducers and other vehicle dealers and introducers that provide us with
          your details in order for us to make contact to introduce you to our vehicles and/or Finance Packages
          (where you have provided them with consent
          to do so);
        </li>
        <li>
          Identity and Contact Data from individuals recommending, in a business-to-business context, that we make
          contact with you to introduce our Finance Packages to you;
        </li>
        <li>
          Identity, Contact (and possibly Special Categories of Personal) Data from recruitment professionals who
          send us your CV;
        </li>
        <li>
          Identity, Contact, Financial, Identification, Credit Check, Technical, Profile, Usage and Marketing &amp;
          Communication Data from our Introducers based on the information they obtain from you when you access
          finance or our vehicles through them; and
        </li>
        <li>
          Technical and Usage Data from analytics providers, such as Google, Zuko and Hotjar, based in and outside
          the UK.
        </li>
      </ul>
    </ul>
    <h3 #usepersonaldata>HOW WE USE YOUR PERSONAL DATA</h3>
    <p>
      Please note this section only summarises the purposes for which we use personal data where <u>we</u> are
      acting as a controller of personal data. Our Introducers and other introducers, credit reference agencies and
      your bank are responsible
      for determining the purposes for which they collect your personal data and will be responsible for providing
      you information in that respect.
    </p>
    <p>
      We will only use your personal data when the law allows us to. Most commonly, we will use your personal data
      in the following circumstances:
    </p>
    <ul>
      <li>
        <p>
          <b>Performance of Contract</b> means processing your personal data where it is necessary for the
          performance of a contract to which you are a party or to take steps at your request before entering into
          such a contract.
        </p>
      </li>
      <li>
        <p>
          <b>Legitimate Interest</b> means the interest of our business in conducting and managing our business , including training and monitoring of our customer services experience, to
          enable us to offer you the best product and/or service and the best and most secure experience we can. We
          make sure we
          consider and balance any potential impact on you (both positive and negative) and your rights before we
          process your personal data for our legitimate interests. We do not use your personal data for activities
          where our interests
          are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to
          by law). You can contact us for further information about how we assess our legitimate interests against
          any potential
          impact on you in respect of specific activities (please see the '<b>CONTACT DETAILS AND COMPLAINTS'</b>
          section).
        </p>
      </li>
      <li>
        <p>
          <b>Consent</b> means where we have obtained your agreement to process your personal data. Consent must be
          freely given, specific, informed and be an unambiguous indication of your wishes for us to process your
          personal data (this may be
          collected by you ticking a box). Generally we do not rely on consent as a legal basis for processing your
          personal data other than in relation to sending our newsletters and/or marketing material to you via
          email, post, phone,
          text message and social media. You have the right to withdraw consent to newsletters and/or marketing at
          any time by contacting us (please see the
          <b>'Opting Out / Unsubscribing</b>', the
          <b>'Withdraw consent at any time'</b> under '<b>YOUR LEGAL RIGHTS</b>' and the <b>'CONTACT DETAILS AND
            COMPLAINTS'</b> sections).
        </p>
      </li>
      <li>
        <p>
          <b>Comply with a legal or regulatory obligation</b> means processing your personal data where it is
          necessary for compliance with a legal or regulatory obligation that we are subject to.
        </p>
      </li>
    </ul>
    <p>
      We have set out below, in a table format, a description of all the ways we plan to use your personal data, and
      which of the legal bases we rely on to do so. We have also identified what our legitimate interests are where
      appropriate.
    </p>
    <p>
      Note that we may process your personal data for more than one lawful ground depending on the specific purpose
      for which we are using your data. Please contact us if you need details about the specific legal ground we are
      relying on to process your personal
      data where more than one ground has been set out in the table below.
    </p>
  </div>
  <div class="scrollTable">
    <table class="policy-data-table" border="1">
      <tbody>
        <tr>
          <td><b>Purpose / Activity</b></td>
          <td><b>Type of Data</b></td>
          <td>
            <b>Lawful basis for processing including basis of legitimate
              interest</b>
          </td>
        </tr>
        <tr>
          <td>
            To assess your application for a Finance Package, make
            decisions on the most appropriate lending decisions, and
            undertake financial and credit checks (including credit
            reference checks via external credit reference agencies)
          </td>
          <td>
            Identity Data<br />Contact Data<br />Financial Data
            <br />Identification<br />
            Data<br />Vehicle/Driving Data <br />Credit Check Data
          </td>
          <td>
            Performance of a contract (to take steps at your request
            before entering into a contract) Necessary for our legitimate
            interests (to ensure our customers are likely to be able to
            afford repayments) Necessary to comply with a legal obligation
            Consent (in respect of credit reference checks and data
            obtained via Open Banking)
          </td>
        </tr>
        <tr>
          <td>
            To provide our products and/or services to you (including
            carrying out our obligations arising from any contracts
            entered into between you and us)
          </td>
          <td>
            Identity Data<br />
            Contact Data<br />Financial Data <br />Identification Data<br />Vehicle/Driving
            Data
          </td>
          <td>Performance of a contract</td>
        </tr>
        <tr>
          <td>
            To manage our Finance Package agreements with you, which may
            include exercising our rights such as tracing/recovering debt
            and asset recovery
          </td>
          <td>
            Identity Data<br />Contact Data<br />Special Categories of
            Data<br />Vehicle/Driving Data
          </td>
          <td>
            Performance of a contract Necessary for our legitimate
            interests (in retrieving our assets where customers are unable
            to meet repayments) Explicit consent (in respect of special
            categories of data related to reasons for not making
            repayments)
          </td>
        </tr>
        <tr>
          <td>For direct marketing our vehicles and Finance Packages</td>
          <td>
            Identity Data<br />Contact Data<br />Marketing and
            Communications Data
          </td>
          <td>
            Consent (where you have agreed to receive marketing
            communications from us) Necessary for our legitimate interests
            (to provide our products and services to you)
          </td>
        </tr>
        <tr>
          <td>
            To provide, monitor, develop and improve our products/services
            and the experience we are able to offer our customers and
            Introducers
          </td>
          <td>
            Identity Data<br />
            Contact Data<br />
            Financial Data <br />
            Technical Data<br />
            Profile Data<br />
            Usage Data <br />
            Marketing and Communications<br />
            Special Categories of Personal Data<br />
          </td>
          <td>
            Necessary for our legitimate interests (to continually improve
            our products and services)
          </td>
        </tr>
        <tr>
          <td>
            To manage our relationship with you which will include:(a)
            Notifying you about changes to our terms of use, privacy
            policy or cookie policy(b) Asking you to provide a review or
            take a survey

          </td>
          <td>
            Identity Data<br />
            Contact Data<br />
            Profile Data <br />
            Marketing and Communications Data<br />
          </td>
          <td>
            Performance of a contract Necessary for our legitimate
            interests (to keep our records updated and to study how
            customers, Introducers use our Finance Packages) Necessary to
            comply with a legal obligation
          </td>
        </tr>
        <tr>
          <td>
            To receive and respond to enquiries from you about our
            vehicles and/or Finance Packages
          </td>
          <td>
            Identity Data<br />
            Contact Data<br />
            Marketing and Communications Data<br />
          </td>
          <td>
            Necessary for our legitimate interests (to engage with
            stakeholders and to facilitate the growth of our business)
          </td>
        </tr>
        <tr>
          <td>
            To receive and respond to customer support related questions
            raised by you
          </td>
          <td>
            Identity Data<br />
            Contact Data<br />
            Credit Check Data<br />
            Technical Data<br />
            Profile Data<br />
            Usage Data <br />
          </td>
          <td>
            Necessary for our legitimate interests (to be able to provide
            our customers and Introducers with excellent customer service
            and support in relation to the vehicles and/or Finance
            Packages we provide you)
          </td>
        </tr>
        <tr>
          <td>To enable you to complete a survey</td>
          <td>
            Identity Data<br />
            Contact Data<br />
            Profile Data <br />
            Usage Data<br />
            Marketing and Communications Data <br />
          </td>
          <td>
            Necessary for our legitimate interests (to study how our
            customers and Introducers use our products/services, to
            develop it and improve our business)
          </td>
        </tr>
        <tr>
          <td>
            To administer and protect our business and assets, this website (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data) and to keep both staff, customers and members of the public safe in and around our sites
          </td>
          <td>
            Identity Data<br />
            Contact Data<br />
            Technical Data <br />
            Usage Data <br/>
            CCTV Data
          </td>
          <td>
            Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security, to prevent fraud, prevent, detect, deter and report on crime, to protect buildings and assets from crime, to protect the health and safety of customers, prospective customers, staff and other visitors and/or to assist with criminal investigations and support law enforcement bodies. and criminal activity and in the context of a business reorganisation or group restructuring exercise)<br/>
Necessary to comply with a legal obligation<br/>
Necessary to protect the vital interests of you or another individual.

          </td>
        </tr>
        <tr>
          <td>
            To use data analytics to improve our website, marketing,
            customer relationships and experiences
          </td>
          <td>
            Technical Data<br />
            Usage Data<br />
            Profile Data
          </td>
          <td>
            Necessary for our legitimate interests (to define types of
            customers our services, to keep our website updated and
            relevant, to develop our business and to inform our marketing
            strategy
          </td>
        </tr>
        <tr>
          <td>
            To make suggestions and recommendations to you about our
            vehicles and/or Finance Packages that may be of interest to
            you
          </td>
          <td>
            Identity Data<br />
            Contact Data<br />
            Technical Data <br />
            Usage Data<br />
            Profile Data<br />
            Marketing and Communications
          </td>
          <td>
            Necessary for our legitimate interests (to develop our
            products and services and grow our business) <br />Consent
          </td>
        </tr>
        <tr>
          <td>
            To receive information about you from you or other third
            parties for the purposes of recruitment
          </td>
          <td>
            Identity Data<br />
            Contact Data<br />
            Special Categories of Personal Data
          </td>
          <td>
            Performance of a contract Necessary for our legitimate
            interests (in finding and hiring the right people to help grow
            our business) Explicit consent (in respect of special
            categories of data)
          </td>
        </tr>

        <tr>
          <td>
            Referrals to third party advisors including for example insurance brokers
          </td>
          <td>
            Contact Data
          </td>
          <td>
            Consent
          </td>
        </tr>


      </tbody>
    </table>
  </div>
  <div> <br />
    <ul>
      <li>
        <p>
          <b>Prospecting</b>. In a business-to-business context we may make contact with individuals at other
          businesses (including Introducers or potential Introducers) to provide or seek information in connection
          with our vehicles and/or Finance Packages. The legal basis we rely on for using personal data to make such
          contact is our shared legitimate interests in doing business together. When we make contact with an
          individual,
          they can exercise your right to object to such contact from us (please see the <b>'Object to
            processing'</b> section under '<b>YOUR LEGAL RIGHTS</b>')
        </p>
      </li>
      <li>
        <p>
          <b>Marketing</b>. We strive to provide you with choices regarding certain personal data uses, particularly
          around marketing and advertising. We have established personal data control mechanisms for our business
          including an unsubscribe link
          displayed on every email newsletter and marketing communication.
        </p>
        <p><u>Marketing from Us</u></p>
        <p>
          If you provide your express, opt-in consent, we may use your Identity, Contact, Technical, Usage and/or
          Profile Data to form a view on what we think you may want or need, or what may be of interest to you. This
          is how we decide which vehicles, Finance
          Packages and offers may be relevant for you (we call this marketing).
        </p>
        <p>
          You may receive marketing communications from us after you have requested information from us, purchased a
          vehicle, applied for or obtained a Finance Package from us, or opened a client account and, in each case,
          you have opted-in to receiving that marketing.
        </p>
        <p>You can withdraw your consent at any time.</p>
        <p>
          You can ask us to stop sending you marketing communications at any time (please see the '<b>Opting Out /
            Unsubscribing</b>' section).
        </p>
        <p><u>Third-Party Marketing</u></p>
        <p>
          We will get your express opt-in consent before we share your personal data with any third party company
          for marketing purposes
        </p>
        <p><u>Opting Out / Unsubscribing</u></p>
        <p>
          You can ask us to stop sending you marketing messages at any time by following the unsubscribe links on
          any marketing message sent to you or by contacting us at any time.
        </p>
        <p>
          Where you opt out of receiving these marketing messages, you may still receive messages for other purposes
          (such as providing the services to you).
        </p>
      </li>
      <li>
        <p>
          <b>Cookies</b>. Our website uses cookies to distinguish you from other users of our website. This helps us
          to provide you with a good experience when you browse our website and also allows us to improve our
          website. By continuing to browse the website, users are agreeing to our use of cookies.
        </p>
        <p>
          A cookie is a small file of letters and numbers that we store on your browser or the hard drive of their
          computer. We only use (and store) non-essential cookies on your computer's browser or hard drive if you
          provide your consent. For more information
          about the cookies we use, please see our
          <a href="/cookie" class="applyNow">Cookie Policy</a>.
        </p>
      </li>
      <li>
        <p>
          <b>Change of Purpose</b>. We will only use your personal data for the purposes for which we collected it,
          unless we reasonably consider that we need to use it for another reason and that reason is compatible
          with the original purpose. If you wish to get an explanation as to how the processing for the new purpose
          is compatible with the original purpose, you can contact us (please the '<b>CONTACT DETAILS AND
            COMPLAINTS'</b> section).
        </p>
        <p>
          If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the
          legal basis which allows us to do so.
        </p>
        <p>
          Please note that we may process your personal data without your knowledge or consent, in compliance with
          the above rules, where this is required or permitted by law.
        </p>
      </li>
    </ul>
    <h3 #dispersonaldata>DISCLOSURES OF YOUR PERSONAL DATA</h3>
    <p>
      For the purposes set out in the '<b>HOW WE USE YOUR PERSONAL DATA</b>' section we may have to share your
      personal data with the following third parties:
    </p>
    <ul>
      <li>
        Third party service providers such as Perfect Data Solutions Ltd, trading as Lending Metrics (<b>Lending
          Metrics</b>) who collect and process your information in connection with such credit checks via credit
        reference agencies such as Equifax (see the sections on
        <b>CREDIT REFERENCE AGENCIES AND OTHER AGENCIES</b> and
        <b>OPEN BANKING</b> for more information).
      </li>
      <li>
        Service providers and suppliers based in (and outside) the UK who provide IT and system administration
        services including for example FLG 360, Bronto (Oracle Net suite) and Constant Contact, who provide Customer
        Relationship Management (CRM) software.
      </li>
      <li>
        Analytics service providers such as Google, Zuko, Hotjar and Qlik Sense, which use anonymised data from our
        website for analytical user behaviour purposes.
      </li>
      <li>
        Reputable companies such as Feefo and Trustpilot that provide consumer survey and review services to us and
        our customers.
      </li>
      <li>
        Insurance companies where we are obliged to provide Vehicle/Driving Data to them, for example if you have an
        accident in one of our vehicles/whilst using our Finance Packages;
      </li>
      <li>
        Third parties we use to help deliver our vehicles to you, such as payment service providers and delivery companies.
      </li>
      <li>
        Professional advisers including lawyers, bankers, auditors and insurers based in the UK who provide
        consultancy, legal, banking, accounting insurance and services.
      </li>
      <li>
        HM Revenue &amp; Customs, regulators, law enforcement bodies, police and other authorities based in the UK
        who require reporting of processing activities in certain circumstances (such as criminal activity,
        suspected or actual fraud or tax evasion).
      </li>
      <li>
        Marketing and PR providers where you have agreed to a publication or article with us.
      </li>
      <li>
        Third party advisors for example insurance brokers where you have agreed for us to pass on your details.
      </li>
      <li>
        Approved and vetted asset recovery or debt collection companies, if necessary to exercise our rights.
      </li>
      <li>
        Third parties to whom we may choose to sell, transfer, or merge parts of our business or our assets.
        Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our
        business, then the new owners may use your personal
        data in the same way as set out in this privacy policy.
      </li>
    </ul>
    <p>
      If you require further information on specific third party integrations in relation to your personal data,
      please get in contact (please see the '<b>CONTACT DETAILS AND COMPLAINTS</b>' section).
    </p>
    <p>
      We require all third parties to respect the security of your personal data and to treat it in accordance with
      the law. We do not allow our third-party service providers to use your personal data for their own purposes
      and only permit them to process your
      personal data for specified purposes and in accordance with our instructions.
    </p>
    <p><b>INTERNATIONAL TRANSFERS</b></p>
    <p>
      Where we transfer personal data out of the UK, we ensure a similar degree of protection is afforded to it by
      ensuring at least one of the following safeguards is implemented:
    </p>
    <ul>
      <li>
        We will only transfer personal data to countries that have been deemed to provide an adequate level of
        protection for personal data.
      </li>
      <li>
        Where we use certain service providers, we may use specific contracts approved for use in the UK which give personal data the same protection it has in the UK.
      </li>
    </ul>
    <p>
      If further information on the specific mechanism used by us when transferring your personal data out of the
      UK is required, please contact us directly (please see the
      <b>CONTACT DETAILS AND COMPLAINTS</b><b> </b> section).
    </p>
    <h3 #creagencies>CREDIT REFERENCE AGENCIES AND OTHER AGENCIES</h3>
    <p>
      As a responsible lender, we have a legal obligation to take steps to ensure that our customers are
      creditworthy. We must ensure that our customers are able to afford the Finance Packages we offer them.
    </p>
    <p>
      We use a variety of methods to try to make sure that we do not lend money to people who are in financial
      difficulty. One of these is to perform credit reference and identity checks on you with a credit reference
      agency (<b>CRA</b>).
      In order to do this, we will pass the personal data that you provide to us in your Finance Package application
      to a CRA called Perfect Data Solutions Limited which trades as "Lending Metrics" (<b>Lending Metrics</b>).
      Lending Metrics provide us with data about a customer's credit history and borrowing habits.
    </p>
    <p>
      This data will include information from your credit application and about your financial situation and
      financial history. Lending Metrics will supply to us both public (including the electoral register) and shared
      credit, financial situation and financial
      history information and fraud prevention information.
    </p>
    <p>
      You can contact Lending Metrics at Lancaster Court, 8 Barnes Wallis Road, Fareham, Hampshire, PO15 5TU in
      writing to request the information that they hold about you (please note, a small statutory fee may be
      payable).
    </p>
  </div>
  <div class="scrollTable">
    <table class="policy-imp-table" border="1">
      <tbody>
        <tr>
          <td width="616" valign="top">
            <p style="font-weight: bold;">Important – Your Personal Information</p>
          </td>
        </tr>
        <tr>
          <td width="616" valign="top">
            <p>
              Credit decisions and the prevention of fraud and money laundering:
            </p>
            <p>
              We may use credit reference and fraud prevention agencies to help us make decisions. What we do and
              how both we and credit reference and fraud prevention agencies will use your information is detailed
              in the section below called:
            </p>
            <p>
              'A condensed guide to the use of your personal information by ourselves and at Credit Reference and
              Fraud Prevention Agencies.'
            </p>
            <p>
              By confirming your agreement to proceed you are accepting that we may each use your information in
              this way.
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div><br />
    <p>
      <u>
        A condensed guide to the use of your personal information by
        ourselves and at Credit Reference and Fraud Prevention Agencies
      </u>
    </p>
    <p>
      1. When you apply to us take out a Finance Package, we will check the following records about you and others
      (see 2 below):
    </p>
    <p>1.1 Our own database;</p>
    <p>
      1.2 Those at CRAs. When CRAs receive a search from us they will place a search footprint on your credit file
      that may be seen by other lenders. They supply to us both public (including the electoral register) and shared
      credit and fraud prevention information.
    </p>
    <p>1.3 Those at fraud prevention agencies (<b>FPA</b>s).</p>
    <p>We will use this information to:</p>
    <ul>
      <li>
        Assess your creditworthiness and whether you can afford to take out the Finance Package;
      </li>
      <li>Verify the accuracy of the data you have provided to us;</li>
      <li>Prevent criminal activity, fraud and money laundering;</li>
      <li>Manage your account(s);</li>
      <li>Trace and recover debts; and</li>
      <li>
        Ensure any offers provided to you are appropriate to your circumstances.
      </li>
    </ul>
    <h4><b>CRAs:</b></h4>
    <div class="scrollTable">
      <table class="policy-cra-table" cellspacing="0" cellpadding="0">
        <tbody>
          <tr>
            <td width="308" valign="top">
              Equifax Ltd<br /> Customer Service Centre<br /> PO Box 10036<br /> Leicester
              <br /> LE3 4FS<br />
              <a class="applyNow" href="https://www.equifax.co.uk" target="_blank" rel="noopener">www.equifax.co.uk</a><br>
              <a class="applyNow" href="https://www.equifax.co.uk/crain" target="_blank" rel="noopener">www.equifax.co.uk/crain</a>
            </td>
            <td width="308" valign="top">
              Perfect Data Solutions Limited <br />
              (trading as LendingMetrics)<br />
              Lancaster Court<br />
              8 Barnes Wallis Road<br />
              Fareham, PO15 5TU<br />
              www.lendingmetrics.com
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p>
      2. If you are making a joint application or tell us that you have a
      spouse or financial associate, we will link your records together so
      you must be sure that you have their agreement to disclose information
      about them. CRAs also link your records together and these links will
      remain on your and their files until such time as you or your partner
      successfully files for a disassociation with the CRAs to break that
      link.
    </p>
    <p>
      3. Information on applications will be sent to CRAs and will be
      recorded by them. Where you borrow from us (such as where you take out
      a Finance Package with us), we will give details of your accounts and
      how you manage it/them to CRAs. If you borrow and do not repay in full
      and on time, CRAs will record the outstanding debt. This information
      may be supplied to other organisations by CRAs and FPAs to perform
      similar checks and to trace your whereabouts and recover debts that
      you owe. Records remain on file for six (6) years after they are
      closed, whether settled by you or defaulted.
    </p>
    <p>
      4. Please ensure the information you provide about yourself is true as
      it will be checked. If you give us false or inaccurate information and
      we suspect or identify fraud we will record this and may also pass
      this information to FPAs and other organisations involved in crime and
      fraud prevention.
    </p>
    <p>
      5. If you have borrowed from us and do not make payments that you owe
      us, we will trace your whereabouts and recover debts or the asset.
    </p>
    <p>
      6. Your data may also be used for other purposes for which you give
      your specific permission or, in very limited circumstances, when
      required by law or where permitted under the terms of Data Protection
      Laws.
    </p>
    <p><b>How to find out more</b></p>
    <p>
      This is a condensed version and if you would like to read the full
      details of how your data may be used please contact us on the details
      in the <b>'CONTACT DETAILS AND COMPLAINTS'</b> section.
    </p>
    <p>
      You can contact Lending Metrics at the address above in writing to
      request the information that they hold about you (please note, a small
      statutory fee may be payable).
    </p>
    <p>
      The details in this policy relating to the third parties in this
      section are for your information only. Further information about the
      CRAs, their role as fraud prevention agencies, the data they hold, the
      ways in which they use and share personal information, data retention
      periods and your data protection rights with them are explained in
      more detail within the Credit Reference Agency Information Notice
      (<b>CRAIN</b>) accessible from the credit reference agency's websites
      (see above for link).
    </p>
    <p>
      CRAs have a legal duty to explain the purposes and legal basis for
      processing your personal data. Please always refer to the CRAs'
      privacy policies on their websites (see above) for such information.
      We are not responsible for the privacy policies or practices of these
      third party recipients of your personal data.
    </p>
    <h4><b>Other agencies:</b></h4>
    <p>
      We may provide your personal data to other agencies (including FPAs),
      this will be in order to help lenders such as us trace debtors,
      recover debt, prevent fraud and to check your identity to prevent
      money laundering. If you have taken out a Finance Package from us and
      failed to make repayments, we may trace your whereabouts and recover
      debts. Agencies are not permitted to keep "blacklists" nor should they
      give any opinion about whether or not credit finance should be granted
      to you - this for us to decide.
    </p>
    <p>
      Each agency has a legal duty to explain the purposes and legal basis
      for processing your personal data. Please always refer to the agency's
      privacy policy on their website for such information. We are not
      responsible for the privacy policies or practices of these third party
      recipients of your personal data.
    </p>
    <h4><b>Accessing your file: </b></h4>
    <p>
      If you wish to see the information contained on an agency file you can
      do so by writing to the relevant agency. If your file contains
      information about you which is incorrect (or contains information
      about other people with whom you have no financial connection) you can
      request for the applicable information to be corrected, removed or
      have a note put on the file explaining why you think the information
      is wrong. The agency will not remove correct information.
    </p>
  </div>
  <div>
    <h3 #openbank>OPEN BANKING</h3>
    <p>
      Open Banking is the secure way of providing access to your bank or
      building society account to providers who are registered for this
      purpose.
    </p>
    <p>
      Registered providers and participating banks and building societies
      are listed under the Open Banking Directory.
    </p>
    <p>
      We use Open Banking as it allows us to process loan applications
      efficiently, securely and in our customers' best interests. By
      permitting access to your bank or building society account information
      in this way, we are able to make a better lending decision as we shall
      be able to verify your income, outgoings and other matters in order to
      assess what loan terms would be suitable for you based upon what you
      can reasonably afford to repay.
    </p>
    <p>
      Further information about Open Banking is available from
      <a class="applyNow" href="https://www.openbanking.org.uk/" target="_blank" rel="noopener">www.openbanking.org.uk</a>.
    </p>
    <p>
      <b>
        How your personal data will be processed for the purposes of Open
        Banking:
      </b>
    </p>
    <p>
      By proceeding with your loan application via our website you expressly consent to us sharing the details you
      have provided in your application for a Finance Package, which will include your personal data ("the <b>Shared
        Personal Data</b>"),
      with our registered Open Banking partner, Lending Metrics. Lending Metrics is also a credit reference agency.
      Further information about Lending Metrics including their registered provider and
      regulatory status is available from www.lendingmetrics.com.
    </p>
    <p>
      Whilst completing your Finance Package application, we will ask you to choose whether you would like to
      provide us with copies of your bank statements or allow us to use Open Banking. You are free to choose either
      of these options and we will not use
      Open Banking unless we have obtained your consent.
    </p>
    <p>
      If you consent to Open Banking, during your loan application we will safely and securely direct you to Lending
      Metrics’ secure portal ("the
      <b>Portal</b>") for the purposes of granting Lending Metrics access to your bank or building society account
      information ("<b>Transaction Information</b>").
    </p>
    <p>
      If your bank or building society is registered to provide access under
      the Open Banking Directory, Lending Metrics will obtain your
      Transaction Information and report it back to us in the form of a
      completed search for us to process your loan application ("the
      <b>Permitted Purpose</b>").
    </p>
    <p>
      Where your bank or building society have already permitted access to your Transaction Information you shall
      need to contact them directly in order to withdraw your consent under their particular Open Banking terms and
      conditions.
    </p>
    <p><b>Data security:</b></p>
    <p>
      Lending Metrics are registered under the Open Banking Directory as an account information service provider and
      are also regulated by the Financial Conduct Authority as a payment services firm under number 802599. Any data
      you submit via the Portal will
      be encrypted and its usage tracked as part of set Open Banking data security standards.
    </p>
    <p>
      We are responsible for the secure transmission of any Shared Personal Data to Lending Metrics, for safely
      directing you to the Portal and for the safe receipt and usage of your Transaction Information.
    </p>
    <p>
      You will not be required to share your banking password or log in details with either us or Lending Metrics.
      Once you have given your consent to proceed with Open Banking you will be directed to your own bank or
      building society’s login page where you
      will enter in your own login details directly.
    </p>
    <p>
      Save as set out above or elsewhere in this Privacy Policy, we are not responsible for your direct data
      transmissions with Lending Metrics or with your own bank or building society.
    </p>
    <p>
      <b>
        How your Shared Personal Data and Transaction Information will be
        used
      </b>
    </p>
    <p>
      Lending Metrics shall, subject to their own terms and conditions and privacy policy, and, if your bank or
      building society is registered to provide access under the Open Banking Directory, obtain your Transaction
      Information and submit this back to us
      for the Permitted Purpose. By way of example, the Transaction Information that we shall receive from Lending
      Metrics is likely to include information relating to your income, outgoings and credit
      worthiness.
    </p>
    <p>
      Lending Metrics will be entitled to re-access your Transaction Information for up to 90 days from the date of
      your original search result in order to refresh the search results, obtain a snapshot of your data or gather
      additional data.
    </p>
    <p>
      Lending Metrics will hold the Shared Personal Data and the Transaction Information they receive and retain
      according to their own terms and conditions and privacy policy, available on the Portal and its
      <a href="https://www.lendingmetrics.com/privacy-notice/" class="applyNow" target="_blank" rel="noopener">website</a>, which you will be
      required to read and consent to once directed
      their via our website.
    </p>
    <p>
      As Lending Metrics is also a credit reference agency they may also
      share and keep a record of your Shared Personal Data and Transaction
      Information.
    </p>
    <p>
      <b>Will you use my Transaction Information data for any other purpose?
      </b>
    </p>
    <p>
      The Transaction Information we receive about you will only be used for
      the Permitted Purpose. We do not sell or share Transaction Information
      with any third party.
    </p>
    <p>
      Save as set out above the information contained in the rest of this
      Privacy Policy deals with how we collate, use, transfer, store, delete
      and other terms applicable to your personal data including Shared
      Personal Data and Transaction Information.
    </p>
    <p><b>Do I have to provide you with my consent to proceed?</b></p>
    <p>
      You will be given the choice during your application for a Finance
      Package as to whether you wish to proceed using Open Banking or
      whether you wish to obtain the relevant Transaction Information
      yourself and provide it to us.
    </p>
    <p>
      Where your bank or building society have already permitted access to
      your Transaction Information you shall need to contact them directly
      in order to withdraw your consent under their particular Open Banking
      terms and conditions.
    </p>
  </div>
  <div>
    <p><b>Your legal rights:</b></p>
    <p>
      Your individual data protection and privacy rights set out in the
      section, <b>'YOUR LEGAL RIGHTS'</b>, below continue to apply for the
      personal data we process via Open Banking.
    </p>
    <p>
      Lending Metrics has a legal duty to explain the purposes and legal
      basis for processing your personal data, as does your bank or building
      society. Please refer to your bank and building society and Lending
      Metrics' privacy policy on their website for such information. We are
      not responsible for the privacy policies or practices of these third
      party recipients of your personal data.
    </p>
    <h3 #decmake>AUTOMATED DECISION-MAKING</h3>
    <p>
      When assessing your suitability for a Finance Package, we may use
      automated decision-making facilities. For this purpose, we use a
      software called 'Automated Decision Platform' (<b>ADP</b>) licensed to
      us by Lending Metrics. We may submit your personal data to the ADP and
      Lending Metrics may process it on our behalf in accordance with our
      instructions. Such data may include Identity Data, Contact Data,
      Identification Data, Financial Data, Credit Check Data, Transaction
      Information obtained via Open Banking, and any other information or
      documents you have submitted as part of your Finance Package
      application. We do not make a decision solely by automated means
      without any human involvement in the decision-making process. We
      personally evaluate the decision-making undertaken by the ADP and make
      the final decision as to whether a Finance Package is suitable for
      you.
    </p>
    <h3 #datasec>DATA SECURITY</h3>
    <p>
      We are SOC 2 certified, and we have put in place appropriate security
      measures (including SSL and SMB technology and dual-factor
      authentication) to prevent your personal data from being accidentally
      lost, used or accessed in an unauthorised way, altered or disclosed.
      In addition, we limit access to your personal data to those employees,
      agents, contractors and other third parties who have a business need
      to know. They will only process your personal data on our instructions
      and they are subject to a duty of confidentiality.
    </p>
    <p>
      We have put in place procedures to deal with any suspected personal
      data breach and will notify you and any applicable regulator of a
      breach where we are legally required to do so.
    </p>
    <p>
      Please contact our IT security team using the email
      <span style="font-size: 16px; color: #29abe2"><a class="applyNow" rel="nofollow" hreflang="en-gb"
          href="mailto:securityops@thecarloancentre.co.uk">securityops@thecarloancentre.co.uk</a></span>
      if you require further information on our Security Policy and for the
      specific security measures we have in place.
    </p>
    <h3 #dataret>DATA RETENTION</h3>
    <p><b>How long will you use my personal data for?</b></p>
    <p>
      We will only retain your personal data for as long as necessary to
      fulfil the purposes we collected it for, including for the purposes of
      satisfying any legal, accounting, or reporting requirements.
    </p>
    <p>
      To determine the appropriate retention period for personal data, we
      consider the amount, nature, and sensitivity of the personal data, the
      potential risk of harm from unauthorised use or disclosure of your
      personal data, the purposes for which we process your personal data
      and whether we can achieve those purposes through other means, and the
      applicable legal requirements.
    </p>
    <ul>
      <li>
        Where we have entered into an agreement with you, we will generally
        retain your data for a period of 6 (six) years after a contract has
        ended, to ensure that we are able to assist you should you have any
        questions or feedback in relation to our vehicles and/or Finance
        Packages or to protect, or defend our legal rights, or for tax
        purposes.
      </li>
      <li>
        Where we have processed your personal data to provide you with
        marketing communications with consent, you will be regularly given
        the option to opt out of such marketing. If you tell us that you no
        longer wish to receive such communications, your personal data will
        be removed from our marketing lists (but will be added to a "do not
        contact" list).
      </li>
      <li>
        Where you have applied for a Finance Package with us but your
        application does not proceed, we will hold the data you have
        provided as part of that application for three (3) years from the
        date of the application withdrawal.
      </li>
      <li>
        Where you exercise your right to withdraw from an agreement with us
        within the first 14 (fourteen) days, we will hold your data for
        three (3) years from the date of withdrawal.
      </li>
      <li>
        Where we have processed your data for any other reason (such as
        where you have contacted us with a question in connection with
        services or in relation to recruitment), subject to the remainder of
        this section we will retain your
        data for 12 (twelve) months.
      </li>
    </ul>
    <p> Data from CCTV cameras will not be retained indefinitely but will be permanently deleted once there is no reason to retain the recorded information. Exactly how long images will be retained for will vary according to the purpose for which they are being recorded. For example, where images are being recorded for crime prevention purposes or requested by a law enforcement body, data will be kept by them for as long as they need it. In all other cases, recorded images will be kept for no longer than 30 (thirty) days. We will maintain a comprehensive log of when data is deleted.</p>


    <p>
      In some circumstances you can ask us to delete your data; please see
      section '<b>Request erasure of your personal data </b>' under '
      <b>YOUR LEGAL RIGHTS'</b> for further information.
    </p>
    <p>
      In some circumstances we may anonymise your personal data (so that it
      can no longer be associated with you) for research or statistical
      purposes in which case we may use this information indefinitely
      without further notice to you.
    </p>
    <h3 #legalright>YOUR LEGAL RIGHTS</h3>
    <p>
      Under certain circumstances, you have rights under data protection
      laws in relation to your personal data. These rights are detailed
      below:
    </p>
    <ul>
      <li>
        <b>Request access </b>to your personal data (commonly known as a
        "data subject access request"). This enables you to receive a copy
        of the personal data we hold about you and to check that we are
        lawfully processing it.
      </li>
      <li>
        <b>Request correction </b>of the personal data that we hold about
        you. This enables you to have any incomplete or inaccurate data we
        hold about you corrected, though we may need to verify the accuracy
        of the new data you provide to us.
      </li>
      <li>
        <b>Request erasure </b>of your personal data. This enables you to
        ask us to delete or remove personal data where there is no good
        reason for us continuing to process it. You also have the right to
        ask us to delete or remove your personal data where you have
        successfully exercised your right to object to processing (see
        below), where we may have processed your information unlawfully or
        where we are required to erase your personal data to comply with
        local law. Note, however, that we may not always be able to comply
        with your request of erasure for specific legal reasons which will
        be notified to you, if applicable, at the time of your request.
      </li>
      <li>
        <b>Object to processing </b>of your personal data where we are
        relying on a legitimate interest (or those of a third party) and
        there is something about your particular situation which makes you
        want to object to processing on this ground as you feel it impacts
        on your fundamental rights and freedoms. You also have the right to
        object where we are processing your personal data for direct
        marketing purposes. In some cases, we may demonstrate that we have
        compelling legitimate grounds to process your information which
        override your rights and freedoms.
      </li>
      <li>
        <b>Request restriction of processing </b>of your personal data. This
        enables you to ask us to suspend the processing of your personal
        data in the following scenarios: (a) if you want us to establish the
        data’s accuracy; (b) where our use of the data is unlawful but you
        do not want us to erase it; (c) where you need us to hold the data
        even if we no longer require it as you need it to establish,
        exercise or defend legal claims; or (d) you have objected to our use
        of your data but we need to verify whether we have overriding
        legitimate grounds to use it.
      </li>
      <li>
        <b>Request the transfer </b>of your personal data to you or to a
        third party. We will provide to you, or a third party you have
        chosen, your personal data in a structured, commonly used,
        machine-readable format. Note that this right only applies to
        automated information which you initially provided consent for us to
        use or where we used the information to perform a contract with you.
      </li>
      <li>
        <b>Withdraw consent at any time </b>where we are relying on consent
        to process your personal data. However, this will not affect the
        lawfulness of any processing carried out before you withdraw your
        consent. If you withdraw your consent, we may not be able to provide
        certain products or services to you. We will advise you if this is
        the case at the time you withdraw your consent.
      </li>
    </ul>
    <p>
      If you wish to exercise any of the rights set out above and you are
      the customer of our Introducers you should direct your request to that
      Introducer. If you wish to exercise any of the above rights in respect
      of the personal data we are responsible for, please contact us
      directly (please see the '<b>CONTACT DETAILS AND COMPLAINTS</b><b>'</b> section).
    </p>
    <ul>
      <li>
        <b>No fee usually required</b>. <b> </b> You will not have to pay a
        fee to access your personal data (or to exercise any of the other
        rights). However, we may charge a reasonable fee if your request is
        clearly unfounded, repetitive or excessive. Alternatively, we may
        refuse to comply with your request in these circumstances.
      </li>
      <li>
        <b>What we may need from you</b>. We may need to request specific
        information from you to help us confirm your identity and ensure
        your right to access your personal data (or to exercise any of your
        other rights). This is a security measure to ensure that personal
        data is not disclosed to any person who has no right to receive it.
        We may also contact you to ask you for further information in
        relation to your request to speed up our response.
      </li>
      <li>
        <b>Time limit to respond</b>. We try to respond to all legitimate
        requests within one month. Occasionally it may take us longer than a
        month if your request is particularly complex or you have made a
        number of requests. In this case, we will notify you and keep you
        updated.
      </li>
    </ul>
    <h3 #contcomp>CONTACT DETAILS AND COMPLAINTS</h3>
    <p>Our full details are:</p>
    <div class="scrollTable">
      <table cellspacing="0" cellpadding="0" class="paddingNone">
        <tbody>
          <tr>
            <td width="308" valign="top">Full name of legal entity:</td>
            <td width="308" valign="top">The Asset Exchange Ltd</td>
          </tr>
          <tr>
            <td width="308" valign="top">Contact person:</td>
            <td width="308" valign="top">
              Paul Foster (Compliance Director)
            </td>
          </tr>
          <tr>
            <td width="308" valign="top">Email address:</td>
            <td width="308" valign="top">
              <a class="applyNow" href="mailto:paul.f@thecarloancentre.co.uk">paul.f@thecarloancentre.co.uk</a>
            </td>
          </tr>
          <tr>
            <td width="308" valign="top">Telephone number:</td>
            <td width="308" valign="top">
              <a class="applyNow" href="tel:03303800405">03303 800 405</a>
            </td>
          </tr>
          <tr>
            <td width="308" valign="top">Contact person:</td>
            <td width="308" valign="top">
              Keith Barnes (Customer Services Director)
            </td>
          </tr>
          <tr>
            <td width="308" valign="top">Email address:</td>
            <td width="308" valign="top">
              <a class="applyNow"
              href="mailto:customercare@thecarloancentre.co.uk">customercare@thecarloancentre.co.uk</a>
            </td>
          </tr>
          <tr>
            <td width="308" valign="top">Telephone number:</td>
            <td width="308" valign="top">
              <a class="applyNow" href="tel:07719549027">07719 549027</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <br />
    <p>
      You have the right to make a complaint at any time to the ICO, the UK supervisory authority for data
      protection issues (<a class="applyNow" href="https://ico.org.uk" target="_blank" rel="noopener">ico.org.uk</a>). We would, however,
      appreciate the chance to deal with your
      concerns before you approach the ICO so please contact us in the first
      instance.
    </p>
  </div>
</div>
