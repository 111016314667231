 <div class="content-card pt-0">
<div class="search-container-inner">

  <form [formGroup]="searchforvehicleformgroup" *ngIf="searchforvehicleformgroup">
    <div class="search-container-flex">
      <div class="search-left-wp">
        <h4>Search for a Vehicle...</h4>

        <div class="input-group mt-20">
          <input type="text" class="form-control" placeholder="Type here e.g silver focus" formControlName="general"
          (keydown.enter) = "onSearchForVehicleClick()" (keydown.return) = "onSearchForVehicleClick()"/>
          <div class="input-group-btn">
            <button type="button" class="btn btn-default search-btn-icon" (click)="onSearchForVehicleClick()" >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="search-right-wp">
        <h4>...Or Search by Criteria</h4>

        <div class="form-container-flex">
          <div class="form-group">
            <label>Make</label>
            <select class="form-control" formControlName="make" name="make">
              <option selected value>Any</option>
              <option *ngFor="let item of make">{{item}}</option>
            </select>
          </div>
          <div class="form-group">
            <label>Transmission</label>
            <select class="form-control" formControlName="transmission" name="transmission">
              <option selected value>Any</option>
              <option *ngFor="let item of transmission" value="{{item.value}}">{{item.label}}</option>
            </select>
          </div>
          <div class="form-group">
            <label>Body Type</label>
            <select class="form-control" formControlName="bodytype" name="bodytype">
              <option selected value>Any</option>
              <option *ngFor="let item of bodytype"value="{{item.value}}">{{item.label}}</option>
            </select>
          </div>
          <div class="form-group" *ngIf="showMore">
            <label>Fuel Type</label>
            <select class="form-control" formControlName="fueltype" name="fueltype">
              <option selected value>Any</option>
              <option *ngFor="let item of fueltype" value="{{item.value}}">{{item.label}}</option>
            </select>
          </div>
          <div class="form-group" *ngIf="showMore">
            <label>Reg Year</label>
            <select class="form-control" formControlName="regyear" name="regyear">
              <option selected value>Any</option>
              <option *ngFor="let item of regyear" value="{{item.value}}">{{item.label}}</option>

            </select>
          </div>

          <div class="form-group" *ngIf="showMore">
            <label>Seats</label>
            <select class="form-control" formControlName="seats" name="seats">
              <option selected value>Any</option>
              <option *ngFor="let item of seats">{{item}}</option>
            </select>
          </div>

          <div class="form-group" *ngIf="showMore">
            <label>Engine Size</label>
            <select class="form-control" formControlName="enzinesize" name="enzinesize">
              <option selected value>Any</option>
              <option *ngFor="let item of enzinesize">{{item}}</option>
            </select>
          </div>

          <div class="form-group" *ngIf="showMore">
            <label>Price</label>
            <select class="form-control" formControlName="price" name="price">
              <option selected value>Any</option>
              <option *ngFor="let item of price" value="{{item.value}}">{{item.label}}</option>
            </select>
          </div>
          <div class="search-btn-wp">
            <!-- <a href="{{ generateCustomLink() }}"> -->
            <button type="button" class="btn btn-sarch" (click)="onSearchForVehicleClick()" (keydown.enter) = "onSearchForVehicleClick()" (keydown.return) = "onSearchForVehicleClick()">
              Search
            </button>
            <!-- </a> -->
          </div>
        </div>
        <div class="showMore-flex-wp">
          <button  type="button" class="showMoreBtn arrowDown-icon" id="arrowUpid" value="More search options"
            (click)="showLessBtn()">
            {{ showtext }}
          </button>
        </div>
      </div>
    </div>
  </form>

</div>
</div>
<div class="content-card paddingNone" *ngIf="url">
  <select class="form-control customSelect" name="sorting" id="sorting"
      (change)="onChangeSorting($event.target.value)">
      <option *ngFor="let item of sorting" value="{{item.value}}">{{item.label}}</option>
  </select>
</div>
