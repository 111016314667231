import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CookiesPopupComponent } from '../popup/cookies-popup/cookies-popup.component';
import { EmailVehiclePopupComponent } from '../popup/email-vehicle-popup/email-vehicle-popup.component';
import { SavedCarPopupComponent } from '../popup/saved-car-popup/saved-car-popup.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LeftMenuComponent } from './left-menu/left-menu.component';
import { RequestCallbackLogbookComponent } from './request-callback-logbook/request-callback-logbook.component';
import { RequestCallbackComponent } from './request-callback/request-callback.component';
import { SearchForAVehicleComponent } from './search-for-a-vehicle/search-for-a-vehicle.component';
import { SearchVehicleComponent } from './search-vehicle/search-vehicle.component';
import { PrivacyPolicyContentComponent } from './privacy-policy-content/privacy-policy-content.component';
import { PrivacyPolicyPopupComponent } from '../popup/privacy-policy-popup/privacy-policy-popup.component';
import { TermsConditionsContentComponent } from './terms-conditions-content/terms-conditions-content.component';
import { TermsConditionsPopupComponent } from '../popup/terms-conditions-popup/terms-conditions-popup.component';
import { FootersmallComponent } from './footersmall/footersmall.component';
import { ChatBotLoaderComponent } from './chat-bot-loader/chat-bot-loader.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatDatepickerModule } from '@angular/material/datepicker';



@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDialogModule,
    MatCardModule,
    NgxSpinnerModule,
    MatDatepickerModule,
    NgSelectModule,
  ],

  declarations: [
    FooterComponent,
    FootersmallComponent,
    HeaderComponent,
    RequestCallbackComponent,
    RequestCallbackLogbookComponent,
    LeftMenuComponent,
    SearchForAVehicleComponent,
    SearchVehicleComponent,
    CookiesPopupComponent,
    EmailVehiclePopupComponent,
    SavedCarPopupComponent,
    PrivacyPolicyContentComponent,
    PrivacyPolicyPopupComponent,
    TermsConditionsContentComponent,
    TermsConditionsPopupComponent,
    ChatBotLoaderComponent

  ],

  exports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDialogModule,
    MatCardModule,
    NgxSpinnerModule,
    FooterComponent,
    FootersmallComponent,
    HeaderComponent,
    RequestCallbackComponent,
    RequestCallbackLogbookComponent,
    LeftMenuComponent,
    SearchForAVehicleComponent,
    SearchVehicleComponent,
    CookiesPopupComponent,
    EmailVehiclePopupComponent,
    SavedCarPopupComponent,
    PrivacyPolicyContentComponent,
    PrivacyPolicyPopupComponent,
    TermsConditionsContentComponent,
    TermsConditionsPopupComponent,
    MatDatepickerModule,
    NgSelectModule
  ]
})
export class SharedModule { }
