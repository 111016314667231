import { Injectable, Injector } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { APIURLS } from 'src/api/api-url';
import { ApiResponseCallback } from 'src/app/Interfaces/ApiResponseCallback';
import { RequestACallback } from 'src/app/modals/request-a-callback.model';
import { BaseClassApiService } from '../../../global/base-class-api-service';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RequestCallbackService extends BaseClassApiService {
  postRequestForSubmitCallbakRequest(requestcall: RequestACallback, apiResponseCallBack: ApiResponseCallback) {
    // var header = {
    //   headers: new HttpHeaders()
    //     .set('Authorization',  `Bearer ${token}`)
    // }
    this.apiService.hitPostApi(APIURLS.getAxleBaseImageAndDetailURl(APIURLS.REQUEST_CALL_BACK), createRequestJson(this, requestcall), apiResponseCallBack);
  }

  // postRequestForAPITOken(apiResponseCallBack: ApiResponseCallback) {
  //   this.apiService.hitPostApi("https://preliver2apims.azurewebsites.net/api/Login/token", createRequestJsonForToken(this), apiResponseCallBack, Headers);
  // }

  requestcall: RequestACallback = new RequestACallback();
  constructor(injector: Injector, private router: Router, private activeRoute: ActivatedRoute,
    public _cookieService: CookieService) {
    super(injector);
  }
  public _formGroup: FormGroup;
  public getformGroup(): FormGroup {
    return this._formGroup;
  }
  public setformGroup(value: FormGroup) {
    this._formGroup = value;
  }
}
function createRequestJson(context: RequestCallbackService, requestcall: RequestACallback) {
  console.log(context);
  let requestJson = {};

  requestJson['firstName'] = requestcall.firstname;
  requestJson['surname'] = requestcall.surname;
  requestJson['contactNumber'] = requestcall.contactnumber;
  requestJson['email'] = requestcall.email;

  requestJson['agreeFutureMarketing'] = requestcall.isFuturemarketingclicked;
  if (requestcall.visitorSource != undefined && requestcall.visitorSource != null) {
    requestJson['visitorSource'] = requestcall.visitorSource;
    requestJson['visitorMedium'] = requestcall.visitorMedium;
    requestJson['visitorReference'] = requestcall.visitorReference;
  }
  else {
    requestJson['visitorSource'] = context._cookieService.get('utm_source');
    requestJson['visitorMedium'] = context._cookieService.get('utm_medium');;
    requestJson['visitorReference'] = context._cookieService.get('reference');;
  }
  requestJson['term'] = requestcall.term;
  requestJson['savedVehicles'] = context._cookieService.get('SavedVehicles');
  console.log("Requested json value", requestJson)
  return requestJson;

}


// function createRequestJsonForToken(context: RequestCallbackService, ) {
//   let requestJson = {};
//   requestJson['emailAddress'] = "axle@thecarloancentre.co.uk";
//   requestJson['password'] = "string";
//   requestJson['tenantId'] = "1";
//   return requestJson;
// }











