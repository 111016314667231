import { AfterViewInit, Component, HostListener, Injector, OnInit,Input } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { CommonFunctionsService } from 'src/common/common-functions.service';
import { BaseClass } from 'src/global/base-class';
import {GenericDialogsService} from '../../popup/generic-dialogs.service'
import {DeviceDetectorService} from 'ngx-device-detector'
declare var $: any;

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent extends BaseClass implements OnInit, AfterViewInit {
  @Input() isSearchpanel : boolean= true;
 // @Input() savedCount : number= 0;
  changeUrl: boolean = false;

  public get value(): number {
    return  this._GenericDialogsService.savedCount;
  }


  @HostListener('document:mousedown', ['$event'])
  onTouch(event): void {
    var modalmenu = document.getElementById("navForMobile");
  		if (event.target == modalmenu) {
				modalmenu.style.width = "0";
		}

    if (this._GenericDeviceService.isMobile()){
      //  debugger;
      document.getElementById("navForMobile").style.width = "0";
    }
  }
  constructor(private injector: Injector, public router: Router,
    public commonFunctions: CommonFunctionsService,
    public _cookieService: CookieService,
    public _GenericDialogsService:GenericDialogsService,
    public _GenericDeviceService:DeviceDetectorService
  ) { super(injector) }

  args: any;
  ngAfterViewInit(): void {

    $('.panel-collapse').on('show.bs.collapse', function () {
      $(this).siblings('.panel-heading').addClass('active');

    });
    $('.panel-collapse').on('hide.bs.collapse', function () {
      $(this).siblings('.panel-heading').removeClass('active');
    });
  }
  ongotopage()
  {
    if (this.router.url == '/cars'){
      this._cookieService.set('_getOldPageIndexId', "1", { expires: 1, path: '/' });
      window.open('/cars', "_self");
    }
    

    
  }
  ngOnInit(): void {
    if (this.router.url == '/logbook-loans' || this.router.url == '/logbook-loans/compare')
      this.changeUrl = true;
    else
      this.changeUrl = false;

    let vehicles = this._cookieService.get('SavedVehicles');
    let noOfVehicles = vehicles ? vehicles.split(',').length : 0;
    this._GenericDialogsService.savedCount = noOfVehicles;
    this.args = this._cookieService.get('newlink');
    if (this.args == null || this.args == "NaN") { this.args = "" }
    if (this.router.url == '/cars'){
      this._cookieService.set('_getOldPageIndexId', "1", { expires: 1, path: '/' });
    }
  }
  closeNav() {
    document.getElementById("navForMobile").style.width = "0";
  }

  openSavedCarpopup()
  {
    this._GenericDialogsService.getvehicleDetailsByIDs();
    this._GenericDialogsService.showSavedCarPopup();
    window.scroll(0,0);
  }
}
