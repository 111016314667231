import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent implements OnInit {
  title = 'Cookie Policy | The Car Loan Centre';

  constructor(private titleService: Title,
    private metaTagService: Meta) { }
  isRequestcaliked: boolean = true;

  seoTags()
  {
   this.titleService.setTitle(this.title);
   this.metaTagService.updateTag(
     { name: 'description', content: 'Interested in cookies? Read our cookie policy to find out how and why we use cookies on our website.' }

  );
  this.metaTagService.updateTag(
    { name: 'keywords', content: '' },
   );
  }
  ngOnInit(): void {
    this.seoTags();
  }
  showCallbackFrom() {
    document.getElementById("requestCallbackMainId").style.width = "100%";
    if (this.isRequestcaliked) {
      this.isRequestcaliked = false;
      document.getElementById("requestCallbackMainId").style.width = "100%";
    }
  }
}
