import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-validations-error-popup',
  templateUrl: './validations-error-popup.component.html',
  styleUrls: ['./validations-error-popup.component.css']
})
export class ValidationsErrorPopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ValidationsErrorPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {

  }
  errors: Array<string>;
  ngOnInit() {

    this.errors = this.data;
  }

  closeModal() {
    this.dialogRef.close(false);
  }
}
