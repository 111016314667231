<div class="errors-validation">    
    <h1 mat-dialog-title><i class="fa fa-exclamation-triangle"></i>Errors</h1>
    <mat-dialog-content class="mat-dialog-content">   
         <p *ngFor="let item of errors">* {{item}}</p>
    </mat-dialog-content>
    <hr><button type="button" class="btn btn-errer" mat-dialog-close tabindex="-1" (click)="closeModal()">OK</button>
</div>


<!-- <h1 mat-dialog-title>
   <i class="fa fa-alert"></i> Errors
</h1>
<mat-dialog-content class="mat-dialog-content">
    <div class="row" *ngFor="let item of errors" style="color: red;padding-right: 10px;padding-top: 5px;">
        * {{item}}
    </div>

</mat-dialog-content>
<mat-dialog-actions align="end" style="margin-bottom: 0px!important;">
    <button mat-raised-button color="primary" mat-dialog-close tabindex="-1" (click)="closeModal()">OK</button>
</mat-dialog-actions>    -->