<app-header></app-header>
<!-- <div class="body-bg-wp">
    <div class="bgOpacity" style="background-image: url('https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/pageBg/blurredHomeBackground.jpg')"></div>
</div> -->
<div class="body-bg-wp">

</div>
<!--flex-container content html beginning here-->
<div class="container-flex" id="mtopId">
    <!-- left menu html beginning here -->
    <div class="left-container forMobileBg" id="navForMobile">
        <!--	---fixed wrapper Menu and Search for a Vehicle...html beginning here-- - -->
        <div class="menuFixed-wp">
            <app-left-menu></app-left-menu>
        </div>
    </div>
    <div class="callback-wp-fixed">
        <!-- <a (click)="showCallbackFrom()"><img loading="lazy" src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/request-callback-icon.svg" style="height: 40px; width: 40px;" alt="Request Call Back Icon" /></a> -->
    </div>


    <!-- middle content html beginning here -->
    <div class="middle-container">
        <app-cookies-popup></app-cookies-popup>

       
        <div class="adminBanner">
            <!-- <img src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/administration.webp" alt="Finance driven by you" width="1558" height="536"
                      onerror="this.src='https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/administration.png'">            -->

                      <p>
                        The Asset Exchange Limited trading as The Car Loan Centre – In Administration <br />
                        I advise that The Asset Exchange Limited was placed into administration on 28 February 2024, with Ian Corfield, Geoff Rowley and Glyn Mummery of FRP Advisory Trading Limited appointed as joint administrators. <br />
                   
                        The affairs, business and property of the Company are being managed by the Administrator(s) and who act as agents of the Company without personal liability. <br />
                   
                        A list of our Insolvency Practitioners and their respective licensing bodies is available at <a href="https://www.frpadvisory.com/legal-and-regulatory-notices/#appointmenttakers">https://www.frpadvisory.com/legal-and-regulatory-notices/#appointmenttakers</a>. <br />
                        Insolvency Practitioners are bound by the Insolvency Code of Ethics when carrying out all professional work relating to an insolvency appointment. <br /> <br />
                   
                        At present it is uncertain that goods and services ordered before we entered administration will be supplied.  If you have made a deposit for or paid for goods or services by credit or debit card (including charge and pre-paid cards) and you are advised that goods/order is not going to be fulfilled as expected, you may be able to get your money back by claiming a refund from your card issuer.  There may be time limits that apply. <br />
                        Please contact your card issuer as soon as you can if this may apply to you (there is no need to wait until the due date before contacting your card issuer).  Further information can be found here <a href="https://www.moneysavingexpert.com/reclaim/visa-mastercard-chargeback/#Little-Known%20Card%20Purchase%20Protection%20Which%20Can%20Get%20%C2%A31,000s%20Back">Debit & credit card chargeback: protection on faulty goods - MSE (moneysavingexpert.com)</a>. <br />
                   
                    </p>
        </div>
    
        <!-- <div class="homePageTopBanner">
            <h2>
                LOOK NO FURTHER!

            </h2>
            <p>
                We can help you, where others can’t <a routerLink="/faqs"> more info</a>
            </p>
            <a routerLink="/apply">
                <button type="submit" class="btn btn-small-orange">
          Apply Now &nbsp;<i class="fa fa-play"></i>
        </button>
            </a>
        </div> -->
        <!-- <div class="slider-wrapper">
            <div id="carouselExampleFade" class="carousel slide carousel-fade" data-ride="carousel">
                <div class="carousel-inner">

                    <div class="carousel-item active">
                        <a>
                            <img loading="lazy"
                                src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/wp/slider/covidhelpdesk-web.webp"
                                onerror="this.src='https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/slider/covidhelpdesk-web.png'"
                                class="d--block w-100 slide-web" alt="Bad credit? We can help!">
                            <img loading="lazy"
                                src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/wp/slider/covidhelpdesk-mob.webp"
                                onerror="this.src='https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/slider/covidhelpdesk-web.png'"
                                class="d--block w-100 slide-mobile" alt="Bad credit? We can help!">
                        </a>
                        <div class="carousel-caption d-md-block font-size1 slider-bg">
                            <p class="slidernew">COVID-19 UPDATE <span>WE CAN STILL HELP</span> </p>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <a><img loading="lazy"
                                src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/wp/slider/homepage_new-centre_slide_V2.webp"
                                onerror="this.src='https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/slider/homepage_new-centre_slide_V2.png'"
                                class="d--block w-100" alt="Bad credit? We can help!"></a>
                        <div class="carousel-caption d-md-block">
                            <h5>
                                New Centre <span>in Manchester!</span>
                            </h5>
                            <p>You can now collect your new car in Essex &amp; Manchester!
                            </p>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <a><img loading="lazy"
                                src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/wp/slider/slide1.webp"
                                class="d--block w-100" alt="Bad credit? We can help!"
                                onerror="this.src='https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/slider/slide1.jpg'"></a>
                        <div class="carousel-caption d-md-block">
                            <h5>
                                Bad Credit? <span>We Can Help!</span>
                            </h5>
                            <p>
                                Finance Available with No Credit Scoring! <a routerLink="/faqs">more info</a>
                            </p>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <a><img loading="lazy"
                                src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/wp/slider/slide2.webp"
                                class="d--block w-100" alt="MOT, warranty and pre-deliver inspection on all cars"
                                onerror="this.src='https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/slider/slide2.png'"></a>
                        <div class="carousel-caption d-md-block">
                            <h5>
                                MOT, Warranty & <span>128 point check</span>
                            </h5>
                            <p>on all vehicles sold as standard</p>
                        </div>
                    </div>


                    <div class="carousel-item">
                        <a><img loading="lazy"
                                src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/wp/slider/slide3.webp"
                                class="d--block w-100" alt="Bad credit car finance on 100s of cars"
                                onerror="this.src='https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/slider/slide3.jpg'"></a>
                        <div class="carousel-caption d-md-block">
                            <h5>
                                Quick Decisions on <span>100's of Vehicles</span>
                            </h5>
                            <p>
                                simply fill in our <a routerLink="/apply">new form</a> for an Agreement in
                                Principle* immediately
                            </p>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <a><img loading="lazy"
                                src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/wp/slider/slide4.wepb"
                                class="d--block w-100" alt="We deliver nationwide!"
                                onerror="this.src='https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/slider/slide4.jpg'"></a>
                        <div class="carousel-caption d-md-block">
                            <h5>
                                Nationwide delivery** <span>Available</span>
                            </h5>
                            <p>or pick up your vehicle from our centres</p>
                        </div>
                    </div>

                </div>


                <a class="carousel-control-prev slide-web" href="#carouselExampleFade" role="button"
                    data-slide="prev" style="visibility: hidden"> <span class="carousel-control-prev-icon"
                        aria-hidden="true"></span> <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next slide-web" href="#carouselExampleFade" role="button"
                    data-slide="next" style="visibility: hidden"> <span class="carousel-control-next-icon"
                        aria-hidden="true"></span> <span class="sr-only">Next</span>
                </a>
            </div>
        </div> -->


        <div class="mediaforWeb" style="display: none;">
            <div class="slider-wrapper">
                <div id="carouselExampleFade" class="carousel slide carousel-fade" data-ride="carousel">
                    <div class="carousel-inner">
<!--
                        <div class="carousel-item active">
                            <a>
                                <img loading="lazy"
                                    src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/wp/slider/covidhelpdesk-web.webp"
                                    onerror="this.src='https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/slider/covidhelpdesk-web.png'"
                                    class="d--block w-100 slide-web" alt="Bad credit? We can help!">
                                <img loading="lazy"
                                    src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/wp/slider/covidhelpdesk-mob.webp"
                                    onerror="this.src='https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/slider/covidhelpdesk-web.png'"
                                    class="d--block w-100 slide-mobile" alt="Bad credit? We can help!">
                            </a>
                            <div class="carousel-caption d-md-block font-size1 slider-bg">
                                <p class="slidernew">COVID-19 UPDATE <span>WE CAN STILL HELP</span> </p>
                            </div>
                        </div>

                        <div class="carousel-item">
                            <a><img loading="lazy"
                                    src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/wp/slider/homepage_new-centre_slide_V2.webp"
                                    onerror="this.src='https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/slider/homepage_new-centre_slide_V2.png'"
                                    class="d--block w-100" alt="Bad credit? We can help!"></a>
                            <div class="carousel-caption d-md-block">
                                <h5>
                                    New Centre <span>in Manchester!</span>
                                </h5>
                                <p>You can now collect your new car in Essex &amp; Manchester!
                                </p>
                            </div>
                        </div>
                    -->
                        <!-- <div class="carousel-item active">
                            <a><img loading="lazy"
                                    src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/wp/slider/slide1.webp"
                                    class="d--block w-100" alt="Bad credit? We can help!"
                                    onerror="this.src='https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/slider/slide1.jpg'"></a>
                            <div class="carousel-caption d-md-block">
                                <h5>
                                    Bad Credit? <span>We Can Help!</span>
                                </h5>
                                <p>
                                  Finance Based on your Affordability NOT your credit score* <a routerLink="/faqs">more info</a>
                                </p>
                            </div>
                        </div> -->

                        <!-- <div class="carousel-item">
                            <a><img loading="lazy"
                                    src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/wp/slider/slide2.webp"
                                    class="d--block w-100" alt="MOT, warranty and pre-deliver inspection on all cars"
                                    onerror="this.src='https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/slider/slide2.png'"></a>
                            <div class="carousel-caption d-md-block">
                                <h5>
                                    MOT, Warranty & <span>128 point check</span>
                                </h5>
                                <p>on all vehicles sold as standard</p>
                            </div>
                        </div>


                        <div class="carousel-item">
                            <a><img loading="lazy"
                                    src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/wp/slider/slide3.webp"
                                    class="d--block w-100" alt="Bad credit car finance on 100s of cars"
                                    onerror="this.src='https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/slider/slide3.jpg'"></a>
                            <div class="carousel-caption d-md-block">
                                <h5>
                                    Quick Decisions on <span>100's of Vehicles</span>
                                </h5>
                                <p>
                                    simply fill in our <a routerLink="/apply">new form</a> for an Agreement in
                                    Principle* immediately
                                </p>
                            </div>
                        </div>

                        <div class="carousel-item">
                            <a><img loading="lazy"
                                    src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/wp/slider/slide4.webp"
                                    class="d--block w-100" alt="We deliver nationwide!"
                                    onerror="this.src='https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/slider/slide4.jpg'"></a>
                            <div class="carousel-caption d-md-block">
                                <h5>
                                    Nationwide delivery** <span>Available</span>
                                </h5>
                                <p>or pick up your vehicle from our centres</p>
                            </div>
                        </div>
     -->
                    </div>


                    <a class="carousel-control-prev slide-web" href="#carouselExampleFade" role="button"
                        data-slide="prev" style="visibility: hidden"> <span class="carousel-control-prev-icon"
                            aria-hidden="true"></span> <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next slide-web" href="#carouselExampleFade" role="button"
                        data-slide="next" style="visibility: hidden"> <span class="carousel-control-next-icon"
                            aria-hidden="true"></span> <span class="sr-only">Next</span>
                    </a>
                </div>
            </div>
        </div>


        <div class="mediaforMobile" style="display: none;">
            <div class="slider-wrapper">
                <div id="carouselExampleFade" class="carousel slide carousel-fade" data-ride="carousel">
                    <div class="carousel-inner">

                        <!-- <div class="carousel-item active">
                            <a>
                                <img loading="lazy"
                                    src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/wp/slider/covidhelpdesk-web.webp"
                                    onerror="this.src='https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/slider/covidhelpdesk-web.png'"
                                    class="d--block w-100 slide-web" alt="Bad credit? We can help!">
                                <img loading="lazy"
                                    src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/wp/slider/covidhelpdesk-mob.webp"
                                    onerror="this.src='https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/slider/covidhelpdesk-web.png'"
                                    class="d--block w-100 slide-mobile" alt="Bad credit? We can help!">
                            </a>
                            <div class="carousel-caption d-md-block font-size1 slider-bg">
                                <p class="slidernew">COVID-19 UPDATE <span>WE CAN STILL HELP</span> </p>
                            </div>
                        </div> -->

                        <!-- <div class="carousel-item active">
                            <a><img loading="lazy"
                                    src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/wp/slider/homepage_new-centre_slide_V2-mobile.webp"
                                    onerror="this.src='https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/slider/homepage_new-centre_slide_V2.png'"
                                    class="d--block w-100" alt="Bad credit? We can help!"></a>
                            <div class="carousel-caption d-md-block">
                                <h5>
                                    New Centre <span>in Manchester!</span>
                                </h5>
                                <p>You can now collect your new car in Essex &amp; Manchester!
                                </p>
                            </div>
                        </div> -->

                        <!-- <div class="carousel-item active">
                            <a><img loading="lazy"
                                    src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/wp/slider/slide1-mobile.webp"
                                    class="d--block w-100" alt="Bad credit? We can help!"
                                    onerror="this.src='https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/slider/slide1.jpg'"></a>
                            <div class="carousel-caption d-md-block">
                                <h5>
                                    Bad Credit? <span>We Can Help!</span>
                                </h5>
                                <p>
                                    Finance Available with No Credit Scoring&#42;! <a routerLink="/faqs">more info</a>
                                </p>
                            </div>
                        </div> -->
<!--
                        <div class="carousel-item">
                            <a><img loading="lazy"
                                    src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/wp/slider/slide2-mobile.webp"
                                    class="d--block w-100" alt="MOT, warranty and pre-deliver inspection on all cars"
                                    onerror="this.src='https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/slider/slide2.png'"></a>
                            <div class="carousel-caption d-md-block">
                                <h5>
                                    MOT, Warranty & <span>128 point check</span>
                                </h5>
                                <p>on all vehicles sold as standard</p>
                            </div>
                        </div>


                        <div class="carousel-item">
                            <a><img loading="lazy"
                                    src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/wp/slider/slide3.webp"
                                    class="d--block w-100" alt="Bad credit car finance on 100s of cars"
                                    onerror="this.src='https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/slider/slide3.jpg'"></a>
                            <div class="carousel-caption d-md-block">
                                <h5>
                                    Quick Decisions on <span>100's of Vehicles</span>
                                </h5>
                                <p>
                                    simply fill in our <a routerLink="/apply">new form</a> for an Agreement in
                                    Principle* immediately
                                </p>
                            </div>
                        </div>

                        <div class="carousel-item">
                            <a><img loading="lazy"
                                    src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/wp/slider/slide4.webp"
                                    class="d--block w-100" alt="We deliver nationwide!"
                                    onerror="this.src='https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/slider/slide4.jpg'"></a>
                            <div class="carousel-caption d-md-block">
                                <h5>
                                    Nationwide delivery** <span>Available</span>
                                </h5>
                                <p>or pick up your vehicle from our centres</p>
                            </div>
                        </div> -->

                    </div>


                    <a class="carousel-control-prev slide-web" href="#carouselExampleFade" role="button"
                        data-slide="prev" style="visibility: hidden"> <span class="carousel-control-prev-icon"
                            aria-hidden="true"></span> <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next slide-web" href="#carouselExampleFade" role="button"
                        data-slide="next" style="visibility: hidden"> <span class="carousel-control-next-icon"
                            aria-hidden="true"></span> <span class="sr-only">Next</span>
                    </a>
                </div>
            </div>
        </div>




        <div class="heading-wrapper">
            <h1 style="color: #4d4d4d;">Welcome to The Car Loan Centre</h1>
        </div>

        <!-- Setps wrapper flex container html beginning here -->
        <!-- <div class="steps-wp-flex">

            <div class="stepOne-wrapper step-box-for-mobile" routerLink="/apply">
                <div class="inner-content-bg">
                    <img loading="lazy" src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/wp/step-one-bg.webp" alt="Simple online application for car finance" class="steps-img-web" onerror="this.src='https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/step-one-bg.png'">
                    <img loading="lazy" src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/wp/step-one-bg-mobile.webp" alt="Simple online application for car finance" class="steps-img-mobile" onerror="this.src='https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/step-one-bg-mobile.png'">

                    <div class="inner-text">
                        <h2>
                            <span class="large-text">S</span>TEP <span class="large-text">O</span>NE
                        </h2>
                        <h5>APPLY ONLINE!</h5>
                        <p>
                            Simply fill in our <strong><i>Fast Track</i></strong> application form,
                            <br> and receive an immediate quick car finance agreement in principle*
                        </p>
                        <a routerLink="/apply">
                            <button type="submit" class="btn btn-small-orange">
              Apply Now &nbsp;<i class="fa fa-play"></i>
            </button>
                        </a>
                    </div>
                </div>

            </div>


            <div class="stepTwo-wrapper step-box-for-mobile" routerLink="/cars">
                <div class="inner-content-bg">
                    <img loading="lazy" src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/wp/step-two-bg.webp" alt="Quality used cars for sale" class="steps-img-web" onerror="this.src='https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/step-two-bg.png'" >
                    <img loading="lazy" src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/wp/step-two-bg-mobile.webp" alt="Quality used cars for sale" class="steps-img-mobile" onerror="this.src='https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/step-two-bg-mobile.png'" >
                    <div class="rightText">
                        <h2>
                            <span class="large-text">S</span>TEP <span class="large-text">T</span>WO
                        </h2>
                        <h5>CHOOSE A VEHICLE!</h5>
                        <p>
                            100's of Quality used Cars in stock<br> All vehicles come with MOT, Warranty & Comprehensive check
                        </p>
                        <a routerLink="/cars">
                            <button type="submit" class="btn btn-small-orange">
              <span class="textForWeb">View Our Cars</span> <span class="textForMob">Select a Car</span> &nbsp;<i class="fa fa-play"></i>
              </button>
                        </a>
                    </div>
                </div>
            </div>

            <div class="stepThree-wrapper step-box-for-mobile" routerLink="/apply">
                <div class="inner-content-bg">
                    <img loading="lazy" src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/wp/step-three-bg.webp" alt="Enjoy your new car!" class="steps-img-web" onerror="this.src='https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/step-three-bg.png'">
                    <img loading="lazy" src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/wp/step-three-bg-mobile.webp" alt="Enjoy your new car!" class="steps-img-mobile" onerror="this.src='https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/step-three-bg-mobile.png'" >

                    <div class="inner-text">
                        <h2>
                            <span class="large-text">S</span>TEP <span class="large-text">T</span>HREE
                        </h2>
                        <h5>ONCE APPROVED DRIVE AWAY!</h5>
                        <p>
                            Select your Delivery/Collection Date<br> Sign your documents & enjoy your new car
                        </p>
                        <a routerLink="/apply">
                            <button type="submit" class="btn btn-small-orange">
              <span class="for-web-btn">Apply Now</span> <span class="for-mobile-btn">Once approved<br>drive away!
              </span>&nbsp;<i class="fa fa-play"></i>
            </button>
                        </a>
                    </div>
                </div>
            </div>


        </div> -->
        <!-- Setps wrapper flex container html end here -->


        <div class="bad-credit-home-top">
            <span role="img" aria-label="A couple looking into the interior of a car in a showroom for bad credit car loans"> </span>
            <div class="content-bg1">
                <h2 class="primary-color">
                    Bad Credit <span>Car Loans</span>
                </h2>
                <p class="paragraph">
                    Here at The Car Loan Centre, we specialise in offering car finance for bad credit history applicants. We consider all circumstances and we really can help you get a new car with our bad credit car loans. Been declined elsewhere when looking at buying
                    a car with bad credit? We can help provide you with <a routerLink="/car-finance" class="applyNow">car
            finance</a>, even if you have bad or poor credit. If you have a CCJ, Default, or have got into arrears or even been previously bankrupt, we can offer bad credit finance. Do you currently have a Benefits only income? Or are you Self-Employed
                    and finding it difficult to purchase the vehicle you want? We’re proud to offer our customers a service they may not easily find elsewhere, considering more than just your credit score*; in fact we don’t use it at all!
                </p>
                <p class="paragraph">
                    You can <a routerLink="/apply" class="applyNow">apply</a> through us today in confidence that we will take your current circumstances and base a decision on what you can afford to pay. We do not rely on an automated response based
                    on your credit score* when assessing your application for quick car finance. We take a personal approach and review all applications individually, all whilst following FCA guidelines as a responsible lender.
                </p>
                <p class="paragraph">
                    As our finance is based on your affordability as opposed to your credit score*, we're able to approve many more customers. To find out more information, you can view what our customers have previously queried in our <a routerLink="/faqs"
                        class="applyNow">FAQs</a>. If you want to apply for our Bad Credit finance and already have a vehicle in mind, you can <a routerLink="/cars" class="applyNow">view our cars</a> currently available and find the ideal vehicle today.
                </p>
                <p class="paragraph">We really can consider ALL circumstances here at The Car Loan Centre. Even if you have been declined and refused car finance by other lenders, find out how we can still help you today in the same way we’ve helped 1000’s of customers already.
                </p>
            </div>
        </div>


        <div class="bad-credit-home-footer content-bg1">
            <h2 class="primary-color">
                Car Finance for <span>Bad Credit History</span>
            </h2>
            <div class="content-inner">
                <p class="paragraph">Whilst looking for a vehicle on finance, you may have come across companies who offer "guaranteed" approval. Similar to companies who offer no credit check car finance, this is something that most lenders and dealerships are unable to
                    do. Many will be basing their approval on your credit score* solely, declining you very quickly if you are looking for car finance with a bad credit history.</p>
                <p class="paragraph">Any dealerships or lenders offering “guaranteed” finance has caused concern for the industry regulators, and rightly so. Here at The Car Loan Centre, we’re fully committed to the responsible lending guidelines. We review our cases differently, as we want to help as many people with poor credit as we can! Our Underwriting team focus on your affordability, and whilst we do perform a check on your credit history to assist our decision, we do not review or base our decision on your credit score*. This way, we are able to help as many customers as possible.
                </p>
            </div>


        </div>










        <div class="content-card content-bg3">
            <h2 class="primary-color">Buying a Car with Bad Credit</h2>
            <div class="content-inner">
                <p class="paragraph">We consider those who have had past or present difficulties which means we can say YES to more customers than many other lenders. Even with this in mind, we are still unable to "Guarantee" you will be accepted, but we’re able to approve
                    more customers than most for our bad credit car loans!</p>
                <p class="paragraph">
                    We can make buying a car with bad credit simple. You won’t have to worry about having really bad credit as car finance with us is based on your affordability. We’ll always strive to get back to you as quickly as we can to get you in the driver’s seat
                    and back on the road. If you’ve already made your mind up, <a routerLink="/cars" class="applyNow">view
            our cars</a> available and click <a routerLink="/apply" class="applyNow">apply</a> now.
                </p>

            </div>

            <h2 class="primary-color">The Car Loan Centre FAQs</h2>

            <div class="content-inner">
                <p class="paragraph mb4">
                    <b>Do You Search My Credit Report?</b>
                </p>
                <p class="paragraph">We work differently here at The Car Loan Centre, in that your credit score* is not a factor in deciding to lend to you. When we look at your financial history, this is a smaller element to build the lending process, with us focusing on
                    your affordability first and foremost. This is how we can provide finance for bad credit. To help aid our decision in deciding your affordability, you should be able to provide the following information:
                </p>
                <ul class="innerText">
                    <li>Proof of ID</li>
                    <li>Proof of Address</li>
                    <li>Your last 3 month’s bank statements</li>
                </ul>

                <p class="paragraph">Once we have received this information, we will be able to make a clearer and more accurate lending decision.</p>
                <p class="paragraph mb4">
                    <b>Can You Still Lend to Me if I Have a Default or CCJ?</b>
                </p>
                <p class="paragraph">
                    Yes, the good news is we can offer <a routerLink="/ccj-loans" class="applyNow">CCJ Loans</a> finance options even if you have various forms of bad credit including CCJs or Defaults. In fact, as long as you are not currently bankrupt or filing for bankruptcy, we could help you. As bad credit car finance specialists,
                    we are happy to offer financial help to those that have been turned away elsewhere. Please contact us to find out more by <a class="request-callback-link link-for-mobile" (click)="showCallbackFrom()">requesting
            a callback</a> <a class="request-callback-link link-for-web" (click)="wobbleForm()">requesting
            a callback</a> or <a routerLink="/apply" class="applyNow">applying today</a>.
                </p>
                <p class="paragraph mb4">
                    <b>How Old Do I Need to Be to Apply?</b>
                </p>
                <p class="paragraph">The minimum age we can accept a finance application from is 18 years old. Our lending is based on your affordability and we can help even if you have No credit history. You can apply whether you are in full time education, employment or
                    receiving benefits.</p>
                <p class="paragraph mb4">
                    <b>Can I Get Guaranteed Finance with You?</b>
                </p>
                <p class="paragraph">
                    No car loan lender can provide guaranteed finance. We consider many different circumstances to help broaden the range of people and situations we can help. However, no loan is guaranteed for approval as it is subject to initial checks of your credit history
                    and affordability. If you have any queries, please <a routerLink="/contact" class="applyNow">contact
            us</a> directly.
                </p>
                <p class="paragraph mb4">
                    <b>Looking for Financial Support?</b>
                </p>
                <p class="paragraph">
                    If you feel you could benefit from free, impartial debt advice you can access help from The Money Advice Service, a not for profit debt advice body <a rel="noreferrer" target="_blank" href="https://www.moneyhelper.org.uk/en/money-troubles/dealing-with-debt/use-our-debt-advice-locator?source=mas"
                        class="applyNow">here</a>.
                </p>


            </div>

        </div>




        <div class="homeFooterSearch">
            <app-search-vehicle></app-search-vehicle>
        </div>


        <div class="poster-wp home-poster" loading="lazy">
            <h2>
                At The Car Loan Centre<br> we want to put you back in the drivers seat
            </h2>
        </div>





    </div>
    <!-- middle content html beginning here -->



    <!-- right content html beginning here -->
    <div class="right-container forMobileform" id="requestCallbackMainId">
        <!--	---fixed wrapper Request A Callback...html beginning here-- - -->
        <div class="requestCallBack-fixed-wp">
            <app-request-callback></app-request-callback>
        </div>
    </div>
</div>
<!-- flex-container content html end here -->

<app-footer></app-footer>
