<!-- footer html beginning here -->
<footer class="footer-wrapper">


    <div class="foter-top-wp">

        <span id="allPages" *ngIf="!showForLogbookPages">
            <p class="text-top">Representative example – Total amount of credit £9,548, annual interest rate 40% (fixed), Handling fee £399 (payable in advance), Total charge for credit £13,051.04, Total amount payable (includes Advance Payment of the Handling Fee and if applicable any Deposit) £22,599.04, term of the agreement 60 months, 59 monthly instalments of £370.01 and a final instalment of £369.45. 51.87% APR Representative, plus a £25 option to purchase fee. – Subject to status & affordability.</p>
        <p class="textSmall">
          Warning: Your vehicle is at risk of repossession if you do not maintain your monthly payments, and late payment can cause you serious money problems. For help go to <a class="applyNow" href="https://www.moneyhelper.org.uk/en" target="_blank">Moneyhelper.org.uk</a>. When buying a car, you may also want to consider the ongoing running costs such as which warranty is best for you or whether you may wish to organise independent breakdown cover. You should also be aware of factors like depreciation. If you’re not sure what all these terms mean, our  <a href="/faqs#jargon-buster-tab">Jargon Buster</a> helps explain everything in everyday English!
        </p>
      </span>

        <span id="LogbookLoansPage" *ngIf="showForLogbookPages">
        <p class="text-top">Representative Example - Total amount of credit £3,095, annual interest rate 30.4% (fixed). Total charge for credit £2,348.51 (includes an Administration fee of £500). Total amount payable £5,443.51. Term of the agreement 30 months, 29 monthly
            instalments of £181.45 and a final instalment of £181.46. 62.2% APR Representative, plus a £25 option to purchase fee. – Subject to status & affordability.</p>
        <p class="textSmall">Warning: A Logbook Loan is secured against your vehicle, your vehicle is at risk of repossession if you do not maintain your monthly payments, and late payment can cause you serious money problems. For help go to <a class="applyNow" href="https://www.moneyhelper.org.uk/en" target="_blank">Moneyhelper.org.uk</a>
        </p>
      </span>
      <!-- <noscript>
        <p class="text-top">Representative example – Total amount of credit £7999, annual interest rate 26.23% (fixed), Handling fee £249 (payable in advance), Total charge for credit £8,393.96, Total amount payable (includes Advance Payment of Handling Fee and Deposit)
            £16,442.96, term of the agreement 48 months, 47 monthly instalments of £336.33 and a final instalment of £336.45. 51.32% APR Representative, plus a £25 option to purchase fee. – Subject to status &amp; affordability.</p>
        <p class="textSmall">
            Please be aware your vehicle is at risk if you do not maintain your monthly payments. When buying a car, you may also want to consider the ongoing running costs such as which warranty is best for you or whether you may wish to organise independent breakdown
            cover. You should also be aware of factors like depreciation. If you’re not sure what all these terms mean, our <a href="/faqs#jargon-buster-tab">Jargon Buster</a> helps explain everything in everyday English!
        </p>
      </noscript> -->
    </div>


    <div class="foter-bottom-wp">
        <div class="footer-flex">
            <div class="footer-menu-wrapper" >
                <ul>
                    <li><a routerLink="/terms">Website Terms</a></li>
                    <li><a routerLink="/policy">Privacy Policy</a></li>
                    <li><a routerLink="/centre-assessments">Centre Assessments</a></li>
                    <li><a routerLink="/complaints">Complaints Procedure</a></li>
                    <li><a routerLink="/cookie">Cookies</a></li>
                    <li><a routerLink="/offers">Offers</a></li>
                    <li><a routerLink="/warranty" >Warranty</a></li>
                    <li><a routerLink="/promo" class="border-none">Promo</a></li>
                </ul>
                <ul class="footerNoScript">
                    <li><a routerLink="/specialist-car-finance">Specialist Car Finance</a></li>
                    <!-- <li><a routerLink="/bad-credit-car-loans">Bad Credit Car Loans</a></li> -->
                    <li><a routerLink="/used-cars">Used Cars</a></li>
                    <li><a routerLink="/benefit-loans">Benefit Loans</a></li>
                    <li><a routerLink="/declined-finance">Declined Finance</a></li>
                    <li><a routerLink="/ccj-loans">CCJ Loans</a></li>
                    <!-- <li><a routerLink="/poor-credit-car-finance">Poor Credit Car Finance</a></li> -->
                    <li><a routerLink="/no-credit-score-check">No Credit Scoring&#42;</a></li>
                    <li><a routerLink="/default-car-finance">Default Car Finance</a></li>
                    <li><a routerLink="/no-guarantor-car-finance">No Guarantor Car Finance</a></li>
                    <li><a routerLink="/essex" class="border-none">Essex</a></li>
                    <!-- <li><a routerLink="/manchester" class="border-none">Manchester</a></li> -->
                </ul>
                 <!-- <noscript>
                    <style type="text/css">
                        .footerNoScript {display:none !important;}
                    </style>
                    <ul class="footerWithNoScript">
                        <li><a href="/terms">Website Terms</a></li>
                        <li><a href="/policy">Privacy Policy</a></li>
                        <li><a href="/centre-assessments">Centre Assessments</a></li>
                        <li><a href="/complaints">Complaints Procedure</a></li>
                        <li><a href="/cookie">Cookies</a></li>
                        <li><a href="/offers">Offers</a></li>
                        <li><a href="/warranty" class="border-none">Warranty</a></li>
                    </ul>
                </noscript> -->
            </div>
            <div class="copyright-wrapper"><span>Copyright &copy; {{year}} The Car Loan Centre | All Rights Reserved</span></div>
        </div>

        <p id="LogbookLoansPage1" *ngIf="showForLogbookPages">*We guarantee to match any comparable product (excludes introductory or promotional offerings). **Calculations are based on 30.4% flat rate p.a. (fixed) ***All details correct at time of publication (17/03/2021).</p>
        <p id="allPages1" *ngIf="!showForLogbookPages">*All applicants will be required to verify their details including being credit checked to confirm their identity. All applications are subject to status, and we reserve the right to alter any offer of finance for any reason. **Fees may apply to Nationwide Delivery.</p>
        <p>The Car Loan Centre is a trading style of The Asset Exchange Ltd. The Asset Exchange Ltd is authorised and regulated by the Financial Conduct Authority, firm reference 729827. Registered Address: The Car Loan Centre, 77 Wollaston Way, Burnt Mills
            Industrial Estate, Basildon, Essex, SS13 1DJ, registered company no: 08679485.
        </p>
        <p>
            We are a lender, and not a broker, and operate with a flat interest rate for all customers. We pay our sales team a flat rate of commission for vehicles sold, and this does not affect the terms of your agreement, nor is it affected by your choice of vehicle or the terms of your finance. We may receive commission from other third parties as a fixed fee or fixed % of commission to us for introducing you to their services with your consent. If you elect to purchase one of these 3rd party products/services we may pay our sales person an additional commission for this, For example if you choose to take an extended warranty. If you would like a breakdown of all third parties who may pay us a commission in certain circumstances this can be provided on request.
        </p>
    </div>
</footer>
<!-- footer html end here -->
<app-privacy-policy-popup></app-privacy-policy-popup>
<app-terms-conditions-popup></app-terms-conditions-popup>
