import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Constants {

  public errorNoInternetConnectionAvailable = "Kindly check your internet connection";
  public noInternetConnectionError = "Kindly check your internet connection";
  errorUnauthorized: any;
  errorMsgUnauthorized: any;
  errorNotFound: any;
  erroMsgNotFound: string;
  errorDuplicate: any;
  error409: any;
  errorMsgForDefault: any;
  public onlyNumeric = "Only Numerics are allowed in the input";
  public zeroError = "cannot be zero"
  public errorInvalidText = "No Special Characters are allowed in the input"
  errorInvalidEmailAddress="Please provide a valid email address"
  errorEmpty="Field can not be empty."
  errorInputEmpty="Please enter a ";
  errorSelectWithoutA = "Please select ";
  errorSelectEmpty="Please select a ";
  errorSelectAnEmpty="Please select an ";
  errorValidEmpty = "Please enter a valid ";
  errorEnterSelectValid = "Please enter/select a valid"
  errorTerms = 'You must agree to the terms and conditions to submit an application';
  firstnameEmpty="Please provide a first name."
  surnameEmpty="Please provide a surname."
  emailnameEmpty="Please provide an email address."
  contactnummberempty="Please provide a phone number."
  contactnummbergreater="Please provide a valid phone number"
  errorMinLengthTwo="Please provide a valid phone number"
  errormaxLengthTwelve="Not a valid contact number"
  access_token="access_token"
  //errorChckboxClick="Please Check terms and conditions";

  errorChckboxClick="You must agree to the terms and conditions to submit a callback request.";
  errorNOSpaceAllowed="Can not contain space";
  errorNoRecipient="Please provide a recipient.";
  errorNoName="Please enter your name.";
  errorMilage="Please provide your mileage.";
  errorRegistration="Please provide your registration.";
  agenoteligible="You must be atleast 18 to apply for car."
  errorSelection = "Please make a selection.";

  // apply page static messages
  errorPrefferedType = "Please select a preferred vehicle type";
  errorLicenceType = "Please select a licence type";
  erroryearsAtAddressType = "Please select time at address";
  errorYearsAtJob = "Please select time in occupation"
  errorTotalMonthlyIncome = "Please select monthly take home pay";
  errorBankruptcy = "Please select bankruptcy status";
  static getArray(name: string): any[] {
    if (name == "make")
      return this.makeArray
    else if (name == "transmission")
      return this.transmissionArray
    else if (name == "bodytype")
      return this.bodytypeArray
    else if (name == "maxprice")
      return this.maxpriceArray

  }
  //public loadingMessage = "Loading";

  public static makeArray = ["Any", "Alfa Romeo", "Audi", "BMW", "Chevrolet", "Citroen", "Dacia", "Fiat", "Ford", "Honda", "Jaguar", "Kia", "Mazda", "Mercedes-Benz", "Mini",
    "Mitsubishi", "Nissan", "Peugeot", "Renault", "Seat", "Skoda", "Smart", "Suzuki", "Toyota", "Vauxhall", "Volkswagen", "Volvo"];

  public static transmissionArray = ["Any", "MANUAL", "AUTOMATIC", "SEMI AUTO", "CVT"];

  public static bodytypeArray = ["Any", "3 DOOR HATCHBACK", "4 DOOR SALOON", "5 DOOR HATCHBACK", "CONVERTIBLE", "COUPLE", "ESTATE", "MULTI PURPOSE", "PANEL VAN(INTEGRAL)"];

  public static maxpriceArray = ["Any"];

  public getArray(array: any) {
    return array;
  }









}
