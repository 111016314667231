import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-cookies-popup',
  templateUrl: './cookies-popup.component.html',
  styleUrls: ['./cookies-popup.component.scss']
})
export class CookiesPopupComponent implements OnInit {

  isCookie: boolean = false;
  expireDays: number = 7;
  constructor(    private _cookieService: CookieService
  ) { }

  ngOnInit(): void {
    this.isCookie = this._cookieService.get('CookiesAccepted') === "Yes" ? true : false
  }

  onDismissClick() {
    this._cookieService.set('CookiesAccepted', 'Yes', { expires: this.expireDays, path:'/' });
    this.isCookie = true;
  }
}
