import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { DataTransferService } from '../common/data-transfer.service';
import { CanonicalService } from './shared/canonical.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']

})
export class AppComponent implements OnInit { rendered = false;
  title = 'The car loan centre | We are Bad Credit Car Finance Specialists supplying quality used cars';
  spinnerApp: string = "appSpinner";
  public static totalCalls: string[] = [];
  loaderSubscription: Subscription;

  schema;
  constructor(private titleService: Title, private router: Router,
    private metaTagService: Meta, public http: HttpClient, public dataTransferService: DataTransferService, public ngxSpinner: NgxSpinnerService, private canonicalService: CanonicalService) {
      setTimeout(() => {
      this.rendered = true;
    }, 100)
  }

  getDataHttp(url:string) {

    return this.http.get(url);
}
  ngOnInit(): void {
    if(window.location.pathname == '/')
    {
      this.getDataHttp('../assets/json-ld/home.json').subscribe(
        data => {
          this.schema = data;
        }
      );
    }
    else{
      this.getDataHttp('../assets/json-ld' + window.location.pathname + '.json').subscribe(
        data => {
          this.schema = data;
        }
      );
    }
    this.title = this.title;
    this.metaTagService.addTags([
      // { name: 'keywords', content: 'We are Bad Credit Car Finance Specialists supplying quality used cars, including bad credit finance, even if you have been declined elsewhere! Apply today!' },
      // { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'The car loan centre' },
      { name: 'charset', content: 'UTF-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1.0, user-scalable=no, maximum-scale=10.0' },
      { charset: 'UTF-8' }
    ]);

    this.router.events.subscribe((event) => {
      if(event instanceof NavigationEnd) {
        this.canonicalService.setCanonicalURL(event.url);
      }
    });


    showHideLoader(this);
//     setTimeout(function(){
//      loadJsDrift();
//  }, 100)


  }

  ngOnDestroy(): void {
    if (this.loaderSubscription && !this.loaderSubscription.closed) {
      this.loaderSubscription.unsubscribe();
    }
  }

}
function showHideLoader(context: AppComponent) {
  context.loaderSubscription = context.dataTransferService.hideShowLoaderObservable.subscribe((showLoader: boolean) => {
    if (showLoader) {
      context.ngxSpinner.show(context.spinnerApp);
    } else {
      if (AppComponent.totalCalls.length == 0)
        context.ngxSpinner.hide(context.spinnerApp);
    }
  })
}
