export class Paths {
  static pathLogin(pathLogin: any) {
    throw new Error('Method not implemented.');
  }
  public static pathHome = "home";
  public static pathBadCreditLoans = "bad-credit-car-loans";
  public static pathCCJLoans = "ccj-loans";
  public static pathBenefitsLoans = "benefit-loans";
  public static pathNoCardScoring = "no-credit-score-check";
  public static pathThankyouSubmitRequest = "success";
  public static pathApplyThankyouSubmitRequest = "applysuccess";
  public static pathjargonBuster = "jargon";
  public static pathLogBookLoan = "logbook-loans";
  public static pathCompareLogBookLoan = "logbook-loans/compare";
  public static pathFAQ = "faqs";
  public static pathApply = "apply";
  public static pathPrivacyPolicy = "policy";
  public static pathTermsAndCondition = "terms";
  public static pathWarranty = "warranty";
  public static pathOffers = "offers";
  public static pathCookies = "cookie";
  public static pathCarFinance = "car-finance";
  public static pathAbout = "about";
  public static pathContact = "contact";
  public static pathLogbookCompare = "compare";
  public static pathComplaintProcedure = "complaints";
  public static pathCentreAssessments = "centre-assessments";
  public static pathdefaultcarfinance = "default-car-finance";
  public static pathnoguarantorcarfinance = "no-guarantor-car-finance";
  public static pathpoorcreditcarfinance = "poor-credit-car-finance";
  public static pathspecialistcarfinance = "specialist-car-finance";
  public static pathReview = "review";
  public static pathViewOurCars = "cars";
  public static pathUsedCarFinance = "used-cars";
  public static pathManchesterCarFinance = "manchester";
  public static pathCarFinanceInEssex = "essex";
  public static pathDeclinedCarFinance = "declined-finance";
  public static pathCarDetails = 'cars/info';
  public static pathPromo = "promo";
  public static pathBlog = "blog";
  public static pathBlogDetails1 = "blog-details1";
  public static pathadministration = "administration";

}
