<div *ngIf="!isCookie">
  <mat-card class="welcome" id="indicatorFixedId">
      <div class="cookieBanner" id="cookieBannerID">
          <div>
              <p> We use cookies and Facebook tools* to provide you with the best experience possible. By continuing, you agree to our use of these tools. </p>
          </div>
          <div class="cookie-btn-flex">
              <button id="gotItButton" class="btn btn-secondary" type="button" (click)="onDismissClick()">Continue</button>
              <a class="ml10" hreflang="en-gb" routerLink="/cookie">more info</a>
          </div>
      </div>
  </mat-card>
</div>
