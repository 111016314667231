export class MasterList{
  public static make = ['Alfa Romeo', 'Audi', 'BMW', 'Chevrolet', 'Citroen', 'Dacia', 'Fiat', 'Ford', 'Honda', 'Hyundai', 'Jaguar', 'Kia', 'Mazda', 'Mercedes-Benz', 'Mini', 'Mitsubishi', 'Nissan', 'Peugeot', 'Renault', 'Seat', 'Skoda', 'Smart', 'Ssangyong', 'Suzuki', 'Toyota', 'Vauxhall', 'Volkswagen', 'Volvo'];
  public static transmission = [{ value: 'Manual', label: 'Manual' }, { value: 'Automatic', label: 'Automatic' }, { value: 'Semi Auto', label: 'Semi Auto' }, { value: 'CVT', label: 'CVT' }];
  public static fueltype = [{ value: 'Petrol', label: 'Petrol' }, { value: 'Diesel', label: 'Diesel' }, { value: 'Hybrid Electric', label: 'Hybrid Electric' }];
  public static bodytype = [{ value: '3 Door Hatchback', label: '3 Door Hatchback' }, { value: '4 Door Saloon', label: '4 Door Saloon' }, { value: '5 Door Hatchback', label: '5 Door Hatchback' }, { value: 'Convertible', label: 'Convertible' }, { value: 'Coupe', label: 'Coupe' }, { value: 'Estate', label: 'Estate' }, { value: 'Multi Purpose', label: 'Multi Purpose' }, { value: 'Panel Van (Integral)', label: 'Panel Van (Integral)' }];
  public static regyear = [{ value: '2007', label: '2007+' }, { value: '2008', label: '2008+' }, { value: '2009', label: '2009+' }, { value: '2010', label: '2010+' }, { value: '2011', label: '2011+' }, { value: '2012', label: '2012+' }, { value: '2013', label: '2013+' }, { value: '2014', label: '2014+' }, { value: '2015', label: '2015+' }, { value: '2016', label: '2016+' }, { value: '2018', label: '2018+' }];
  public static seats = ['2', '3', '4', '5', '7'];
  public static enzinesize = ['0.9L', '1L', '1.2L', '1.4L', '1.5L', '1.6L', '1.7L', '1.8L', '2L', '2.1L', '2.2L', '2.7L', '3L', '4L'];
  public static price = [{ value: 'less_4000', label: 'Less Than £4000' }, { value: 'less_5000', label: 'Less Than £5000' }, { value: "less_6000", label: 'Less Than £6000' }, { value: "less_7000", label: 'Less Than £7000' }, { value: "less_8000", label: "Less Than £8000" }, { value: "less_9000", label: "Less Than £9000" }, { value: "less_10000", label: "Less Than £10000" }, { value: "more_10000", label: "More Than £10000" }];
  public static sorting = [{ value: 'sortingon=availability', label: 'Sort By Availability' }, { value: 'sortingon=price&sortorder=asc', label: 'Sort By Price Asc' }, { value: "sortingon=price&sortorder=desc", label: 'Sort By Price Desc' }, { value: "sortingon=carage", label: 'Sort By Car Age' }, { value: "sortingon=stockinessex", label: "Sort By Essex Centre" }, { value: "sortingon=stockinmanchester", label: "Sort By Manchester Centre" }, { value: "sortingon=readytogo", label: "Sort By Ready To Go" }];
}
