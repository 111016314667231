<!-- header fixed wrapper beginning here -->
<div [ngClass]="changeLogoText?'logbook-fixed':''" class="headerFixed-wrapper " id="myHeader">
    <!-- header html beginning here -->

    <header class="header-wrapper">
        <div class="flex-container-header">
            <div class="logo-wp">
                <a class="mobile-logo"> <img loading="lazy" src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/tclc-logo-mobile-new.svg" rel="noopener"
                        alt="Bad Credit Car Finance Specialists" usemap="#Map" title="Logo" width="115" height="44" href="../">
                    <map name="Map">
                        <area shape="rect" coords="-5,4,101,48" href="../">
                        <area shape="rect" coords="105,3,159,47" routerLink="/review">
                    </map>
                </a>

                <a href="../" id="logoWebId" class="logo-web-wp">
                    <img loading="lazy" src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/tclcHeaderLogo-new.svg" alt="Thecarloancentre"
                        title="Logo" class="logo-web" rel="noopener" width="162" height="62">
                    <!-- <p *ngIf="!changeLogoText">Bad Credit Car Finance Specialists <span class="inBlock"></span></p>
                    <p *ngIf="changeLogoText">Great Britain's <span class="textBold">CHEAPEST</span> Logbook Lender
                        <span class="inBlock"></span>
                    </p> -->

                </a>
                <a href="#" class="compressed-logo" id="compressedLogoId"> 
                    <img loading="lazy" src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/car-swoosh-white.svg" rel="noopener" alt="The Car Loan Centre" title="Logo">
                </a>
            </div>
            <div class="header-contact-right social-icon-web">
                <div class="flex-contect">
                    <div class="contect-no" id="contectId"><a href="tel:03303800400">
                        <!-- <i class="fa fa-phone"></i>  -->
                        03303 800 400</a></div>
                    <div class="email-id" id="emailId">
                        <!-- <a href="mailto:info@thecarloancentre.co.uk"><i
                                class="fa fa-envelope"></i>
                            info@thecarloancentre.co.uk</a> -->
                        </div>
                </div>
                <div class="social-icons">
                    <a href="mailto:info@thecarloancentre.co.uk" title="Mail us" target="_blank" rel="noopener">Email Us</a>
                    <a routerLink="/cars" rel="noreferrer" (click)="ongotopage()" title="View Our Cars">View Our Cars</a>

                    <!-- <a href="https://www.facebook.com/thecarloancentre" rel="noreferrer" class="fb-icon"
                        title="Facebook" target="_blank" rel="noopener"> <img loading="lazy" src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/facebook-icon.svg"
                            alt="Facebook social link"></a>
                    <a href="https://www.instagram.com/thecarloancentre/" rel="noreferrer" class="instagram-icon"
                        title="Instagram" target="_blank" rel="noopener"> <img loading="lazy" src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/instagram-icon.svg"
                            alt="Visit our Instagram page"></a>
                    <a href="https://twitter.com/thecarloancentr" rel="noreferrer" class="twitter-icon" title="Twitter"
                        target="_blank" rel="noopener"> <img loading="lazy" src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/twitter-icon.svg"
                            alt="Visit our Twitter page"></a>
                    <a href="https://www.youtube.com/channel/UCMkGZZEEB65O7VYmLoy8wSg" rel="noreferrer"
                        class="youtube-icon" title="YouTube" target="_blank" rel="noopener"> <img loading="lazy"
                            src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/youtube-icon.svg" alt="Visit our YouTube Channel"></a> -->

  
                </div>
            </div>
            <div class="header-contact-right social-icon-mobile">
                <div class="social-icons">
                    <a class="apply-today-icon" *ngIf="changeLogoText" title="Apply Today"> <img loading="lazy" rel="noopener"
                            src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/apply-today-icon.svg" alt="Apply Today Icon"
                            routerLink="/apply" ></a>
                    
                            <a routerLink="/apply" class="apply-today-icon-" *ngIf="!changeLogoText" title="Apply Today"> <img
                            loading="lazy" src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/apply-today-icon.svg" rel="noopener" alt="Apply Now Icon"></a>

                            <a href="tel:03303800400"><i class="fa fa-phone"></i></a>




                    <!-- <a routerLink="/cars" class="car-icon"> <img loading="lazy" src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/car-vector-icon.svg" rel="noopener"
                            alt="View Our Cars" (click)="ongotopage()"></a> -->
                    <!-- <a href="mailto:info@thecarloancentre.co.uk" class="mail-icon" title="Mail us" target="_blank" rel="noopener"> <img loading="lazy"src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/mail-icon.svg" alt="Email us"></a> -->
                    <!-- <a href="https://www.facebook.com/thecarloancentre" rel="noreferrer" class="fb-icon"
                        title="Facebook" target="_blank" rel="noopener"> <img loading="lazy" src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/facebook-icon.svg"
                            alt="Facebook social link"></a>
                    <a href="https://www.instagram.com/thecarloancentre/" rel="noreferrer" class="instagram-icon"
                        title="Instagram" target="_blank" rel="noopener"> <img loading="lazy" src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/instagram-icon.svg"
                            alt="Visit our Instagram page"></a>
                    <a href="https://twitter.com/thecarloancentr" rel="noreferrer" class="twitter-icon" title="Twitter"
                        target="_blank" rel="noopener"> <img loading="lazy" src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/twitter-icon.svg"
                            alt="Visit our Twitter page"></a>
                    <a href="https://www.youtube.com/channel/UCMkGZZEEB65O7VYmLoy8wSg" rel="noreferrer"
                        class="youtube-icon" title="YouTube" target="_blank" rel="noopener"> <img loading="lazy"
                            src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/youtube-icon.svg" alt="Visit our YouTube Channel"></a> -->
                </div>
                <div class="menu-bar-icon"><span (click)="showMenuLeft()"><i class="fa fa-bars"></i></span></div>
            </div>
        </div>
    </header>


    <!-- header html end here -->

    <!--this-container is only for mobile - beginning here-->
    <div class="for-mobile-wrapper">
        <!-- <div class="bar-for-mobile d-flex justify-content-between mbott3">
            <div class="for-mobile-no"><a href="tel:03303800400"><i class="fa mr-2"><img
                            src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/call-icon.svg" rel="noopener" alt="Call us" style="height: 26px; width: 26px;"></i>
                    03303 800 400</a>
            </div>
            <div class="for-emails"><a href="mailto:info@thecarloancentre.co.uk" class="justify-content-end">Email <i
                        class="fa ml-2"><img src="https://axlehublive.blob.core.windows.net/liveimagefeedtclc/assets/images/mail-icon-mbl.svg" alt="Email us" rel="noopener"
                            style="height: 26px; width: 26px;"></i></a></div>
        </div> -->
        <div *ngIf="!changeLogoText" class="bar-for-mobile text-center"><a (click)="openSavedCarpopup()"
                class="justify-content-center">Saved Cars &nbsp;<i class="fa fa-gratipay"></i>
                <span>{{value}}</span></a></div>
    </div>
</div>
<!-- <app-saved-car-popup></app-saved-car-popup> -->
<!-- <app-chat-bot-loader></app-chat-bot-loader> -->

<!-- header fixed wrapper end here -->
