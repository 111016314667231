export class RequestACallback {
    firstname: string="";
    surname: string="";
    email: string="";
    contactnumber: string;
    istermclicked: boolean=false;
    isFuturemarketingclicked:boolean=false;
    visitorSource: string="";
    savedVehicles: string="";
    visitorMedium: string="";
    visitorReference: string="";
    term: string="test";
} 
